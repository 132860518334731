import React from "react";
import {
  AuthError,
  CompleteRegistrationPage,
} from "@neurosolutionsgroup/authentication";
import { useNavigate } from "react-router-dom";

const CompleteRegistration = () => {
  const navigate = useNavigate();
  const onSuccess = (firstName: string, lastName: string) => {
    console.log("complete registration success");
    navigate("/app/follow-up");
  };
  const onFailure = (err: AuthError) => {
    console.log("complete registration failure");
  };
  return (
    <CompleteRegistrationPage onSuccess={onSuccess} onFailure={onFailure} />
  );
};

export default CompleteRegistration;
