import { Box, Drawer, Grid } from "@material-ui/core";
import { useAuth } from "@neurosolutionsgroup/authentication";
import { CloseIcon } from "commons/components/Icons";
import { PageHeader } from "commons/components/Layout/Components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OptionCard from "./OptionCard";

interface OptionPageProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const OptionPage: React.FC<
  OptionPageProps & React.HTMLProps<HTMLDivElement>
> = ({ isOpen, setIsOpen }) => {
  const ROOT_CLASS = "option-page";
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const {
    actions: { invalidateSession },
  } = useAuth();

  const onBack = () => {
    const urlString = "uniwebview://back";

    window.location.href = urlString;
  };

  const onLogOut = () => {
    invalidateSession();
  };

  return (
    <Drawer
      classes={{ paperAnchorRight: ROOT_CLASS + "__menu" }}
      anchor="right"
      open={isOpen}
    >
      <Box m={1} height={"100%"} display="flex" flexDirection="column">
        <Box mb={3}>
          <PageHeader
            className={ROOT_CLASS + "__header"}
            leftElement={<Box>{t("navigation.pages.option.title")}</Box>}
            rightElement={<CloseIcon onClick={() => setIsOpen(false)} />}
          />
        </Box>

        <Grid container direction="column" spacing={1}>
          <Grid item>
            <OptionCard
              onClick={onBack}
              name={t("navigation.pages.option.back")}
            />
          </Grid>
          <Grid item>
            <OptionCard
              onClick={() => navigate("/app/add-child")}
              name={t("navigation.pages.option.addWithdrawChild")}
            />
          </Grid>
          <Grid item>
            <OptionCard
              onClick={() => navigate("/app/settings")}
              name={t("navigation.pages.option.settings")}
            />
          </Grid>
          <Grid item>
            <a
              className={ROOT_CLASS + "__link"}
              href={
                i18n.language === "en"
                  ? "https://support.kairosgame.com/hc/en-us"
                  : "https://support.kairosgame.com/hc/fr"
              }
              target="_blank"
              rel="noreferrer"
            >
              <OptionCard name={t("navigation.pages.option.support")} />
            </a>
          </Grid>
          <Grid item>
            <OptionCard onClick={onLogOut} name={"Log out (dev only)"} />
          </Grid>
        </Grid>

        <Box marginTop="auto" marginLeft="auto">
          <p className="build-number">#{process.env.REACT_APP_BUILD_NUMBER}</p>
        </Box>
      </Box>
    </Drawer>
  );
};

export default OptionPage;
