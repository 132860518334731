import { Prescription } from "@neurosolutionsgroup/models";
import { Loader } from "commons/components";
import {
  CancelDialog,
  ErrorDialog,
} from "commons/components/Layout/Components/Dialog/Dialog";
import CloseablePage from "commons/components/Layout/Page/CloseablePage/CloseablePage";
import TimeHelpers from "commons/helpers/Time";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import { ApiError } from "commons/models/ApiError";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import ConfirmationPage from "./ConfirmationPage/ConfirmationPage";
import PrescriptionForm from "./PrescriptionForm/PrescriptionForm";
import SideEffectForm from "./SideEffectForm/SideEffectForm";

enum FollowUpFormStep {
  Prescription,
  SideEffects,
  Confirmation,
}

interface CreateFollowUpFormProps {
  open: boolean;
  onClose: VoidFunction;
  medicalChildId: string;
  previousSideEffects?: string[];
}

const CreateFollowUpForm: React.FC<CreateFollowUpFormProps> = ({
  open,
  onClose,
  medicalChildId,
  previousSideEffects,
}) => {
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [formStep, setFormStep] = useState<FollowUpFormStep>(
    FollowUpFormStep.Prescription
  );
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);

  // Form State.
  const [id] = useState(v4());
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [sideEffects, setSideEffects] = useState<string[]>(
    previousSideEffects ?? []
  );
  const [takesOtherMedication, setTakesOtherMedication] = useState(false);

  const {
    requests: { createFollowUp },
    loading,
  } = useFollowUps();

  useEffect(() => {
    if (previousSideEffects) setSideEffects(previousSideEffects);
  }, [previousSideEffects]);

  const { t } = useTranslation();

  const resetForm = () => {
    setPrescriptions([]);
    setSideEffects([]);
    setShowCancelDialog(false);
    setFormStep(FollowUpFormStep.Prescription);
  };

  const onCancelConfirmation = () => {
    resetForm();
    onClose();
  };

  const onSubmit = async (): Promise<void> => {
    if (prescriptions.length > 0) {
      return createFollowUp({
        followUpId: id,
        medicalChildId,
        startDate: TimeHelpers.strings.getDateStringFromDate(startDate),
        prescriptions,
        followedSideEffectIds: sideEffects,
        takesOtherMedication,
      })
        .then(() => {
          resetForm();
          onClose();
        })
        .catch((error: ApiError) => {
          setApiError(error);
        });
    }
  };

  return (
    <>
      <CloseablePage
        isOpen={open}
        onClose={() => {
          setShowCancelDialog(true);
        }}
        title={
          formStep === FollowUpFormStep.Confirmation
            ? t("followup.confirmation.title")
            : t("followup.form.title")
        }
        closeElement={<div>{t("generic.cancel")}</div>}
        hasFooterButtons
      >
        {formStep === FollowUpFormStep.Prescription ? (
          <PrescriptionForm
            prescription={
              prescriptions.length === 0 ? undefined : prescriptions[0]
            }
            initialStartDate={startDate}
            initialTakesOtherMedication={takesOtherMedication}
            onSubmit={(p, tom, sd) => {
              setPrescriptions([p]);
              setFormStep(FollowUpFormStep.SideEffects);
              setStartDate(sd);
              setTakesOtherMedication(tom);
            }}
          />
        ) : null}
        {formStep === FollowUpFormStep.SideEffects ? (
          <SideEffectForm
            initialSideEffects={sideEffects}
            onSubmit={(se) => {
              setSideEffects(se);
              setFormStep(FollowUpFormStep.Confirmation);
            }}
            onBack={() => {
              setFormStep(FollowUpFormStep.Prescription);
            }}
            minSideEffects={1}
          />
        ) : null}
        {formStep === FollowUpFormStep.Confirmation &&
        prescriptions.length > 0 ? (
          <ConfirmationPage
            prescription={prescriptions[0]}
            startDate={startDate}
            takesOtherMedication={takesOtherMedication}
            sideEffects={sideEffects}
            medicalChildId={medicalChildId}
            onConfirm={onSubmit}
            onBack={() => setFormStep(FollowUpFormStep.SideEffects)}
          />
        ) : null}
      </CloseablePage>
      <CancelDialog
        open={showCancelDialog}
        title={t("medication.cancel.title")}
        onCancel={() => {
          setShowCancelDialog(false);
        }}
        onConfirm={onCancelConfirmation}
      />
      <ErrorDialog
        title={t("generic.errorDialog.title", {
          code: apiError?.code ?? "500",
        })}
        text={t("generic.errorDialog.text")}
        action={t("generic.tryAgain")}
        open={!!apiError}
        onClick={() => setApiError(undefined)}
      />
      <Loader show={loading} />
    </>
  );
};

export default CreateFollowUpForm;
