import moment from "moment";
import { TFunction } from "react-i18next";

const dayIndexes: number[] = [1, 2, 4, 8, 16, 32, 64];

/**
 * Function to check if a day is active within the days.
 * It performs a bit-by-bit operation, checking that the bit in binary representation of the day
 * is set to 1 in the binary representation of the days
 * @param {number} day The value of the day to check.
 * @param {number} days The days to check the day against.
 * @returns {boolean} Whether the day is active within the binary number days representation.
 */
const dayInDays = (day: number, days: number): boolean => {
  return !!(day & days);
};

/**
 * Toggle a day in a days number.
 * Performs a bit-by-bit operation on the binary representation of the days number to switch
 * the bit that represents the day.
 * @param {number} day The day to toggle.
 * @param {number} days The days to toggle the day in.
 * @returns {number} The new days number.
 */
const toggleDay = (day: number, days: number): number => {
  return days ^ day;
};

/**
 * Deactivate specific days within a days number.
 * Performs bit-by-bit operation to deactivate the bit that represents the day passed in the
 * days number.
 * @param {number} days The days number within which to deactivate days.
 * @param {number} daysToDeactivate The days to deactivate.
 * @returns {number} The new days number.
 */
const deactivateDays = (days: number, daysToDeactivate: number): number => {
  return days & ~daysToDeactivate;
};

/**
 * Generate a string representation of a time of day defined by number of seconds since midnight.
 * @param {number} seconds Representation of time of day by number of seconds since midnight.
 * @returns {string} A string displaying a human readable time.
 */
const secondsToTimeStamp = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  return (
    (hours < 10 ? "0" : "") +
    hours.toFixed(0) +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes.toFixed(0)
  );
};

/**
 * Generate a localised string representation of a time of day defined by number of seconds since midnight.
 * @param {number} seconds Representation of time of day by number of seconds since midnight.
 * @param language The language of the string you want, either "en" or "fr".
 * @returns {string} A localised string displaying a human readable time.
 */
const localizedTimeFromSeconds = (
  seconds: number,
  language: "en" | "fr"
): string => {
  const isAM = seconds < 43200;

  if (language === "en") {
    seconds = seconds < 46800 ? seconds : seconds - 43200;
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  if (language === "en") {
    return (
      (hours < 10 ? "0" : "") +
      hours.toFixed(0) +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes.toFixed(0) +
      " " +
      (isAM ? "AM" : "PM")
    );
  } else {
    return (
      (hours < 10 ? "0" : "") +
      hours.toFixed(0) +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes.toFixed(0)
    );
  }
};

/**
 * Generate a string representation of a time period.
 * @param {number} start Representation of time of day of start of period by number of seconds since midnight.
 * @param {number} end Representation of time of day of end of period by number of seconds since midnight.
 * @param language The language of the string you want, either "en" or "fr".
 * @returns {string} A localised string displaying a human readable time.
 */
const localizedTimePeriodFromSeconds = (
  start: number,
  end: number,
  language: "en" | "fr"
): string => {
  const noon = 43200;

  const startIsAM = start < noon;
  const endIsAM = end < noon;
  const bothSamePeriod = startIsAM === endIsAM;

  if (language === "en") {
    start = start < 46800 ? start : start - noon;
    end = end < 46800 ? end : end - noon;
  }

  const hours = (seconds: number): number => {
    return Math.floor(seconds / 3600);
  };
  const minutes = (seconds: number): number => {
    return Math.floor((seconds - hours(seconds) * 3600) / 60);
  };

  if (language === "en") {
    return (
      (hours(start) < 10 ? "0" : "") +
      hours(start).toFixed(0) +
      ":" +
      (minutes(start) < 10 ? "0" : "") +
      minutes(start).toFixed(0) +
      (bothSamePeriod ? "" : " AM") +
      " - " +
      (hours(end) < 10 ? "0" : "") +
      hours(end).toFixed(0) +
      ":" +
      (minutes(end) < 10 ? "0" : "") +
      minutes(end).toFixed(0) +
      " " +
      (endIsAM ? "AM" : "PM")
    );
  } else {
    return (
      (hours(start) < 10 ? "0" : "") +
      hours(start).toFixed(0) +
      ":" +
      (minutes(start) < 10 ? "0" : "") +
      minutes(start).toFixed(0) +
      " - " +
      (hours(end) < 10 ? "0" : "") +
      hours(end).toFixed(0) +
      ":" +
      (minutes(end) < 10 ? "0" : "") +
      minutes(end).toFixed(0)
    );
  }
};

/**
 * Get a string representation of a date without time information.
 * @param {Date} date
 * @param t Localization function.
 * @returns {string}
 */
const dateToLocalizedString = (
  date: Date,
  t: TFunction<"translation">
): string => {
  let string = date.getUTCDate() + " ";

  string += t(`time.months.${date.getUTCMonth()}.full`) + " ";

  string += date.getUTCFullYear();

  return string;
};

/**
 * Get the date representation of midnight on the same day of a date with timezone information stripped.
 * @param {Date} [date=now] date The date to use, if not given a date it generates today's date.
 * @returns {Date} The date at midnight of the passed date.
 */
const getDateUTCMidnight = (date?: Date): Date => {
  return moment(date).utc().startOf("day").toDate();
};

/**
 * Get the date at midnight of the first monday before a date.
 * @param {Date} [date=now] date
 * @returns {Date}
 */
const getPreviousMonday = (date?: Date, utc = true): Date => {
  return utc
    ? moment(date).utc().startOf("isoWeek").toDate()
    : moment(date).startOf("isoWeek").toDate();
};

/**
 * Get the UNIX epoch timestamp of a date in seconds, if not date passed returns current time.
 * @param {Date} date
 * @returns {number}
 */
const getTimeStamp = (date?: Date): number => {
  return Math.floor((date ? date.getTime() : Date.now()) / 1000);
};

/**
 * Get current local time of day.
 * @returns {number}
 */
const getCurrentTime = (): number => {
  const now = new Date();

  return (now.getHours() * 60 + now.getMinutes()) * 60;
};

/**
 * Generate the string representation of date in format YYYY-MM-DD.
 * @param {Date} date
 * @returns {string}
 */
const getDateStringFromDate = (date: Date): string => {
  return (
    date.getUTCFullYear() +
    "-" +
    (date.getUTCMonth() < 9
      ? "0" + (date.getUTCMonth() + 1)
      : date.getUTCMonth() + 1) +
    "-" +
    (date.getUTCDate() < 10 ? "0" + date.getUTCDate() : date.getUTCDate())
  );
};

/**
 * Generate the JS Date for a date string of format YYYY-MM-DD recieved from the API.
 * @param dateString
 * @returns
 */
const getDateFromApiDateString = (dateString: string): Date => {
  return getDateUTCMidnight(new Date(dateString));
};

const compareToApiDateStrings = (a?: string, b?: string): number => {
  return (
    (b ? getDateFromApiDateString(b).getTime() : 0) -
    (a ? getDateFromApiDateString(a).getTime() : 0)
  );
};

const TimeHelpers = {
  days: {
    dayIndexes,
    toggleDay,
    dayInDays,
    deactivateDays,
  },
  strings: {
    secondsToTimeStamp,
    localizedTimeFromSeconds,
    localizedTimePeriodFromSeconds,
    dateToLocalizedString,
    getDateStringFromDate,
  },
  date: {
    compareToApiDateStrings,
    getDateUTCMidnight,
    getTimeStamp,
    getCurrentTime,
    getDateFromApiDateString,
    getPreviousMonday,
  },
};

export default TimeHelpers;
