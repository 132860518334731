import { useAuth } from "@neurosolutionsgroup/authentication";
import { Note } from "@neurosolutionsgroup/models";
import TimeHelpers from "commons/helpers/Time";
import MedicalService from "commons/services/Medical";
import { useCallback } from "react";
import { useAppDataContext } from "../AppDataContext";
import { useNotesContext } from "./NotesContext";

interface UseNotesResult {
  notes: Note[];
  addNote: (note: Note) => Promise<void>;
  editNote: (note: Note) => Promise<void>;
  deleteNote: (noteId: string, childId: string) => Promise<void>;
  getNoteById: (id: string) => Note | undefined;
  getNotesByDay: (date: Date) => Note[];
  loading: boolean;
}

const useNotes = (): UseNotesResult => {
  const { notes, setNotes } = useNotesContext();
  const { loading, setLoading } = useAppDataContext();

  const {
    actions: { getIdToken },
  } = useAuth();

  const addNote = async (note: Note): Promise<void> => {
    setLoading(true);

    const idToken = await getIdToken();

    if (idToken) {
      return MedicalService.ChildService.JournalService.addNote(
        idToken,
        note.medicalChildId,
        note
      )
        .then((result) => {
          setNotes((current) => [...current, result]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated."));
    }
  };

  const editNote = async (note: Note): Promise<void> => {
    setLoading(true);

    const idToken = await getIdToken();

    if (idToken) {
      return MedicalService.ChildService.JournalService.editNote(
        idToken,
        note.medicalChildId,
        note
      )
        .then((response) => {
          setNotes((current) => {
            return current.map((n) => {
              if (n.noteId === response.noteId) {
                return response;
              } else {
                return n;
              }
            });
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated."));
    }
  };

  const deleteNote = async (noteId: string, childId: string): Promise<void> => {
    setLoading(true);

    const idToken = await getIdToken();

    if (idToken) {
      return MedicalService.ChildService.JournalService.deleteNote(
        idToken,
        childId,
        noteId
      )
        .then((response) => {
          setNotes((current) => current.filter((n) => n.noteId !== response));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated."));
    }
  };

  const getNoteById = useCallback(
    (id: string): Note | undefined => {
      return notes.find((n) => n.noteId === id);
    },
    [notes]
  );

  const getNotesByDay = useCallback(
    (date: Date): Note[] => {
      return notes.filter(
        (n) =>
          TimeHelpers.date.getDateFromApiDateString(n.eventDate).getTime() ===
          date.getTime()
      );
    },
    [notes]
  );

  return {
    notes,
    addNote,
    editNote,
    deleteNote,
    getNoteById,
    getNotesByDay,
    loading,
  };
};

export default useNotes;
