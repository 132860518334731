import { Box, Grid, Typography } from "@material-ui/core";
import FaceIcon, { faceState } from "../components/FaceIcon";
import FeedbackIcon, { feedbackIconShape } from "../components/FeedbackIcon";
import { useTranslation } from "react-i18next";

interface IntensityFormProps {
  intensity: number;
  setIntensity: (newValue: number) => void;
}

const IntensityForm = ({
  intensity,
  setIntensity,
}: IntensityFormProps): JSX.Element => {
  const { t } = useTranslation();
  const ROOT_CLASS = "intensity-form";

  return (
    <Box className={ROOT_CLASS} p={2}>
      <Grid container direction="row" justifyContent="space-around">
        <Box
          className={ROOT_CLASS + "__answer__low"}
          onClick={() => setIntensity(1)}
        >
          <FeedbackIcon
            className={
              ROOT_CLASS +
              "__answer-icon" +
              (intensity === 1 ? "__selected" : "")
            }
            shape={feedbackIconShape.square}
            icon={<FaceIcon state={faceState.happy} />}
            selected={intensity === 1}
          />
          <Box
            className={
              ROOT_CLASS +
              "__answer-text" +
              (intensity === 1 ? "__selected" : "")
            }
            mt={2}
          >
            <Typography align="center">
              {t("journal.question.intensity.low")}
            </Typography>
          </Box>
        </Box>
        <Box
          className={ROOT_CLASS + "__answer__medium"}
          onClick={() => setIntensity(2)}
        >
          <FeedbackIcon
            className={
              ROOT_CLASS +
              "__answer-icon" +
              (intensity === 2 ? "__selected" : "")
            }
            shape={feedbackIconShape.square}
            icon={<FaceIcon state={faceState.neutral} />}
            selected={intensity === 2}
          />
          <Box
            className={
              ROOT_CLASS +
              "__answer-text" +
              (intensity === 2 ? "__selected" : "")
            }
            mt={2}
          >
            <Typography align="center">
              {t("journal.question.intensity.moderate")}
            </Typography>
          </Box>
        </Box>
        <Box
          className={ROOT_CLASS + "__answer__high"}
          onClick={() => setIntensity(3)}
        >
          <FeedbackIcon
            className={
              ROOT_CLASS +
              "__answer-icon" +
              (intensity === 3 ? "__selected" : "")
            }
            shape={feedbackIconShape.square}
            icon={<FaceIcon state={faceState.sad} />}
            selected={intensity === 3}
          />
          <Box
            className={
              ROOT_CLASS +
              "__answer-text" +
              (intensity === 3 ? "__selected" : "")
            }
            mt={2}
          >
            <Typography align="center">
              {t("journal.question.intensity.high")}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default IntensityForm;
