import clsx from "clsx";
import React, { ReactNode } from "react";
import { CloseIcon } from "../Icons";

interface BaseAlertProps {
  display: boolean;
  icon: ReactNode;
  text: string;
  onClose?: VoidFunction;
  className?: string;
}

const BaseAlert: React.FC<BaseAlertProps> = ({
  display,
  icon,
  text,
  onClose,
  className,
}) => {
  const ROOT_CLASS = "alert";
  return display ? (
    <div className={clsx(ROOT_CLASS, className)}>
      {icon}
      <span className={ROOT_CLASS + "__text"}>{text}</span>
      {onClose ? <CloseIcon onClick={onClose} /> : null}
    </div>
  ) : null;
};

export default BaseAlert;
