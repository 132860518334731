import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Prescription } from "@neurosolutionsgroup/models";
import { Avatar } from "commons/components";
import { Button } from "commons/components/Inputs";
import { FooterButtons } from "commons/components/Layout/Components";
import useChildren from "commons/hooks/children/useChildren";
import React from "react";
import { useTranslation } from "react-i18next";
import { Presentation } from "../Components";

interface ConfirmationPageProps {
  prescription: Prescription;
  startDate: Date;
  takesOtherMedication: boolean;
  sideEffects: string[];
  medicalChildId: string;
  onConfirm: VoidFunction;
  onBack: VoidFunction;
}

const ConfirmationPage: React.FC<ConfirmationPageProps> = ({
  prescription,
  startDate,
  takesOtherMedication,
  sideEffects,
  medicalChildId,
  onConfirm,
  onBack,
}) => {
  const ROOT_CLASS = "followup-confirmation";

  const { t } = useTranslation();

  const { selectedChildId, getChildFromId } = useChildren();

  return (
    <>
      <Grid container direction="column" spacing={1} className={ROOT_CLASS}>
        <Grid item container justifyContent="center">
          <Grid item>
            <Box m={1}>
              <Avatar
                name={
                  selectedChildId
                    ? getChildFromId(selectedChildId)?.name ?? ""
                    : ""
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          <Box mx={1}>
            <Presentation.PrescriptionCard
              prescription={prescription}
              startDate={startDate}
              takesOtherMedication={takesOtherMedication}
              childId={medicalChildId}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box mx={1}>
            <div className={ROOT_CLASS + "__divider"} />
          </Box>
        </Grid>
        <Grid item>
          <Box mx={1}>
            <Presentation.SideEffectsCard sideEffects={sideEffects} />
          </Box>
        </Grid>
      </Grid>
      <FooterButtons>
        <Grid container spacing={1} justifyContent="center">
          <Grid item>
            <Button variant="contained" color="secondary" onClick={onBack}>
              {t("generic.previous")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={onConfirm}>
              {t("generic.confirm")}
            </Button>
          </Grid>
        </Grid>
      </FooterButtons>
    </>
  );
};

export default ConfirmationPage;
