import { Loader } from "commons/components";
import {
  CancelDialog,
  ErrorDialog,
} from "commons/components/Layout/Components/Dialog/Dialog";
import CloseablePage from "commons/components/Layout/Page/CloseablePage/CloseablePage";
import useAlerts from "commons/hooks/alerts/useAlerts";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import { ApiError } from "commons/models/ApiError";
import { FrontEndFollowUp } from "commons/models/Medical";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SideEffectForm from "./SideEffectForm/SideEffectForm";

interface ModifySideEffectsFormProps {
  open: boolean;
  onClose: VoidFunction;
  followUp: FrontEndFollowUp;
}

const ModifySideEffectsForm: React.FC<ModifySideEffectsFormProps> = ({
  open,
  onClose,
  followUp,
}) => {
  const { t } = useTranslation();
  const { addSuccessAlert } = useAlerts();

  const {
    requests: { modifySideEffects },
    loading,
  } = useFollowUps();

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [isModified, setIsModified] = useState(false);

  const resetState = () => {
    setApiError(undefined);
    setShowCancelDialog(false);
  };

  const onCancelConfirmation = () => {
    resetState();
    onClose();
  };

  const onSubmit = (sideEffectIds: string[]) => {
    modifySideEffects({
      ...followUp,
      followedSideEffectIds: sideEffectIds,
    })
      .then(() => {
        addSuccessAlert(t("sideEffects.form.modify.success"), true, true);
        resetState();
        onClose();
      })
      .catch((error) => {
        setApiError(error);
      });
  };

  return (
    <CloseablePage
      isOpen={open}
      onClose={() =>
        isModified ? setShowCancelDialog(true) : onCancelConfirmation()
      }
      title={t("sideEffects.form.modify.title")}
      closeElement={<div>{t("generic.cancel")}</div>}
      hasFooterButtons
    >
      <SideEffectForm
        initialSideEffects={followUp.followedSideEffectIds}
        onSubmit={onSubmit}
        submitText={t("generic.update")}
        isModified={isModified}
        onModification={setIsModified}
        disabledIfUnmodified
      />
      <CancelDialog
        open={showCancelDialog}
        onCancel={() => {
          setShowCancelDialog(false);
        }}
        onConfirm={onCancelConfirmation}
      />
      <ErrorDialog
        title={t("generic.errorDialog.title", {
          code: apiError?.code ?? "500",
        })}
        text={t("generic.errorDialog.text")}
        action={t("generic.tryAgain")}
        open={!!apiError}
        onClick={() => setApiError(undefined)}
      />
      <Loader show={loading} />
    </CloseablePage>
  );
};

export default ModifySideEffectsForm;
