import { Box, Grid } from "@material-ui/core";

export enum feedbackIconShape {
  round = "round",
  square = "square",
}

interface feedbackIconProps {
  shape: feedbackIconShape;
  icon: JSX.Element;
  onClick?: () => void;
  selected?: boolean;
}

const FeedbackIcon: React.FC<
  feedbackIconProps & React.HTMLProps<HTMLDivElement>
> = ({ shape, icon, selected, ...props }) => {
  const ROOT_CLASS = props.className ? props.className : "feedback-icon";

  const radius = () => {
    switch (shape) {
      case feedbackIconShape.round:
        return 100;
      case feedbackIconShape.square:
      default:
        return 25;
    }
  };

  return (
    <Box className={ROOT_CLASS} borderRadius={radius()}>
      <Grid
        className={"feedback-icon__grid"}
        container
        alignItems="center"
        justifyContent="center"
      >
        {icon}
      </Grid>
    </Box>
  );
};

export default FeedbackIcon;
