import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const AddIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="add">
      <path
        d="M 20,11.428571 H 11.428571 V 20 H 8.5714286 V 11.428571 H 0 V 8.5714286 H 8.5714286 V 0 H 11.428571 V 8.5714286 H 20 Z"
        fill="#000000"
        id="path985"
      />
    </BaseIcon>
  );
};

export default AddIcon;
