import React from "react";
import clsx from "clsx";
import Dialog from "../Dialog";
import { Button } from "commons/components/Inputs";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { CloseIcon } from "commons/components/Icons";

interface ErrorDialogProps {
  title: string;
  text?: string;
  action: string;
  onClick: VoidFunction;
}

const ErrorDialog: React.FC<
  React.ComponentProps<typeof Dialog> & ErrorDialogProps
> = ({ title, text, action, onClick, ...props }) => {
  return (
    <Dialog
      {...props}
      onClose={onClick}
      className={clsx("dialog", "dialog--error", props.className)}
    >
      <Box p={2}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item container>
            <Box mr="auto" ml={1}>
              <h3>{title}</h3>
            </Box>
            <CloseIcon onClick={onClick} />
          </Grid>
          {text ? (
            <Grid item container>
              <Box mr="auto" ml={1}>
                {text}
              </Box>
            </Grid>
          ) : null}
          <Grid item>
            <Box>
              <Button variant="contained" color="secondary" onClick={onClick}>
                {action}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ErrorDialog;
