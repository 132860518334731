import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const ArrowIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="menu">
      <path
        d="M 0,10.013162 C 0,9.4075103 0.20188789,8.9159511 0.60566217,8.5385112 1.0182136,8.1610699 1.531706,7.9723484 2.1461513,7.9723484 c 0.6232089,0 1.1367148,0.1887215 1.5404876,0.5661628 0.4125514,0.3774399 0.6188346,0.8689991 0.6188346,1.4746508 0,0.596888 -0.2018939,1.084058 -0.6056666,1.461495 -0.4037728,0.368658 -0.921668,0.552995 -1.5536556,0.552995 -0.623224,0 -1.1367149,-0.184337 -1.54048913,-0.552995 C 0.20188789,11.09722 0,10.61005 0,10.013162 Z m 7.8472677,0 c 0,-0.6056517 0.2018939,-1.0972109 0.6056667,-1.4746508 0.4125514,-0.3774413 0.9260423,-0.5661628 1.5404876,-0.5661628 0.623224,0 1.136715,0.1887215 1.540488,0.5661628 0.412551,0.3774399 0.618834,0.8689991 0.618834,1.4746508 0,0.596888 -0.201894,1.084058 -0.605666,1.461495 -0.403773,0.368658 -0.921653,0.552995 -1.553656,0.552995 -0.6232239,0 -1.1367148,-0.184337 -1.5404876,-0.552995 C 8.0491616,11.09722 7.8472677,10.61005 7.8472677,10.013162 Z m 7.8473003,0 c 0,-0.6056517 0.201939,-1.0972109 0.605667,-1.4746508 0.412566,-0.3774413 0.925953,-0.5661628 1.540458,-0.5661628 0.623194,0 1.13673,0.1887215 1.540457,0.5661628 C 19.793717,8.9159511 20,9.4075103 20,10.013162 c 0,0.596888 -0.201939,1.084058 -0.605667,1.461495 -0.403727,0.368658 -0.921608,0.552995 -1.55364,0.552995 -0.623194,0 -1.13673,-0.184337 -1.540458,-0.552995 -0.403728,-0.377437 -0.605667,-0.864607 -0.605667,-1.461495 z"
        fill="#000000"
        id="path824"
      />
    </BaseIcon>
  );
};

export default ArrowIcon;
