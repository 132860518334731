import React from "react";
import MUIDialog from "@material-ui/core/Dialog";
import clsx from "clsx";
import ErrorDialog from "./ErrorDialog/ErrorDialog";
import CancelDialog from "./CancelDialog/CancelDialog";
import YesNoDialog from "./YesNoDialog/YesNoDialog";
import NoInternetDialog from "./NoInternetDialog/NoInternetDialog";
import ApiErrorDialog from "./ErrorDialog/ApiErrorDialog";

const Dialog: React.FC<React.ComponentProps<typeof MUIDialog>> = ({
  ...props
}) => {
  return (
    <MUIDialog {...props} className={clsx("dialog", props.className)}>
      {props.children}
    </MUIDialog>
  );
};

export {
  ErrorDialog,
  CancelDialog,
  YesNoDialog,
  NoInternetDialog,
  ApiErrorDialog,
};

export default Dialog;
