import React from "react";
import BaseIcon from "./BaseIcon";

export enum ArrowType {
  default,
  empty,
}
interface ArrowIconProps {
  onClick?: () => void;
  arrowType?: ArrowType;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({ onClick, arrowType }) => {
  const path = () => {
    switch (arrowType) {
      case ArrowType.empty:
        return (
          <path
            d="M 6.175,0 3.825,2.35 11.458333,10 3.825,17.65 6.175,20 l 10,-10 z"
            fill="#000000"
            id="path20"
          />
        );
      case ArrowType.default:
      default:
        return (
          <path
            d="M 9.999974,15.62915 0,4.3708501 l 20,2e-6 z"
            fill="#000000"
            id="path824"
          />
        );
    }
  };
  return (
    <BaseIcon onClick={onClick} classModifier="arrow">
      {path()}
    </BaseIcon>
  );
};

export default ArrowIcon;
