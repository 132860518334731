import React, { useCallback, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import queryString from "query-string";
import LandingPage from "pages/landing/LandingPage";
import Authentication from "pages/authentication/Authentication";
import { AppDataProviders } from "commons/hooks/AppDataContext";
import { useAuth } from "@neurosolutionsgroup/authentication";
import NoInternetDialog from "commons/components/Layout/Components/Dialog/NoInternetDialog/NoInternetDialog";
import { AlertProvider } from "commons/hooks/alerts/AlertContext";
import AppNavigator from "pages/appNavigator/AppNavigator";
import { CustomWindow } from "custom.window";
import { useTranslation } from "react-i18next";

declare let window: CustomWindow;

const AppRouter: React.FC = () => {
  const {
    actions: { isAuthenticated, passAuthentication },
  } = useAuth();

  const { i18n } = useTranslation();

  useEffect(() => {
    // Set session ticket in env to skip auth.
    if (process.env.REACT_APP_DEV_SESSION_TICKET) {
      passAuthentication(process.env.REACT_APP_DEV_SESSION_TICKET);
    }
    window.auth = {
      passSessionTicket: async (customToken: string): Promise<boolean> => {
        return passAuthentication(customToken).then(() => {
          return true;
        });
      },
    };

    window.changeLanguage = (newLanguage: "en" | "fr"): void => {
      i18n.changeLanguage(newLanguage);
    };

    const params = queryString.parse(window.location.search.slice(1));

    if (params.lang === "en") {
      i18n.changeLanguage("en");
    } else if (params.lang === "fr") {
      i18n.changeLanguage("fr");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAuthed = useCallback((): boolean => {
    if (process.env.REACT_APP_SKIP_AUTH || isAuthenticated()) {
      return true;
    }

    return false;
  }, [isAuthenticated]);

  return (
    <Router>
      <Routes>
        <Route
          path="/*"
          element={isAuthed() ? <Navigate to="app" /> : <LandingPage />}
        />
        <Route path="/authentication/*" element={<Authentication />} />
        <Route
          path="app/*"
          element={
            isAuthed() ? (
              <AlertProvider>
                <AppDataProviders>
                  <AppNavigator />
                  <NoInternetDialog />
                </AppDataProviders>
              </AlertProvider>
            ) : (
              <Navigate to="/authentication" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
