const resources = {
  en: {
    translation: {
      generic: {
        anErrorOccurred: "An error has occured",
        back: "Back",
        cancel: "Cancel",
        close: "Close",
        confirm: "Confirm",
        create: "Create",
        day: "day",
        day_plural: "days",
        delete: "Delete",
        edit: "Edit",
        finish: "Finish",
        next: "Next",
        no: "No",
        ok: "Ok",
        other: "Other",
        previous: "Previous",
        save: "Save",
        select: "Select",
        selectAChild: "Select a child",
        skip: "Skip",
        tryAgain: "Try again",
        update: "Update",
        yes: "Yes",
        cancelDialog: {
          title: "Cancel modifications",
          text: {
            one: "Are you sure?",
            two: "Modifications will not be saved.",
            three: "This action is irreversible.",
          },
        },
        errorDialog: {
          title: "Code {{code}} - Error",
          text: "Please try again. If the problem persists, please contact support.",
        },
        noInternetDialog: {
          title: "Internet connection error",
          text: "Please check your internet connection and try again.",
        },
      },
    },
  },
  fr: {
    translation: {
      generic: {
        anErrorOccurred: "Une erreur est survenue",
        back: "Retour",
        cancel: "Annuler",
        close: "Fermer",
        confirm: "Confirmer",
        create: "Créer",
        day: "jour",
        day_plural: "jours",
        delete: "Supprimer",
        edit: "Modifier",
        finish: "Terminer",
        next: "Suivant",
        no: "Non",
        ok: "Ok",
        other: "Autre",
        previous: "Précédent",
        save: "Sauvegarder",
        select: "Sélectionner",
        selectAChild: "Sélectionnez un enfant",
        skip: "Sautez l'étape",
        tryAgain: "Réessayer",
        update: "Mettre à jour",
        yes: "Oui",
        cancelDialog: {
          title: "Annuler les modifications",
          text: {
            one: "Êtes-vous certain?",
            two: "Les modifications ne seront pas enregistrées.",
            three: "Cette action est irréversible.",
          },
        },
        errorDialog: {
          title: "Code {{code}} - Erreur",
          text: "Veuillez réessayer. Si le problème persiste, merci de contacter le support.",
        },
        noInternetDialog: {
          title: "Erreur de connexion internet",
          text: "Veuillez vérifier votre connexion internet et réessayer.",
        },
      },
    },
  },
};

export default resources;
