import { Box, Grid } from "@material-ui/core";
import ObservationEmptyState from "./ObservationEmptyState";
import SideEffectCard from "./SideEffectCard";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import useSideEffects from "commons/hooks/sideEffects/useSideEffects";
import { useCallback, useState } from "react";
import QuestionPage from "./questions/QuestionPage";
import { v4 } from "uuid";
import { AnswerType } from "commons/models/Types";
import HeadSection from "commons/components/Layout/Components/HeadSection/HeadSection";
import { SideEffect, Record } from "@neurosolutionsgroup/models";
import TimeHelpers from "commons/helpers/Time";

interface ObservationDisplayProps {
  activeChildId: string;
}

const ObservationDisplay = ({ activeChildId }: ObservationDisplayProps) => {
  const { getSideEffectFromId } = useSideEffects();
  const {
    helpers: {
      getFollowUpSideEffectsForLastWeeksJournal,
      getFollowUpSideEffectStatusLastWeek,
      getPreviousSideEffectsForChild,
    },
    requests: { createNewRecords },
  } = useFollowUps();
  const { t, i18n } = useTranslation();

  const [openQuestionPage, setOpenQuestionPage] = useState(false);
  const [sideEffectEvaluated, setSideEffectEvaluated] = useState<
    SideEffect | undefined
  >(undefined);
  const dateString = (): string => {
    const monday = moment().utc().startOf("week").add(-6, "day");
    const sunday = moment().utc().endOf("week").add(-6, "day");
    switch (i18n.language) {
      case "en":
        return (
          "From " +
          monday.date() +
          " " +
          t(`time.months.${monday.get("month")}.short`) +
          " to " +
          sunday.date() +
          " " +
          t(`time.months.${sunday.get("month")}.short`)
        );
      case "fr":
        return (
          "Du " +
          monday.date() +
          " " +
          t(`time.months.${monday.get("month")}.short`) +
          " au " +
          sunday.date() +
          " " +
          t(`time.months.${sunday.get("month")}.short`)
        );
      default:
        return (
          "From " +
          monday.date() +
          " " +
          t(`time.months.${monday.get("month")}.short`) +
          " to " +
          sunday.date() +
          " " +
          t(`time.months.${sunday.get("month")}.short`)
        );
    }
  };

  const ObservationDisplayHeader = () => {
    const qtyActiveSideEffect = getFollowUpSideEffectsForLastWeeksJournal(
      activeChildId
    ).filter((fuse) => fuse.isActive).length;
    return (
      <Box className="observation-display-header__container" p={4}>
        <Grid container direction="column" justifyContent="flex-end">
          <h3>{dateString()}</h3>
          {t("journal.observation", { count: qtyActiveSideEffect })}
        </Grid>
      </Box>
    );
  };

  const questionsCompleted = useCallback(
    (sideEffectId: string): boolean => {
      let filteredList;
      let records;
      filteredList = getFollowUpSideEffectsForLastWeeksJournal(
        activeChildId
      ).filter((fuse) => fuse.sideEffectId === sideEffectId);
      if (filteredList.length > 0) {
        records = filteredList[0].recordsLast4Weeks;
        if (
          records.filter(
            (r) =>
              TimeHelpers.date.getDateFromApiDateString(r.weekStartDate) >=
              moment().utc().startOf("week").add(-6, "day").toDate()
          ).length > 0
        ) {
          return true;
        }
      }
      return false;
    },
    [getFollowUpSideEffectsForLastWeeksJournal, activeChildId]
  );

  const ObservationDisplayBody = () => {
    if (getFollowUpSideEffectsForLastWeeksJournal(activeChildId).length === 0) {
      if (getPreviousSideEffectsForChild(activeChildId).length > 0) {
        return <ObservationEmptyState type="noPrescription" />;
      }
      return <ObservationEmptyState type="noSideEffects" />;
    } else {
      const followUpSideEffects =
        getFollowUpSideEffectsForLastWeeksJournal(activeChildId);
      return followUpSideEffects.filter(
        (fuse) => !questionsCompleted(fuse.sideEffectId)
      ).length === 0 ? (
        <ObservationEmptyState type="complete" />
      ) : (
        followUpSideEffects.map((fuse) => {
          let se = getSideEffectFromId(fuse.sideEffectId);
          if (se && getFollowUpSideEffectStatusLastWeek(fuse)) {
            return (
              <SideEffectCard
                onClick={() =>
                  se ? openQuestionnaire(se) : closeQuestionnaire()
                }
                key={se.id}
                sideEffect={i18n.language === "en" ? se.name.en : se.name.fr}
                questionsCompleted={questionsCompleted(se.id)}
              />
            );
          } else {
            return null;
          }
        })
      );
    }
  };

  const openQuestionnaire = (sideEffect: SideEffect) => {
    setSideEffectEvaluated(sideEffect);
    setOpenQuestionPage(true);
  };
  const closeQuestionnaire = () => {
    setSideEffectEvaluated(undefined);
    setOpenQuestionPage(false);
  };

  const onQuestionnaireFinished = async (
    intensity: number,
    frequency: number
  ) => {
    let records: Record[] = [];
    let intensityQuestionId = sideEffectEvaluated?.questions.find(
      (q) => q.answerType === AnswerType.intensity
    )?.questionId;
    let frequencyQuestionId = sideEffectEvaluated?.questions.find(
      (q) => q.answerType === AnswerType.frequency
    )?.questionId;
    if (sideEffectEvaluated) {
      if (intensityQuestionId) {
        records.push({
          recordId: v4(),
          answer: intensity,
          weekStartDate: TimeHelpers.strings.getDateStringFromDate(
            moment().utc().startOf("week").add(-6, "day").toDate()
          ),
          questionId: intensityQuestionId,
          sideEffectId: sideEffectEvaluated?.id,
          followUpId:
            getFollowUpSideEffectsForLastWeeksJournal(activeChildId)[0]
              .followUpId,
        });
      }
      if (frequencyQuestionId) {
        records.push({
          recordId: v4(),
          answer: frequency,
          weekStartDate: TimeHelpers.strings.getDateStringFromDate(
            moment().utc().startOf("week").add(-6, "day").toDate()
          ),
          questionId: frequencyQuestionId,
          sideEffectId: sideEffectEvaluated?.id,
          followUpId:
            getFollowUpSideEffectsForLastWeeksJournal(activeChildId)[0]
              .followUpId,
        });
      }

      return createNewRecords(
        records,
        getFollowUpSideEffectsForLastWeeksJournal(activeChildId)[0].followUpId,
        sideEffectEvaluated.id
      ).then(() => {
        return;
      });
    }
  };

  return (
    <Box className="observation-display__container">
      <Grid container direction="column" justifyContent="space-between">
        <Grid item>
          <HeadSection className="observation-display__header">
            {ObservationDisplayHeader()}
          </HeadSection>
        </Grid>
        <Grid className="observation-display__body" item>
          {ObservationDisplayBody()}
        </Grid>
      </Grid>
      <QuestionPage
        sideEffect={sideEffectEvaluated}
        open={openQuestionPage}
        onClose={() => {
          closeQuestionnaire();
        }}
        onFinish={onQuestionnaireFinished}
      />
    </Box>
  );
};

export default ObservationDisplay;
