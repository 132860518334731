import MUICheckbox from "@material-ui/core/Checkbox";
import { CheckBoxIcon } from "commons/components/Icons";
import React from "react";

const Checkbox: React.FC<React.ComponentProps<typeof MUICheckbox>> = ({
  ...props
}) => {
  return (
    <MUICheckbox
      icon={<CheckBoxIcon isChecked={false} />}
      checkedIcon={<CheckBoxIcon isChecked={true} />}
      {...props}
    />
  );
};

export default Checkbox;
