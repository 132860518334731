import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { MenuIcon } from "commons/components/Icons";
import { AddButton } from "commons/components/Inputs/Button";
import {
  Card,
  VerticalCardSection,
} from "commons/components/Layout/Components";
import useSideEffects from "commons/hooks/sideEffects/useSideEffects";
import React from "react";
import { useTranslation } from "react-i18next";

interface SideEffectsCardProps {
  sideEffects: string[];
  onEdit?: VoidFunction;
  onAdd?: VoidFunction;
  disabled?: boolean;
}

const SideEffectsCard: React.FC<SideEffectsCardProps> = ({
  sideEffects,
  onEdit,
  onAdd,
  disabled,
}) => {
  const ROOT_CLASS = "side-effect-card";
  const { t, i18n } = useTranslation();

  const { getSideEffectFromId } = useSideEffects();

  return (
    <Card
      className={clsx(ROOT_CLASS, { [ROOT_CLASS + "--disabled"]: disabled })}
    >
      <Grid container direction="column">
        <VerticalCardSection>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h3>{t("followup.confirmation.sideEffects.title")}</h3>
            </Grid>
            {onEdit ? (
              <Grid
                item
                className={clsx(ROOT_CLASS + "__edit", {
                  [ROOT_CLASS + "__edit--disabled"]: !onEdit,
                })}
              >
                <MenuIcon onClick={onEdit} />
              </Grid>
            ) : null}
          </Grid>
        </VerticalCardSection>
        <VerticalCardSection last>
          <Grid container direction="column" spacing={1}>
            {sideEffects.length === 0 && onAdd ? (
              <div className={ROOT_CLASS + "__add"}>
                <AddButton
                  aria-label="add"
                  onClick={onAdd}
                  className={ROOT_CLASS + "__add-button"}
                />
                <span className={ROOT_CLASS + "__add-text"}>
                  {t("followup.confirmation.sideEffects.add")}
                </span>
              </div>
            ) : (
              sideEffects.map((seid) => {
                const sideEffect = getSideEffectFromId(seid);
                if (sideEffect) {
                  return (
                    <Grid item key={seid} className={ROOT_CLASS + "__value"}>
                      {i18n.language === "en"
                        ? sideEffect.name.en
                        : sideEffect.name.fr}
                    </Grid>
                  );
                } else {
                  return <></>;
                }
              })
            )}
          </Grid>
        </VerticalCardSection>
      </Grid>
    </Card>
  );
};

export default SideEffectsCard;
