import { Box, Button, Typography } from "@material-ui/core";
import CloseablePage from "commons/components/Layout/Page/CloseablePage/CloseablePage";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import NoteForm from "./NoteForm";
import { FooterButtons } from "commons/components/Layout/Components";
import {
  ApiErrorDialog,
  CancelDialog,
  YesNoDialog,
} from "commons/components/Layout/Components/Dialog/Dialog";
import useNotes from "commons/hooks/notes/useNotes";
import { ApiError } from "commons/models/ApiError";
import { Loader } from "commons/components";
import { Note } from "@neurosolutionsgroup/models";

interface EditNoteFormProps {
  open: boolean;
  onClose: VoidFunction;
  note: Note;
}

const EditNoteForm: React.FC<EditNoteFormProps> = ({ open, onClose, note }) => {
  const ROOT_CLASS = "edit-note-form";

  const { t } = useTranslation();

  const { editNote, deleteNote, loading } = useNotes();

  // Form state.
  const [sideEffectId, setSideEffectId] = useState<string | null>(
    note.sideEffectId ?? null
  );
  const [time, setTime] = useState<number | undefined>(note.eventTime);
  const [title, setTitle] = useState(note.title);
  const [text, setText] = useState(note.text);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);

  const onCancel = () => {
    if (isModified()) {
      setOpenCancelDialog(true);
    } else {
      onClose();
    }
  };

  const onCancelConfirm = () => {
    setOpenCancelDialog(false);
    onClose();
  };

  const onSubmit = () => {
    editNote({
      ...note,
      sideEffectId: sideEffectId ?? undefined,
      eventTime: time,
      title,
      text,
    })
      .then(() => {
        onClose();
      })
      .catch((error: ApiError) => {
        setApiError(error);
      });
  };

  const onDelete = () => {
    setOpenDeleteDialog(true);
  };

  const onDeleteConfirm = () => {
    setOpenDeleteDialog(false);
    deleteNote(note.noteId, note.medicalChildId)
      .then(() => {
        onClose();
      })
      .catch((error: ApiError) => {
        setApiError(error);
      });
  };

  const isModified = useCallback((): boolean => {
    if (sideEffectId !== note.sideEffectId) return true;
    if (time !== note.eventTime) return true;
    if (title !== note.title) return true;
    if (text !== note.text) return true;

    return false;
  }, [note, sideEffectId, text, time, title]);

  return (
    <CloseablePage
      isOpen={open}
      onClose={onCancel}
      title={t("journal.note.edit.label")}
      closeElement={<div>{t("generic.cancel")}</div>}
      className={ROOT_CLASS}
      rightHeaderElement={
        <div className={ROOT_CLASS + "__delete-button"} onClick={onDelete}>
          {t("generic.delete")}
        </div>
      }
      hasFooterButtons
    >
      <Box p={1}>
        <NoteForm
          sideEffectId={sideEffectId}
          setSideEffectId={setSideEffectId}
          time={time}
          setTime={setTime}
          title={title}
          setTitle={setTitle}
          text={text}
          setText={setText}
        />
      </Box>
      {isModified() ? (
        <FooterButtons>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            {t("generic.save")}
          </Button>
        </FooterButtons>
      ) : null}
      <CancelDialog
        open={openCancelDialog}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={onCancelConfirm}
        title={t("journal.note.edit.cancel.title")}
        body={
          <>
            <Typography align="center">
              <span>
                <b>{t("generic.cancelDialog.text.one")}</b>
              </span>
              <br />
              <span>
                <b>{t("journal.note.edit.cancel.text")}</b>
              </span>
              <br />
              <span>
                <b>{t("generic.cancelDialog.text.three")}</b>
              </span>
            </Typography>
          </>
        }
      />
      <YesNoDialog
        open={openDeleteDialog}
        onNo={() => setOpenDeleteDialog(false)}
        onYes={onDeleteConfirm}
        title={t("journal.note.edit.delete.title")}
        body={
          <>
            <Typography align="center">
              <span>{t("generic.cancelDialog.text.one")}</span>
              <br />
              <span>{t("journal.note.edit.delete.text")}</span>
              <br />
              <span>{t("generic.cancelDialog.text.three")}</span>
            </Typography>
          </>
        }
      />
      <ApiErrorDialog error={apiError} setError={setApiError} />
      <Loader show={loading} />
    </CloseablePage>
  );
};

interface EditNoteFormWrapperProps {
  open: boolean;
  onClose: VoidFunction;
  note: Note | undefined;
}

const EditNoteFormWrapper: React.FC<EditNoteFormWrapperProps> = ({
  note,
  ...props
}) => {
  return note ? <EditNoteForm note={note} {...props} /> : null;
};

export default EditNoteFormWrapper;
