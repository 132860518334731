import { useAuth } from "@neurosolutionsgroup/authentication";
import TimeHelper from "commons/helpers/Time";
import MedicalService from "commons/services/Medical";
import { useCallback } from "react";
import { v4 } from "uuid";
import { useAppDataContext } from "../AppDataContext";
import { useFollowUpsContext } from "./FollowUpsContext";
import moment from "moment";
import useChildren from "../children/useChildren";
import { FrontEndFollowUp } from "commons/models/Medical";
import { FollowedSideEffect, Record } from "@neurosolutionsgroup/models";

const enum IntensityCategory {
  Low = "low",
  Moderate = "moderate",
  High = "high",
  NA = "na",
}

interface UseFollowUpsResult {
  followUps: FrontEndFollowUp[];
  loading: boolean;
  helpers: {
    getFollowUpById: (followUpId: string) => FrontEndFollowUp | undefined;
    getFollowUpsByChild: (childId: string) => FrontEndFollowUp[];
    getActiveFollowUpForChild: (
      childId: string | undefined
    ) => FrontEndFollowUp | null;
    getPreviousSideEffectsForChild: (childId: string) => string[];
    getLastWeekFollowUpByChild: (childId: string) => FrontEndFollowUp | null;
    getFollowUpSideEffectsForLastWeeksJournal: (
      childId: string
    ) => FollowedSideEffect[];
    getRecordsForFollowUpSideEffectPeriod: (
      followUpId: string,
      sideEffectId: string,
      weeks: number
    ) => Record[];
    getIntensityCategory: (intensity: number) => IntensityCategory;
    getFollowUpSideEffectStatusLastWeek: (fuse: FollowedSideEffect) => boolean;
  };
  requests: {
    modifySideEffects: (followUp: FrontEndFollowUp) => Promise<void>;
    createFollowUp: (followUp: FrontEndFollowUp) => Promise<void>;
    terminateFollowUp: (followUpId: string) => Promise<void>;
    correctPrescription: (followUp: FrontEndFollowUp) => Promise<void>;
    createNewRecords: (
      records: Record[],
      observationPeriodId: string,
      sideEffectId: string
    ) => Promise<void>;
  };
}

const useFollowUps = (): UseFollowUpsResult => {
  const {
    followUps,
    setFollowUps,
    followUpSideEffects,
    setFollowUpSideEffects,
  } = useFollowUpsContext();
  const { selectedChildId } = useChildren();
  const { loading, setLoading, setNoInternetConnection } = useAppDataContext();
  const {
    actions: { getIdToken },
  } = useAuth();

  // Helper functions.
  const getFollowUpById = useCallback(
    (followUpId: string): FrontEndFollowUp | undefined => {
      return followUps.find((fu) => fu.followUpId === followUpId);
    },
    [followUps]
  );

  const getFollowUpsByChild = useCallback(
    (childId: string): FrontEndFollowUp[] =>
      followUps.filter((fu) => fu.medicalChildId === childId),
    [followUps]
  );

  const getActiveFollowUpForChild = useCallback(
    (childId: string | undefined): FrontEndFollowUp | null => {
      if (!childId) {
        return null;
      }
      const activeFollowUps = followUps
        .filter((fu) => fu.medicalChildId === childId && !fu.endDate)
        .sort((a, b) =>
          TimeHelper.date.compareToApiDateStrings(a.startDate, b.startDate)
        );
      return activeFollowUps.length > 0 ? activeFollowUps[0] : null;
    },
    [followUps]
  );

  const getLastWeekFollowUpByChild = useCallback(
    (childId: string): FrontEndFollowUp | null => {
      const lastWeekMonday = moment().utc().startOf("week").add(-6, "day");
      const childFollowUps = getFollowUpsByChild(childId);
      const followUpCompletedLastWeek = childFollowUps.filter(
        (fu) =>
          TimeHelper.date.getDateFromApiDateString(fu.startDate) <=
            lastWeekMonday.toDate() &&
          fu.endDate &&
          TimeHelper.date.getDateFromApiDateString(fu.endDate) >
            lastWeekMonday.toDate()
      );
      if (followUpCompletedLastWeek.length > 0) {
        return followUpCompletedLastWeek[0];
      } else {
        const activeFollowUp = getActiveFollowUpForChild(childId);

        // Check if active follow up was active last week otherwise return null.
        return activeFollowUp &&
          TimeHelper.date.getDateFromApiDateString(activeFollowUp.startDate) <
            TimeHelper.date.getPreviousMonday()
          ? activeFollowUp
          : null;
      }
    },
    [getActiveFollowUpForChild, getFollowUpsByChild]
  );

  // Get side effects followed in previous follow up.
  const getPreviousSideEffectsForChild = useCallback(
    (childId: string): string[] => {
      const previousFollowUp = followUps
        .filter((fu) => !!fu.endDate && fu.medicalChildId === childId)
        .sort((a, b) =>
          TimeHelper.date.compareToApiDateStrings(a.endDate, b.endDate)
        );

      if (previousFollowUp.length > 0) {
        return previousFollowUp[0].followedSideEffectIds;
      } else {
        return [];
      }
    },
    [followUps]
  );

  const getRecordsForFollowUpSideEffectPeriod = useCallback(
    (followUpId: string, sideEffectId: string, weeks: number): Record[] => {
      const fu = getFollowUpById(followUpId);
      if (fu) {
        const endDate = new Date(fu.startDate);

        endDate.setDate(
          TimeHelper.date.getDateFromApiDateString(fu.startDate).getDate() +
            weeks * 7
        );

        const fuse = followUpSideEffects.find(
          (fuse) =>
            fuse.followUpId === followUpId && fuse.sideEffectId === sideEffectId
        );

        if (fuse) {
          const records = fuse.recordsLast4Weeks.filter(
            (r) =>
              TimeHelper.date
                .getDateFromApiDateString(r.weekStartDate)
                .getTime() <= endDate.getTime()
          );

          return records;
        }
      }

      return [];
    },
    [followUpSideEffects, getFollowUpById]
  );

  const getFollowUpSideEffectsForLastWeeksJournal = useCallback(
    (childId: string): FollowedSideEffect[] => {
      let output: FollowedSideEffect[] = [];
      const lastWeekFollowUpId =
        getLastWeekFollowUpByChild(childId)?.followUpId;
      if (lastWeekFollowUpId) {
        followUpSideEffects.forEach((fuse) => {
          if (fuse.followUpId === lastWeekFollowUpId && fuse.isActive) {
            output.push(fuse);
          }
        });
      }
      return output;
    },
    [followUpSideEffects, getLastWeekFollowUpByChild]
  );

  const getIntensityCategory = (intensity: number): IntensityCategory => {
    if (intensity === -1) {
      return IntensityCategory.NA;
    }

    if (intensity === 3) {
      return IntensityCategory.High;
    }

    if (intensity === 1) {
      return IntensityCategory.Low;
    }

    return IntensityCategory.Moderate;
  };

  const getFollowUpSideEffectStatusLastWeek = (
    fuse: FollowedSideEffect
  ): boolean => {
    if (fuse.activeStatusChanges.length === 0) {
      return true;
    }
    // Filter any changes after period in question.
    const statusChanges = fuse.activeStatusChanges.filter(
      (asc) =>
        TimeHelper.date.getDateFromApiDateString(asc).getTime() <
        TimeHelper.date
          .getPreviousMonday(
            undefined,
            false // Don't use UTC in this case as active status changes have time zone.
          )
          .getTime()
    );

    // If number of active status changes was even the side effect was active.
    return statusChanges.length % 2 === 0;
  };

  // API requests.
  const createFollowUp = async (followUp: FrontEndFollowUp): Promise<void> => {
    setLoading(true);

    const followUpToAdd = followUp;

    if (
      followUp.takesOtherMedication &&
      process.env.REACT_APP_MEDICAL_OTHER_MEDICATION_ID
    ) {
      followUpToAdd.prescriptions.push({
        prescriptionId: v4(),
        drug: {
          doses: [],
          drugId: process.env.REACT_APP_MEDICAL_OTHER_MEDICATION_ID,
        },
      });
    }

    // Mock request for developement.
    if (process.env.REACT_APP_SKIP_AUTH) {
      return new Promise((resolve, reject) => {
        // HTTP request mock.
        const timeout = setTimeout(() => {
          setLoading(false);
          clearTimeout(timeout);
          if (!navigator.onLine) {
            setNoInternetConnection(true);
            reject();
          } else {
            setFollowUps((current) => {
              const clone = [...current];
              clone.push(followUp);
              return clone;
            });
            resolve();
          }
        }, 1000);
      });
    }

    const idToken = await getIdToken();

    if (idToken) {
      return MedicalService.ChildService.createFollowUp(
        idToken,
        followUp.medicalChildId,
        followUpToAdd
      )
        .then(() => {
          setFollowUps((current) => {
            return [...current, followUp];
          });
          return;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated."));
    }
  };

  const createNewRecords = async (
    records: Record[],
    observationPeriodId: string,
    sideEffectId: string
  ): Promise<void> => {
    setLoading(true);

    let dtoRecords: Record[] = [];
    records.forEach((r) => {
      dtoRecords.push({
        recordId: r.recordId,
        answer: r.answer,
        questionId: r.questionId,
        weekStartDate: r.weekStartDate,
        sideEffectId,
        followUpId: observationPeriodId,
      });
    });

    // Mock request for developement.
    if (process.env.REACT_APP_SKIP_AUTH) {
      return new Promise((resolve, reject) => {
        // HTTP request mock.
        const timeout = setTimeout(() => {
          setLoading(false);
          clearTimeout(timeout);
          if (!navigator.onLine) {
            setNoInternetConnection(true);
            reject();
          } else {
            setFollowUpSideEffects((current) => {
              const clone = [...current];
              const fuse = clone.find(
                (fuse) => fuse.sideEffectId === sideEffectId
              );
              let index: number = -1;
              if (fuse) {
                index = clone.indexOf(fuse);
                records.forEach((r) => {
                  fuse.recordsLast4Weeks.push(r);
                });
                if (index > -1) {
                  clone[index] = fuse;
                }
              }
              return clone;
            });
            resolve();
          }
        }, 1000);
      });
    }

    const idToken = await getIdToken();

    if (idToken) {
      if (selectedChildId) {
        return MedicalService.ChildService.JournalService.addRecord(
          idToken,
          selectedChildId,
          observationPeriodId,
          sideEffectId,
          dtoRecords
        )
          .then(() => {
            setFollowUpSideEffects((current) => {
              const clone = [...current];
              const fuse = clone.find(
                (fuse) => fuse.sideEffectId === sideEffectId
              );
              let index: number = -1;
              if (fuse) {
                index = clone.indexOf(fuse);
                records.forEach((r) => {
                  fuse.recordsLast4Weeks.push(r);
                });
                if (index > -1) {
                  clone[index] = fuse;
                }
              }
              return clone;
            });
            return;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated."));
    }
  };

  const terminateFollowUp = async (followUpId: string): Promise<void> => {
    setLoading(true);
    if (process.env.REACT_APP_SKIP_AUTH) {
      setFollowUps((current) => {
        let clone = [...current];
        clone.forEach((fu) => {
          if (!fu.endDate && fu.followUpId === followUpId) {
            fu.endDate = TimeHelper.strings.getDateStringFromDate(
              TimeHelper.date.getDateUTCMidnight()
            );
          }
        });
        return clone;
      });
      setLoading(false);
      return;
    }

    const idToken = await getIdToken();

    if (idToken) {
      const followUp = followUps.find((fu) => fu.followUpId === followUpId);

      if (followUp) {
        return MedicalService.ChildService.updateFollowUp(
          idToken,
          followUp.medicalChildId,
          followUpId,
          followUp.startDate,
          TimeHelper.strings.getDateStringFromDate(
            TimeHelper.date.getDateUTCMidnight()
          )
        )
          .then(() => {
            setFollowUps((current) => {
              let clone = [...current];
              clone.forEach((fu) => {
                if (!fu.endDate && fu.followUpId === followUpId) {
                  fu.endDate = TimeHelper.strings.getDateStringFromDate(
                    TimeHelper.date.getDateUTCMidnight()
                  );
                }
              });
              return clone;
            });
            return;
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated"));
    }
  };

  const modifySideEffects = async (
    followUp: FrontEndFollowUp
  ): Promise<void> => {
    setLoading(true);

    const sideEffectIds = followUp.followedSideEffectIds;

    const idToken = await getIdToken();

    if (idToken) {
      return MedicalService.ChildService.updateFollowUpSideEffects(
        idToken,
        followUp.medicalChildId,
        followUp.followUpId,
        sideEffectIds
      )
        .then((res) => {
          setFollowUps((current) => {
            const clone = [...current];

            clone.forEach((fu) => {
              if (fu.followUpId === followUp.followUpId) {
                fu.followedSideEffectIds = res
                  // Only shown active side effects.
                  .filter((fuse) => fuse.isActive)
                  .map((fuse) => fuse.sideEffectId);
              }
            });

            return clone;
          });

          setFollowUpSideEffects((current) => {
            return mergeFollowUpSideEffectState(
              res,
              current,
              followUp.followUpId
            );
          });

          return;
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated"));
    }
  };

  const correctPrescription = async (followUp: FrontEndFollowUp) => {
    setLoading(true);

    const followUpToAdd = followUp;

    if (
      followUp.takesOtherMedication &&
      process.env.REACT_APP_MEDICAL_OTHER_MEDICATION_ID
    ) {
      followUpToAdd.prescriptions.push({
        prescriptionId: v4(),
        drug: {
          doses: [],
          drugId: process.env.REACT_APP_MEDICAL_OTHER_MEDICATION_ID,
        },
      });
    }

    const idToken = await getIdToken();

    if (idToken) {
      return MedicalService.ChildService.updateFollowUpPrescription(
        idToken,
        followUpToAdd.medicalChildId,
        followUpToAdd.followUpId,
        followUpToAdd.startDate,
        followUpToAdd.prescriptions
      )
        .then(() => {
          setFollowUps((current) => {
            const clone = [...current];

            clone.forEach((fu) => {
              if (fu.followUpId === followUp.followUpId) {
                fu.startDate = followUp.startDate;
                fu.prescriptions = followUp.prescriptions;
                fu.takesOtherMedication = followUp.takesOtherMedication;
              }
            });

            return clone;
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      return Promise.reject(new Error("Not authenticated"));
    }
  };

  return {
    followUps,
    loading,
    helpers: {
      getFollowUpById,
      getFollowUpsByChild,
      getActiveFollowUpForChild,
      getLastWeekFollowUpByChild,
      getPreviousSideEffectsForChild,
      getFollowUpSideEffectsForLastWeeksJournal,
      getIntensityCategory,
      getRecordsForFollowUpSideEffectPeriod,
      getFollowUpSideEffectStatusLastWeek,
    },
    requests: {
      modifySideEffects,
      createFollowUp,
      terminateFollowUp,
      correctPrescription,
      createNewRecords,
    },
  };
};

export default useFollowUps;

export const mergeFollowUpSideEffectState = (
  newSideEffects: FollowedSideEffect[],
  currentFollowUpSideEffects: FollowedSideEffect[],
  followUpId: string
): FollowedSideEffect[] => {
  const clone = [...currentFollowUpSideEffects];

  // Get all exisiting side effect records for this follow-up.
  const existingFUSEs = clone.filter((fuse) => fuse.followUpId === followUpId);

  newSideEffects.forEach((fuse) => {
    // Check if follow-up side-effect exists already.
    let existingFUSEIndex = existingFUSEs.findIndex(
      (efuse) => efuse.sideEffectId === fuse.sideEffectId
    );

    if (existingFUSEIndex !== -1) {
      // If exists update the activeStatusChanges.
      existingFUSEs[existingFUSEIndex].activeStatusChanges =
        fuse.activeStatusChanges;
    } else {
      // If doesn't exist create record.
      clone.push({
        startDate: fuse.startDate,
        followUpId: fuse.followUpId,
        sideEffectId: fuse.sideEffectId,
        activeStatusChanges: fuse.activeStatusChanges,
        isActive: fuse.isActive,
        recordsLast4Weeks: [],
      });
    }
  });

  return clone;
};
