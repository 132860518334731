import { i18n } from "i18next";

const getAppLanguageFromI18n = (i18n: i18n): "en" | "fr" => {
  return i18n.language === "en" ? "en" : "fr";
};

const languageHelper = {
  getAppLanguageFromI18n,
};

export default languageHelper;
