import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const JournalIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="journal">
      <path
        d="M 2.3178574,7.6190475 H 15.651191 V 9.523809 h 1.904761 V 3.8095237 c 0,-1.047619 -0.857143,-1.9047618 -1.904761,-1.9047618 H 14.69881 V 0 H 12.794048 V 1.9047619 H 5.1750002 V 0 H 3.2702383 V 1.9047619 H 2.3178574 c -1.0571429,0 -1.8952381,0.8571428 -1.8952381,1.9047618 L 0.4130955,17.142857 c 0,1.047618 0.847619,1.904762 1.9047619,1.904762 H 8.9845239 V 17.142857 H 2.3178574 Z m 0,-3.8095238 H 15.651191 V 5.7142856 H 2.3178574 Z M 19.308333,13.6 l -0.676191,0.67619 -2.019048,-2.019047 0.676192,-0.676191 c 0.371428,-0.371429 0.971428,-0.371429 1.342856,0 l 0.676191,0.676191 c 0.371429,0.371428 0.371429,0.971428 0,1.342857 z M 15.936905,12.933333 17.955951,14.952381 12.908334,20 h -2.019048 v -2.019048 z"
        fill="#000000"
        id="path824"
      />
    </BaseIcon>
  );
};

export default JournalIcon;
