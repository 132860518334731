import React, { ReactNode } from "react";
import MuiButton from "@material-ui/core/Button";
import clsx from "clsx";
import Button from "@material-ui/core/Button";

interface IconButtonProps {
  icon: ReactNode;
}

const IconButton: React.FC<
  React.ComponentProps<typeof MuiButton> & IconButtonProps
> = ({ icon, ...props }) => {
  const ROOT_CLASS = "icon-button";

  return (
    <Button
      variant="contained"
      {...props}
      className={clsx(
        ROOT_CLASS,
        ROOT_CLASS + "--" + props.color,
        props.className
      )}
    >
      {icon}
    </Button>
  );
};

export default IconButton;
