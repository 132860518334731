import { Card, Grid } from "@material-ui/core";
import clsx from "clsx";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import React from "react";
import { useTranslation } from "react-i18next";

export interface FollowUpSummary {
  completedObservations: number;
  averageFrequency: number;
  averageIntensity: number;
}

interface SummaryCardProps {
  summary?: FollowUpSummary;
  rootClass: string;
}

const SummaryCard: React.FC<
  SummaryCardProps & React.HTMLAttributes<HTMLDivElement>
> = ({ summary, rootClass, ...props }) => {
  const ROOT_CLASS = "summary-card";

  const { t } = useTranslation();

  const {
    helpers: { getIntensityCategory },
  } = useFollowUps();

  return (
    <Card
      classes={{
        root: clsx(
          rootClass,
          rootClass + "--summary",
          ROOT_CLASS,
          props.className
        ),
      }}
    >
      <div className={ROOT_CLASS + "__observations-badge"}>
        {summary?.completedObservations ?? 0}
      </div>
      <div className={ROOT_CLASS + "__header"}>
        <h4>{t("dashboard.summary.observations")}</h4>
        <svg
          viewBox="0 0 355 79"
          preserveAspectRatio="none"
          fill="none"
          id="svg826"
          xmlns="http://www.w3.org/2000/svg"
          className={ROOT_CLASS + "__svg-background"}
        >
          <path
            d="M 177,79 C 232,79 355,62.888 355,62.888 V 0 L 0,2.93277e-5 V 0 H 5.41102e-6 L 2.03995e-6,54.872 C 28.276,54.8049 103.068,78.9999 177,79 Z"
            fill="#e5e5e5"
            id="path824"
          />
        </svg>
      </div>
      <Grid
        container
        justifyContent="space-around"
        classes={{ root: ROOT_CLASS + "__body" }}
      >
        <Grid item xs={5}>
          <div className={ROOT_CLASS + "__body-section"}>
            <h5>{t("dashboard.summary.frequency")}</h5>
            <span>
              {summary && summary.averageFrequency !== -1 ? (
                summary.averageFrequency
              ) : (
                <hr />
              )}
            </span>
          </div>
        </Grid>
        <Grid item xs={5}>
          <div className={ROOT_CLASS + "__body-section"}>
            <h5>{t("dashboard.summary.intensity")}</h5>
            <span>
              {summary && summary.averageIntensity !== -1 ? (
                t(
                  "dashboard.summary.categories." +
                    getIntensityCategory(summary.averageIntensity)
                )
              ) : (
                <hr />
              )}
            </span>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
};

export default SummaryCard;
