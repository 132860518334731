import Drawer from "@material-ui/core/Drawer";
import { IconMenuButton } from "commons/components/Inputs/Button";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface MenuDrawerItem {
  translationKey: string;
  icon: ReactNode;
  onClick: VoidFunction;
}

interface MenuDrawerProps {
  menuItems: MenuDrawerItem[];
}

const MenuDrawer: React.FC<
  React.ComponentProps<typeof Drawer> & MenuDrawerProps
> = ({ menuItems, ...props }) => {
  const { t } = useTranslation();

  const ROOT_CLASS = "menu-drawer";

  return (
    <Drawer
      {...props}
      className={ROOT_CLASS}
      classes={{ paper: ROOT_CLASS + "__paper" }}
    >
      {menuItems.map((mi, i) => {
        return (
          <div key={i}>
            {i !== 0 ? <hr className={ROOT_CLASS + "__divider"} /> : null}
            <IconMenuButton
              text={t(mi.translationKey)}
              icon={mi.icon}
              onClick={mi.onClick}
            />
          </div>
        );
      })}
    </Drawer>
  );
};

export default MenuDrawer;
