import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Dialog } from "commons/components/Layout/Components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../Inputs";

interface YesNoDialogProps {
  title: string;
  body: JSX.Element;
  open: boolean;
  onYes: VoidFunction;
  onNo: VoidFunction;
  yes?: string;
  no?: string;
}

const YesNoDialog: React.FC<YesNoDialogProps> = ({
  title,
  body,
  open,
  onYes,
  onNo,
  yes,
  no,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open}>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item container>
            <Box mx="auto">
              <h3>{title}</h3>
            </Box>
          </Grid>
          <Grid item container justifyContent="center">
            {body}
          </Grid>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={onNo}>
                {no ?? t("generic.no")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={onYes}>
                {yes ?? t("generic.yes")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default YesNoDialog;
