import React from "react";
import {
  AuthError,
  LoginPage,
  useAuth,
} from "@neurosolutionsgroup/authentication";
import { FormButton } from "@neurosolutionsgroup/components";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const {
    actions: { isAuthenticated },
  } = useAuth();
  const onSuccess = () => {
    console.log("login success");
  };

  const onFailure = (err: AuthError) => {
    console.log(JSON.stringify(err));
    console.log("login failure");
  };

  const onClickRegistration = () => {
    navigate("/authentication/registration");
  };

  const onClickForgotPassword = () => {
    navigate("/authentication/recoveraccount");
  };

  return (
    <div>
      {isAuthenticated() ? <Navigate to="/app/follow-up" /> : null}
      <FormButton
        type={"button"}
        value={"register"}
        onClick={onClickRegistration}
      />
      <LoginPage onSuccess={onSuccess} onFailure={onFailure} />
      <FormButton
        type={"button"}
        value={"Forgot Password"}
        onClick={onClickForgotPassword}
      />
    </div>
  );
};

export default Login;
