import React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Datum } from "./data";

interface FrequencyGraphProps {
  data: Datum[];
}

const FrequencyGraph: React.FC<FrequencyGraphProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 15,
          right: 30,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis
          dataKey="valueX"
          domain={["dataMin", "dataMax"]}
          axisLine={false}
          tickLine={false}
          tickFormatter={(v, i) => {
            return `${t("dashboard.graph.week")} ${v}`;
          }}
          tick={{ fontSize: 12, fill: "white" }}
          height={25}
        />
        <YAxis
          ticks={["0", "1", "2", "3"]}
          domain={[-0.5, 3]}
          axisLine={false}
          tickLine={false}
          tickMargin={0}
          tick={{ fontSize: 10, fill: "black" }}
          width={70}
          tickFormatter={(v) => {
            switch (v) {
              case "0":
                return t("dashboard.graph.frequency.bins.0");
              case "1":
                return t("dashboard.graph.frequency.bins.1");
              case "2":
                return t("dashboard.graph.frequency.bins.2");
              case "3":
                return t("dashboard.graph.frequency.bins.3");
              default:
                return "N/A";
            }
          }}
        />
        <Bar
          dataKey="valueY"
          fill="#000000"
          barSize={20}
          radius={[5, 5, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FrequencyGraph;
