import { Box, Grid, Typography } from "@material-ui/core";
import FaceIcon, { faceState } from "../components/FaceIcon";
import FeedbackIcon, { feedbackIconShape } from "../components/FeedbackIcon";
import Slider from "@material-ui/core/Slider";
import { useTranslation } from "react-i18next";

interface FrequencyFormProps {
  frequency: number;
  setFrequncy: (newValue: number) => void;
}

const FrequencyForm = ({
  frequency,
  setFrequncy,
}: FrequencyFormProps): JSX.Element => {
  const { t } = useTranslation();
  const ROOT_CLASS = "frequency-form";

  const marks = () => {
    let output = [];
    for (let i = 0; i < 8; i++) {
      output.push({ value: i });
    }
    return output;
  };

  const iconState = (): faceState => {
    if (frequency <= 2) {
      return faceState.happy;
    } else if (frequency >= 5) {
      return faceState.sad;
    } else {
      return faceState.neutral;
    }
  };

  return (
    <Box className={ROOT_CLASS} p={2}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={ROOT_CLASS + "__grid"}
      >
        <Box mt={15} className={ROOT_CLASS + "__feedback-container"}>
          <FeedbackIcon
            shape={feedbackIconShape.round}
            icon={<FaceIcon state={iconState()} />}
          />
        </Box>
        <Box mt={5} className={ROOT_CLASS + "__input-container"}>
          <Slider
            marks={marks()}
            min={marks()[0].value}
            max={marks()[marks().length - 1].value}
            step={null}
            value={frequency}
            onChange={(_, v) => setFrequncy(v as number)}
            className={ROOT_CLASS + "__frequency-slider"}
            color="secondary"
          />
        </Box>
        <Box my={1}>
          <Typography className={ROOT_CLASS + "__slider-text"}>
            {frequency} {t("generic.day", { count: frequency })}
          </Typography>
        </Box>
      </Grid>
    </Box>
  );
};

export default FrequencyForm;
