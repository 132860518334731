import { Page } from "commons/components";
import NavigationTabs from "commons/components/Layout/Components/Tabs/NavigationTabs";
import TabPanel from "commons/components/Layout/Components/Tabs/Tabs";
import React, { useState } from "react";
import ObservationDisplay from "./observationDisplay/ObservationDisplay";
import NotesPage from "./Notes/NotesPage";
import useChildren from "commons/hooks/children/useChildren";
import { PageHeader } from "commons/components/Layout/Components";

const JournalPage: React.FC = () => {
  const ROOT_CLASS = "journal-page";
  const { selectedChildId } = useChildren();

  const [displayState, setDisplayState] = useState(0);

  const pageHeader = (): JSX.Element => {
    return <PageHeader />;
  };

  return (
    <Page header={pageHeader()} className="journal-page">
      <div className={ROOT_CLASS + "__tabs"}>
        <NavigationTabs
          value={displayState}
          onChange={(e, value) => setDisplayState(value)}
          tabs={[
            {
              label: "Observations",
              index: 0,
            },
            {
              label: "Notes",
              index: 1,
            },
          ]}
          id="journal-tabs"
        />
      </div>
      <TabPanel value={displayState} index={0} id="journal-tabs">
        {selectedChildId ? (
          <ObservationDisplay activeChildId={selectedChildId} />
        ) : null}
      </TabPanel>
      <TabPanel value={displayState} index={1} id="journal-tabs">
        <NotesPage />
      </TabPanel>
    </Page>
  );
};

export default JournalPage;
