import { Box, Drawer, Typography } from "@material-ui/core";
import useChildren from "commons/hooks/children/useChildren";
import React from "react";

interface ChildSelectorProps {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
}

const ChildSelectorDrawer: React.FC<
  ChildSelectorProps & React.HTMLProps<HTMLDivElement>
> = ({ isOpen, setIsOpen, ...props }) => {
  const ROOT_CLASS = "child-selector";
  const { children, setSelectedChildId } = useChildren();

  return (
    <Drawer
      classes={{ paperAnchorBottom: ROOT_CLASS + "__menu" }}
      anchor="bottom"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      {children.map((c, i) => {
        return (
          <div key={i}>
            <Box
              onClick={() => {
                setSelectedChildId(c.medicalChildId);
                setIsOpen(false);
              }}
              p={2}
              key={c.medicalChildId}
            >
              <Typography align="center">
                <b>{c.name}</b>
              </Typography>
            </Box>
            {!(
              children[children.length - 1].medicalChildId === c.medicalChildId
            ) ? (
              <hr className={ROOT_CLASS + "__menu__divider"} />
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </Drawer>
  );
};

export default ChildSelectorDrawer;
