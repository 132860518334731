import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { getTabProps } from "./Tabs";
import clsx from "clsx";

interface NavigationOption {
  index: number;
  label: string;
}

interface NavigationTabsProps {
  tabs: NavigationOption[];
  id: string;
}

const NavigationTabs: React.FC<
  React.ComponentProps<typeof Tabs> & NavigationTabsProps
> = ({ tabs, id, ...props }) => {
  const ROOT_CLASS = "navigation-tabs";

  return (
    <Tabs
      {...props}
      className={clsx(props.className, ROOT_CLASS)}
      classes={{
        flexContainer: ROOT_CLASS + "__flex-container",
      }}
      TabIndicatorProps={{
        style: { display: "none" },
      }}
    >
      {props.value}
      {tabs.map((o, i) => {
        return (
          <Tab
            key={i}
            label={o.label}
            {...getTabProps(o.index, id)}
            className={clsx({ [ROOT_CLASS + "__button--first"]: i === 0 })}
            classes={{
              root: ROOT_CLASS + "__button",
            }}
          />
        );
      })}
    </Tabs>
  );
};

export default NavigationTabs;
