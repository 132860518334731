import clsx from "clsx";
import moment from "moment";
import { Moment } from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface DateOptionProps {
  date: Moment;
  selected: boolean;
  onClick: VoidFunction;
  className?: string;
  tagged?: boolean;
}

const DateOption: React.FC<DateOptionProps> = ({
  date,
  selected,
  onClick,
  className,
  tagged = false,
}) => {
  const ROOT_CLASS = "date-picker-option";

  const { t } = useTranslation();

  return (
    <div className={clsx(ROOT_CLASS + "__wrapper")}>
      <div
        className={clsx(
          ROOT_CLASS,
          {
            [ROOT_CLASS + "--selected"]: selected,
          },
          className
        )}
        onClick={onClick}
      >
        {tagged && <div className={ROOT_CLASS + "__tag"} />}
        <span className={ROOT_CLASS + "__month"}>
          {t(`time.months.${date.get("month")}.short`)}
        </span>
        <span className={ROOT_CLASS + "__date"}>{date.get("date")}</span>
      </div>
    </div>
  );
};

interface DateOptionsProps {
  rootDate: Moment;
  setDate: (value: Moment) => void;
  pastDays: number;
  date: Date;
  className?: string;
  taggedDates?: Date[];
}

const DateOptions: React.FC<DateOptionsProps> = ({
  rootDate,
  setDate,
  pastDays,
  date,
  className,
  taggedDates,
}) => {
  const ROOT_CLASS = "date-picker-container";

  const isSelected = useCallback(
    (d: Moment): boolean => d.diff(moment(date), "minutes") === 0,
    [date]
  );

  // Render carousel of dates.
  const renderDates = useCallback(() => {
    // Get date n number of days before state date.
    const getOffsetDate = (offset: number) => {
      let dateWithOffset = moment(rootDate);

      dateWithOffset = dateWithOffset.add(offset, "days");

      return dateWithOffset;
    };

    const onDateSelection = (newDate: Moment) => {
      setDate(newDate);
    };

    const dateIsTagged = (dateArray: Date[], d: Date): boolean => {
      return dateArray.find((td) => td.getTime() === d.getTime()) !== undefined;
    };

    let dates: JSX.Element[] = [];
    for (let i = 0; i < pastDays; i++) {
      const d = getOffsetDate(0 - i);
      dates.push(
        <DateOption
          key={i}
          date={d}
          selected={isSelected(d)}
          onClick={() => onDateSelection(d)}
          tagged={taggedDates ? dateIsTagged(taggedDates, d.toDate()) : false}
        />
      );
    }
    return dates;
  }, [rootDate, setDate, pastDays, isSelected, taggedDates]);

  return <div className={clsx(ROOT_CLASS, className)}>{renderDates()}</div>;
};

export default DateOptions;
