import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Loader } from "commons/components";
import { Button } from "commons/components/Inputs";
import { FooterButtons } from "commons/components/Layout/Components";
import {
  CancelDialog,
  ErrorDialog,
} from "commons/components/Layout/Components/Dialog/Dialog";
import CloseablePage from "commons/components/Layout/Page/CloseablePage/CloseablePage";
import TimeHelper from "commons/helpers/Time";
import useNotes from "commons/hooks/notes/useNotes";
import { ApiError } from "commons/models/ApiError";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import NoteForm from "./NoteForm";

interface AddNoteFormProps {
  open: boolean;
  onClose: VoidFunction;
  date: Date;
  medicalChildId: string;
}

const AddNoteForm: React.FC<AddNoteFormProps> = ({
  open,
  onClose,
  date,
  medicalChildId,
}) => {
  const ROOT_CLASS = "add-note-form";

  const { t } = useTranslation();

  const { addNote, loading } = useNotes();

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);

  // Form state.
  const [sideEffectId, setSideEffectId] = useState<string | null>(null);
  const [time, setTime] = useState<number | undefined>(
    TimeHelper.date.getCurrentTime()
  );
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const resetForm = () => {
    setSideEffectId(null);
    setTime(TimeHelper.date.getCurrentTime());
    setTitle("");
    setText("");
  };

  const onSubmit = () => {
    if (sideEffectId) {
      addNote({
        noteId: v4(),
        title: title,
        text: text,
        eventDate: TimeHelper.strings.getDateStringFromDate(date),
        eventTime: time,
        sideEffectId: sideEffectId !== "other" ? sideEffectId : undefined,
        medicalChildId: medicalChildId,
      })
        .then(() => {
          resetForm();
          onClose();
        })
        .catch((error: ApiError) => {
          setApiError(error);
        });
    }
  };

  const onCancel = () => {
    resetForm();

    setShowCancelDialog(false);

    onClose();
  };

  const submitIsDisabled = useCallback((): boolean => {
    if (title.length === 0) {
      return true;
    }

    if (text.length === 0) {
      return true;
    }

    if (!sideEffectId) {
      return true;
    }

    return false;
  }, [sideEffectId, text.length, title.length]);

  return (
    <CloseablePage
      isOpen={open}
      onClose={() => setShowCancelDialog(true)}
      title={t("journal.note.add.label")}
      closeElement={<div>{t("generic.cancel")}</div>}
      className={ROOT_CLASS}
      hasFooterButtons
    >
      <Box p={1}>
        <NoteForm
          sideEffectId={sideEffectId}
          setSideEffectId={setSideEffectId}
          time={time}
          setTime={setTime}
          title={title}
          setTitle={setTitle}
          text={text}
          setText={setText}
        />
      </Box>
      <FooterButtons>
        <Button
          variant="contained"
          color="secondary"
          onClick={onSubmit}
          disabled={submitIsDisabled()}
        >
          {t("generic.save")}
        </Button>
      </FooterButtons>
      <CancelDialog
        open={showCancelDialog}
        onCancel={() => setShowCancelDialog(false)}
        onConfirm={onCancel}
        body={
          <Grid container justifyContent="center">
            <Typography align="center">
              <b>{t("generic.cancelDialog.text.one")}</b> <br />
              <b>{t("journal.note.add.cancel")}</b> <br />
              <b>{t("generic.cancelDialog.text.three")}</b> <br />
            </Typography>
          </Grid>
        }
      />
      <ErrorDialog
        title={t("generic.errorDialog.title", {
          code: apiError?.code ?? "500",
        })}
        text={t("generic.errorDialog.text")}
        action={t("generic.tryAgain")}
        open={!!apiError}
        onClick={() => setApiError(undefined)}
      />
      <Loader show={loading} />
    </CloseablePage>
  );
};

export default AddNoteForm;
