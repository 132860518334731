import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import YesNoDialog from "../YesNoDialog/YesNoDialog";

interface CancelDialogProps {
  title?: string;
  body?: JSX.Element;
  open: boolean;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
}

const CancelDialog: React.FC<CancelDialogProps> = ({
  title,
  body,
  open,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <YesNoDialog
      title={title ?? t("generic.cancelDialog.title")}
      body={
        body ?? (
          <Grid container justifyContent="center">
            <Typography align="center">
              <b>{t("generic.cancelDialog.text.one")}</b> <br />
              <b>{t("generic.cancelDialog.text.two")}</b> <br />
              <b>{t("generic.cancelDialog.text.three")}</b> <br />
            </Typography>
          </Grid>
        )
      }
      open={open}
      onNo={onCancel}
      onYes={onConfirm}
    />
  );
};

export default CancelDialog;
