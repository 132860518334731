import { FullScreenSelect, SelectOption } from "commons/components/Inputs";
import languageHelper from "commons/helpers/Language";
import TimeHelpers from "commons/helpers/Time";
import useChildren from "commons/hooks/children/useChildren";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import useMedication from "commons/hooks/medication/useMedication";
import { FrontEndFollowUp } from "commons/models/Medical";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface FollowUpSelectorProps {
  selectedFollowUpId: string | undefined;
  setSelectedFollowUpId: (value: string | undefined) => void;
  setSelectedFollowUpLabel: (value: string | JSX.Element) => void;
  setIsCurrentFollowUp: (value: boolean) => void;
}

const FollowUpSelector: React.FC<FollowUpSelectorProps> = ({
  selectedFollowUpId,
  setSelectedFollowUpId,
  setSelectedFollowUpLabel,
  setIsCurrentFollowUp,
}) => {
  const { t, i18n } = useTranslation();

  const {
    helpers: { getFollowUpsByChild },
  } = useFollowUps();

  const { selectedChildId } = useChildren();

  const { getMedicationFromId } = useMedication();

  const [options, setOptions] = useState<SelectOption[]>([]);

  const medicationNameConstructor = useCallback(
    (fu: FrontEndFollowUp): string => {
      return (
        (getMedicationFromId(fu.prescriptions[0].drug.drugId)?.name[
          languageHelper.getAppLanguageFromI18n(i18n)
        ] ?? "") +
        " " +
        fu.prescriptions[0].drug.doses
          .map((d) => d.quantity)
          .reduce((p, c) => {
            return p + c;
          }, 0) +
        "mg"
      );
    },
    [getMedicationFromId, i18n]
  );

  const medicationLabelConstructor = useCallback(
    (fu: FrontEndFollowUp): JSX.Element => {
      return (
        <>
          <b>{medicationNameConstructor(fu)}</b>
          {" ("}
          {fu.startDate}
          {"/"}
          {fu.endDate ? fu.endDate : t("dashboard.medication.today")}
          {")"}
        </>
      );
    },
    [medicationNameConstructor, t]
  );

  useEffect(() => {
    if (selectedChildId) {
      const options = getFollowUpsByChild(selectedChildId)
        .sort((a, b) =>
          TimeHelpers.date.compareToApiDateStrings(a.startDate, b.startDate)
        )
        .filter((fu) => fu.prescriptions.length > 0)
        .map((fu, i) => ({
          value: fu.followUpId,
          label: medicationLabelConstructor(fu),
          name: medicationNameConstructor(fu),
          index: i,
        }));

      setOptions(options);
    }
  }, [
    selectedChildId,
    i18n,
    getFollowUpsByChild,
    medicationLabelConstructor,
    medicationNameConstructor,
  ]);

  useEffect(() => {
    if (options.length > 0) {
      setSelectedFollowUpId(options[0].value);
      setSelectedFollowUpLabel(options[0].label);
    } else {
      setSelectedFollowUpId(undefined);
      setSelectedFollowUpLabel("");
    }
  }, [options, setSelectedFollowUpId, setSelectedFollowUpLabel]);

  return (
    <FullScreenSelect
      options={options}
      value={selectedFollowUpId ?? null}
      onChange={(o) => {
        setSelectedFollowUpId(o.value);
        setSelectedFollowUpLabel(o.label);
        setIsCurrentFollowUp(o.index === 0);
      }}
      placeholder={t("dashboard.medication.title")}
      selectTitle={t("dashboard.medication.title")}
      className="followup-selector"
      drawerClass="followup-selector"
    />
  );
};

export default FollowUpSelector;
