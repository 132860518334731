import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const CloseIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="close">
      <path
        d="M 20,2.0142857 17.985714,0 10,7.9857143 2.0142857,0 0,2.0142857 7.9857143,10 0,17.985714 2.0142857,20 10,12.014286 17.985714,20 20,17.985714 12.014286,10 Z"
        fill="#000000"
        id="path824"
      />
    </BaseIcon>
  );
};

export default CloseIcon;
