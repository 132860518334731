import clsx from "clsx";
import { AlertIcon } from "commons/components/Icons";
import React from "react";
import BaseAlert from "../BaseAlert";

interface ErrorAlertProps {
  display: boolean;
  text: string;
  onClose?: VoidFunction;
  className?: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  display,
  text,
  onClose,
  className,
}) => {
  return (
    <BaseAlert
      display={display}
      icon={<AlertIcon />}
      text={text}
      onClose={onClose}
      className={clsx("alert--error", className)}
    />
  );
};

export default ErrorAlert;
