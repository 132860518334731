import {
  CreateRecordBody,
  CreateRecordInstance,
  CreateUpdateNoteBody,
  Note,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import {
  constructBearerTokenHeader,
  MEDICALCHILD_FUNCTION,
  urlGenerator,
} from ".";
import { handleApiError } from "./error";

const getNotes = async (
  idToken: string,
  medicalChildId: string
): Promise<Note[]> => {
  const URL = urlGenerator(`${MEDICALCHILD_FUNCTION}/${medicalChildId}/note`);

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .get(URL, {
      headers: headers,
    })
    .then((response) => {
      return response.data as Note[];
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const addNote = async (
  idToken: string,
  medicalChildId: string,
  note: CreateUpdateNoteBody
): Promise<Note> => {
  const URL = urlGenerator(`${MEDICALCHILD_FUNCTION}/${medicalChildId}/note`);

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .post(URL, note, {
      headers: headers,
    })
    .then((response) => {
      return response.data as Note;
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const editNote = async (
  idToken: string,
  medicalChildId: string,
  note: CreateUpdateNoteBody
): Promise<Note> => {
  const URL = urlGenerator(
    `${MEDICALCHILD_FUNCTION}/${medicalChildId}/note/${note.noteId}`
  );

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .put(
      URL,
      {
        eventDate: note.eventDate,
        eventTime: note.eventTime,
        title: note.title,
        text: note.text,
        sideEffectId: note.sideEffectId,
      },
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response.data as Note;
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const deleteNote = async (
  idToken: string,
  medicalChildId: string,
  noteId: string
): Promise<string> => {
  const URL = urlGenerator(
    `${MEDICALCHILD_FUNCTION}/${medicalChildId}/note/${noteId}`
  );

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .delete(URL, {
      headers: headers,
    })
    .then(() => {
      return noteId;
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const addRecord = async (
  idToken: string,
  medicalChildId: string,
  observationPeriodId: string,
  sideEffectId: string,
  records: CreateRecordInstance[]
) => {
  const URL = urlGenerator(
    `${MEDICALCHILD_FUNCTION}/${medicalChildId}/followUp/${observationPeriodId}/record`
  );

  const headers = constructBearerTokenHeader(idToken);

  const body: CreateRecordBody = {
    sideEffectId: sideEffectId,
    records: records,
  };

  return axios
    .post(URL, body, { headers: headers })
    .then(() => {
      return;
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const JournalService = {
  getNotes,
  addNote,
  editNote,
  deleteNote,
  addRecord,
};

export default JournalService;
