import Grid from "@material-ui/core/Grid";
import { Dose, Prescription } from "@neurosolutionsgroup/models";
import { Button } from "commons/components/Inputs";
import { FooterButtons } from "commons/components/Layout/Components";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import DoseForm from "./DoseForm/DoseForm";
import MedicationForm from "./MedicationForm/MedicationForm";

interface PrescriptionFormProps {
  onSubmit: (
    prescription: Prescription,
    takesAnotherMedication: boolean,
    startDate: Date
  ) => void;
  submitText?: string;
  initialStartDate: Date;
  initialTakesOtherMedication: boolean;
  prescription?: Prescription;
  isModified?: boolean;
  onModification?: React.Dispatch<SetStateAction<boolean>>;
  disabledIfUnmodified?: boolean;
}

const PrescriptionForm: React.FC<PrescriptionFormProps> = ({
  onSubmit,
  submitText,
  initialStartDate,
  initialTakesOtherMedication,
  prescription,
  isModified,
  onModification,
  disabledIfUnmodified,
}) => {
  const { t } = useTranslation();

  // Form state.
  const [id] = useState<string>(prescription?.prescriptionId ?? v4());
  const [medication, setMedication] = useState<string | null>(
    prescription?.drug.drugId ?? null
  );
  const [doses, setDoses] = useState<Dose[]>(
    prescription?.drug.doses ?? [
      {
        quantity: 0,
        time: -1,
      },
    ]
  );
  const [takesOtherMedication, setTakesOtherMedication] = useState(
    initialTakesOtherMedication
  );
  const [startDate, setStartDate] = useState(initialStartDate);

  // Reset doses when medication changed as doses change.
  useEffect(() => {
    if (medication !== prescription?.drug.drugId) {
      setDoses([
        {
          quantity: 0,
          time: -1,
        },
      ]);
    }
  }, [id, medication, prescription]);

  // Check if submission should be disabled.
  const submitDisabled = useCallback((): boolean => {
    if (disabledIfUnmodified && !isModified) {
      return true;
    }

    if (!medication) {
      return true;
    }

    if (doses.length > 0) {
      let dosesValid = true;
      doses.forEach((d) => {
        if (d.quantity === 0 || d.time < 0) {
          dosesValid = false;
        }
      });

      if (!dosesValid) {
        return true;
      }
    } else {
      return true;
    }

    return false;
  }, [disabledIfUnmodified, isModified, medication, doses]);

  useEffect(() => {
    if (onModification) {
      if (
        medication !== prescription?.drug.drugId ||
        !areDosesEqual(doses, prescription.drug.doses ?? []) ||
        takesOtherMedication !== initialTakesOtherMedication ||
        startDate.getTime() !== initialStartDate.getTime()
      ) {
        onModification(true);
      } else {
        onModification(false);
      }
    }
  }, [
    medication,
    doses,
    takesOtherMedication,
    startDate,
    onModification,
    prescription,
    initialTakesOtherMedication,
    initialStartDate,
  ]);

  const areDosesEqual = (dosesA: Dose[], dosesB: Dose[]): boolean => {
    if (dosesA.length !== dosesB.length) {
      return false;
    }

    for (let i = 0; i < dosesA.length; i++) {
      if (
        dosesA[i].quantity !== dosesB[i].quantity ||
        dosesA[i].time !== dosesB[i].time
      ) {
        return false;
      }
    }

    return true;
  };

  const onFormSubmit = () => {
    if (medication) {
      onSubmit(
        {
          prescriptionId: v4(),
          drug: {
            drugId: medication,
            doses,
          },
        },
        takesOtherMedication,
        startDate
      );
    }
  };

  return (
    <>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item xs={12}>
          <MedicationForm
            medication={medication}
            setMedication={setMedication}
            startDate={startDate}
            setStartDate={setStartDate}
          />
        </Grid>
        <Grid item xs={12}>
          <DoseForm
            doses={doses}
            setDoses={setDoses}
            prescriptionId={id}
            medicationId={medication}
            takesOtherMedication={takesOtherMedication}
            setTakesOtherMedication={setTakesOtherMedication}
          />
        </Grid>
      </Grid>

      <FooterButtons>
        <Button
          variant="contained"
          color="secondary"
          disabled={submitDisabled()}
          onClick={() => onFormSubmit()}
        >
          {submitText ?? t("generic.next")}
        </Button>
      </FooterButtons>
    </>
  );
};

export default PrescriptionForm;
