import { SideEffect } from "@neurosolutionsgroup/models";
import axios from "axios";
import { urlGenerator } from ".";

const getSideEffects = (): Promise<SideEffect[]> => {
  const URL = urlGenerator("/sideEffect");

  return axios.get(URL).then((response) => {
    return response.data as SideEffect[];
  });
};

const SideEffectService = { getSideEffects };

export default SideEffectService;
