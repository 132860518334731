import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#000",
    },
    error: {
      main: "#ff002e",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "999px",
        textTransform: "none",
        fontWeight: "bold",
        minWidth: "8em",
        transition: "color .01s",
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: "#c4c4c4",
        borderTopLeftRadius: "1em",
        borderTopRightRadius: "1em",
      },
    },
    MuiSlider: {
      mark: {
        opacity: 0.38,
      },
      markActive: {
        backgroundColor: "#000",
      },
    },
    MuiFormControlLabel: {
      root: {
        justifyContent: "space-between",
      },
      labelPlacementStart: {
        marginLeft: 0,
      },
    },
    MuiCard: {
      root: {
        backgroundColor: "#e5e5e5",
        borderRadius: "1em",
      },
    },
    MuiTableCell: {
      root: {
        padding: 0,
        borderBottom: "none",
      },
    },
    MuiBottomNavigation: {
      root: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#000",
        borderTopLeftRadius: "1em",
        borderTopRightRadius: "1em",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        "color": "#fff",
        "&$selected": {
          color: "red",
        },
      },
    },
    MuiGrid: {
      "spacing-xs-1": {
        width: "auto",
      },
    },
    MuiTypography: {
      body2: {
        fontSize: "0.85em",
      },
    },
  },
});
