import { Box, Button, Grid } from "@material-ui/core";
import { Page } from "commons/components";
import { ArrowIcon } from "commons/components/Icons";
import { ArrowType } from "commons/components/Icons/ArrowIcon";
import CloseablePage from "commons/components/Layout/Page/CloseablePage/CloseablePage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Credits from "./Credits";

interface SettingsProps {}

const Settings: React.FC<SettingsProps> = ({ ...props }) => {
  const ROOT_CLASS = "settings-page";

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openCreditsPage, setOpenCreditsPage] = useState(false);

  return (
    <Page className={ROOT_CLASS}>
      <Box p={1}>
        <Grid container direction="column" spacing={1}>
          <Grid item classes={{ root: ROOT_CLASS + "__header" }}>
            <Button onClick={() => navigate("/app/follow-up")}>
              <ArrowIcon arrowType={ArrowType.empty} />
              {t("navigation.pages.option.title")}
            </Button>
          </Grid>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <h2>{t("navigation.pages.settings.title")}</h2>
            </Grid>
            <Grid item>
              <h3>{t("navigation.pages.settings.privacy.text")}</h3>
              <Box my={1}>
                <a
                  href={t("navigation.pages.settings.privacy.link")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="contained" color="secondary">
                    {t("navigation.pages.settings.privacy.text")}
                  </Button>
                </a>
              </Box>
            </Grid>
            <Grid item>
              <h3>{t("navigation.pages.settings.terms.text")}</h3>
              <Box my={1}>
                <a
                  href={t("navigation.pages.settings.terms.link")}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button variant="contained" color="secondary">
                    {t("navigation.pages.settings.terms.text")}
                  </Button>
                </a>
              </Box>
            </Grid>
            <Grid item>
              <h3>{t("navigation.pages.settings.credits.title")}</h3>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenCreditsPage(true)}
              >
                {t("navigation.pages.settings.credits.title")}
              </Button>
              <Box my={1}>
                <CloseablePage
                  isOpen={openCreditsPage}
                  onClose={() => setOpenCreditsPage(false)}
                  title={t("navigation.pages.settings.credits.title")}
                >
                  <Credits />
                </CloseablePage>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Settings;
