import React from "react";
import MuiButton from "@material-ui/core/Button";
import { AddIcon } from "commons/components/Icons";
import clsx from "clsx";

const AddButton: React.FC<React.ComponentProps<typeof MuiButton>> = ({
  ...props
}) => {
  return (
    <MuiButton
      variant={props.variant ?? "contained"}
      color={props.color ?? "secondary"}
      {...props}
      className={clsx("button--add", props.className)}
    >
      <AddIcon />
    </MuiButton>
  );
};

export default AddButton;
