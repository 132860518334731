import { Box, Button, Grid } from "@material-ui/core";
import { ArrowIcon } from "commons/components/Icons";
import { ArrowType } from "commons/components/Icons/ArrowIcon";
import React from "react";

interface OptionCardProps {
  onClick?: () => void;
  name: string;
}

const OptionCard: React.FC<OptionCardProps> = ({ onClick, name }) => {
  return (
    <Button
      className="option-card__container"
      onClick={onClick}
      style={{
        width: "100%",
        borderRadius: "1rem",
        fontWeight: "normal",
      }}
      variant="contained"
      color="primary"
      disableElevation
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Box m={3}>{name}</Box>
        </Grid>
        <Grid item>
          <Box mt={3} mr={3}>
            <ArrowIcon arrowType={ArrowType.empty} />
          </Box>
        </Grid>
      </Grid>
    </Button>
  );
};

export default OptionCard;
