import "./App.scss";
import { AuthProvider } from "@neurosolutionsgroup/authentication";
import { useTranslation } from "react-i18next";
import AppRouter from "./AppRouter";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme } from "stylesheets/muiTheme";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { useMemo } from "react";

function App() {
  const { i18n } = useTranslation();

  const firebaseConfig = {
    apiKey: "AIzaSyCHoixy0Wu12_uywcbLvsMWV8PNRGG7zAI",
    authDomain: "kairos-dev-c03e6.firebaseapp.com",
    projectId: "kairos-dev-c03e6",
    storageBucket: "kairos-dev-c03e6.appspot.com",
    messagingSenderId: "209451559843",
    appId: "1:209451559843:web:50c20de7ca875a32664816",
  };

  const fbApp = useMemo(() => initializeApp(firebaseConfig), []);

  const auth = getAuth(fbApp);
  const firestore = getFirestore(fbApp);

  if (process.env.NODE_ENV === "development") {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(firestore, "localhost", 8080);
  }

  if (process.env.NODE_ENV !== "development") {
    getPerformance(fbApp);
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider
        reCaptchaKey="6LcEgdIZAAAAANRwraZQ9OJHMbXchth7wIFPDRmp"
        i18n={i18n}
        authInstance={auth}
      >
        <AppRouter />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
