import moment, { Moment } from "moment";
import { utc } from "moment";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import DateOptions from "./DateOptions";

interface SimpleDatePickerProps {
  date: Date;
  setDate: React.Dispatch<SetStateAction<Date>>;
  pastDays?: number;
  label?: string;
  taggedDates?: Date[];
}

const SimpleDatePicker: React.FC<SimpleDatePickerProps> = ({
  date,
  setDate,
  pastDays = 4,
  label,
  taggedDates,
}) => {
  const ROOT_CLASS = "date-picker";

  // The "first" (latest) date to be shown in the carousel.
  const [rootDate, setRootDate] = useState(utc().startOf("day"));

  useEffect(() => {
    if (date) {
      setRootDate(moment(date).utc().startOf("day"));
      setDate(moment(date).utc().startOf("day").toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDateFromUTC = useCallback(
    (value: Moment): void => {
      setDate(new Date(value.unix() * 1000));
    },
    [setDate]
  );

  return (
    <div className={ROOT_CLASS}>
      {label && (
        <label className={ROOT_CLASS + "__label"}>
          <span>{label}</span>
        </label>
      )}

      <DateOptions
        rootDate={rootDate}
        setDate={setDateFromUTC}
        pastDays={pastDays}
        date={date}
        taggedDates={taggedDates}
      />
    </div>
  );
};

export default SimpleDatePicker;
