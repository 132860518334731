import { ArrowIcon } from "commons/components/Icons";
import { ArrowType } from "commons/components/Icons/ArrowIcon";
import { DetailedHTMLProps } from "react";

const KairosBackButton: React.FC<
  DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> = ({ ...props }) => {
  return (
    <div className={props.className + " back-button__wrapper"}>
      <button {...props} className="back-button__element">
        <ArrowIcon arrowType={ArrowType.empty} />
      </button>
    </div>
  );
};

export default KairosBackButton;
