import React from "react";
import {
  AuthError,
  RecoverAccountPage,
} from "@neurosolutionsgroup/authentication";

const RecoverAccount = () => {
  const onSuccess = () => {
    console.log("RecoverAccount success");
  };

  const onFailure = (err: AuthError) => {
    console.log("RecoverAccount failure");
  };

  return <RecoverAccountPage onSuccess={onSuccess} onFailure={onFailure} />;
};

export default RecoverAccount;
