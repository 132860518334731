import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const SearchIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="search">
      <path
        d="m 14.293882,12.474417 h -0.903373 l -0.320183,-0.296284 c 1.12064,-1.250886 1.795312,-2.8748884 1.795312,-4.6414978 0,-3.9391996 -3.327616,-7.1322642 -7.4328192,-7.1322642 C 3.3276158,0.404371 0,3.5974356 0,7.5366352 c 0,3.9391538 3.3276158,7.1322928 7.4328188,7.1322928 1.841052,0 3.5334472,-0.647456 4.8370492,-1.722699 l 0.308748,0.307147 v 0.866896 L 18.296169,19.595629 20,17.960752 Z m -6.8610632,0 c -2.8473414,0 -5.1457976,-2.205569 -5.1457976,-4.9377818 0,-2.7322013 2.2984562,-4.9377245 5.1457976,-4.9377245 2.8473412,0 5.1457972,2.2055232 5.1457972,4.9377245 0,2.7322128 -2.298456,4.9377818 -5.1457972,4.9377818 z"
        fill="#000000"
        id="path824"
      />
    </BaseIcon>
  );
};

export default SearchIcon;
