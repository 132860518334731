import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const CheckMarkIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="archive">
      <path
        d="M 20,3.6399998 7.6,17.787778 0,11.101111 1.4655556,9.445555 7.4,14.652222 l 10.961111,-12.44 z"
        fill="#ffffff"
        id="path98"
      />
    </BaseIcon>
  );
};

export default CheckMarkIcon;
