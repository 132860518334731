import { Box, Grid } from "@material-ui/core";
import BurgerMenuIcon from "commons/components/Icons/BurgerMenuIcon";
import { Button } from "commons/components/Inputs";
import OptionPage from "pages/optionPage/OptionPage";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface OptionMenuButtonProps {}

const OptionMenuButton: React.FC<OptionMenuButtonProps> = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={() => setDrawerIsOpen(true)}
        endIcon={<BurgerMenuIcon />}
        className="option-menu-button"
        variant="contained"
        color="secondary"
      >
        <Box className="option-menu-button__text" pr={1}>
          {t("navigation.pages.option.button")}
        </Box>
        <Grid container justifyContent="space-between"></Grid>
      </Button>
      <OptionPage isOpen={drawerIsOpen} setIsOpen={setDrawerIsOpen} />
    </>
  );
};

export default OptionMenuButton;
