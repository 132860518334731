import { Record } from "@neurosolutionsgroup/models";
import TimeHelpers from "commons/helpers/Time";
import moment from "moment";

export interface Datum {
  valueX: number;
  valueY?: number;
}

export const formatGraphData = (
  periodWeeks: number,
  records: Record[],
  startDate: Date,
  formatter: (answer: number) => number
): Datum[] => {
  const data: Datum[] = Array.from(Array(periodWeeks).keys()).map((k) => {
    return {
      valueX: k + 1,
    };
  });

  let j = 0;

  let expectedDate = moment(
    TimeHelpers.date.getPreviousMonday(startDate)
  ).utc();

  data.forEach((d, i) => {
    if (j < records.length) {
      // Check if week start date matches expected date.
      if (
        expectedDate.unix() ===
        moment(records[j].weekStartDate).utc().startOf("day").unix()
      ) {
        d.valueY = formatter(records[j].answer);
        j++;
      }

      expectedDate.add(7, "days");
    }
  });

  return data;
};
