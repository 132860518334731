import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
  id: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ value, index, id, ...props }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`${id}-tab-${index}`}
      {...props}
    >
      {value === index && props.children}
    </div>
  );
};

export const getTabProps = (index: any, id: string) => {
  return {
    "id": `${id}-tab-${index}`,
    "aria-controls": `${id}-tabpanel-${index}`,
  };
};

export default TabPanel;
