import React from "react";
import MUICard from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import clsx from "clsx";

const Card: React.FC<React.ComponentProps<typeof MUICard>> = ({ ...props }) => {
  return <MUICard {...props}>{props.children}</MUICard>;
};

export default Card;

interface VerticalCardSectionProps {
  className?: string;
  last?: boolean;
}

export const VerticalCardSection: React.FC<VerticalCardSectionProps> = ({
  className,
  last = false,
  ...props
}) => {
  return (
    <Grid
      item
      className={clsx(
        "card__section",
        { "card__section--last": last },
        className
      )}
    >
      <Box px={3} py={1}>
        {props.children}
      </Box>
    </Grid>
  );
};
