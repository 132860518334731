import { Medication } from "@neurosolutionsgroup/models";
import { SetStateAction, useState } from "react";
import { createGenericContext } from "../GenericContext";

interface MedicationContextData {
  medications: Medication[];
  setMedications: React.Dispatch<SetStateAction<Medication[]>>;
}

const [useMedicationContext, MedicationContextProvider] =
  createGenericContext<MedicationContextData>();

const MedicationProvider: React.FC = (props) => {
  const [medications, setMedications] = useState<Medication[]>([]);

  return (
    <MedicationContextProvider value={{ medications, setMedications }}>
      {props.children}
    </MedicationContextProvider>
  );
};

export { useMedicationContext, MedicationProvider };
