import { ArrowIcon } from "commons/components/Icons";
import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { TextInput } from "..";
import SearchIcon from "commons/components/Icons/SearchIcon";
import { useTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import { Box } from "@material-ui/core";

export type SelectOption = {
  label: string | JSX.Element;
  value: string;
  name: string;
  index?: number;
};

interface FullScreenSelectProps {
  options: SelectOption[];
  value: string | null;
  onChange: (option: SelectOption) => void;
  placeholder?: string;
  selectTitle?: string;
  isSearchable?: boolean;
  searchPlaceholder?: string;
  id?: string;
  className?: string;
  drawerClass?: string;
}

/**
 * A component that displays a dropdown input, but open in full screen when selected.
 * The menu is also searchable.
 * Component uses Material-UI drawer for the full screen dropdown menu.
 */
const FullScreenSelect: React.FC<FullScreenSelectProps> = ({
  options,
  value,
  placeholder,
  selectTitle,
  isSearchable = false,
  searchPlaceholder,
  id,
  onChange,
  className,
  drawerClass,
  ...props
}) => {
  const ROOT_CLASS = "select-fullscreen";

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");

  const { t } = useTranslation();

  // Update label on options or value change.
  const getValueLabel = useCallback((): string | JSX.Element | null => {
    if (value) {
      let option = options.find((o) => value === o.value);

      return option?.name ?? null;
    }
    return null;
  }, [options, value]);

  const onOptionClick = (option: SelectOption) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div
      className={clsx(ROOT_CLASS + "__input", className)}
      onClick={() => {
        setIsOpen(true);
      }}
      id={id}
    >
      <span className={ROOT_CLASS + "__input-value"} {...props}>
        {getValueLabel() ?? placeholder}
      </span>
      <ArrowIcon />
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        classes={{
          paper: drawerClass,
          paperAnchorBottom: ROOT_CLASS + "__menu",
        }}
      >
        <div className={ROOT_CLASS + "__menu-header"}>
          <button
            className={ROOT_CLASS + "__menu-header-cancel"}
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            {t("generic.cancel")}
          </button>
          {isSearchable ? (
            <TextInput
              icon={<SearchIcon />}
              value={search}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
              placeholder={searchPlaceholder}
              resetable={true}
              resetFunction={() => {
                setSearch("");
              }}
            />
          ) : null}
          {selectTitle ? (
            <Box marginLeft={"auto"}>
              <h4>{selectTitle}</h4>
            </Box>
          ) : null}
        </div>
        <div className={ROOT_CLASS + "__menu-values"}>
          {options
            .filter((o) =>
              o.name.toLowerCase().startsWith(search.toLowerCase())
            )
            .map((o, i) => (
              <div
                key={i}
                onClick={(e) => {
                  e.stopPropagation();
                  onOptionClick(o);
                }}
                className={clsx(ROOT_CLASS + "__menu-value-wrapper", {
                  [ROOT_CLASS + "__menu-value-wrapper--selected"]:
                    o.value === value,
                })}
              >
                <div className={ROOT_CLASS + "__menu-value"}>{o.label}</div>
              </div>
            ))}
        </div>
      </Drawer>
    </div>
  );
};

export default FullScreenSelect;
