import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { Dose } from "@neurosolutionsgroup/models";
import { AddIcon } from "commons/components/Icons";
import { Button, Checkbox } from "commons/components/Inputs";
import useMedication from "commons/hooks/medication/useMedication";
import React, { SetStateAction, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DoseComponent from "./DoseComponent";

interface DoseFormProps {
  doses: Dose[];
  setDoses: React.Dispatch<SetStateAction<Dose[]>>;
  takesOtherMedication: boolean;
  setTakesOtherMedication: React.Dispatch<SetStateAction<boolean>>;
  prescriptionId: string;
  medicationId: string | null;
}

const DoseForm: React.FC<DoseFormProps> = ({
  doses,
  setDoses,
  medicationId,
  takesOtherMedication,
  setTakesOtherMedication,
  prescriptionId,
}) => {
  const ROOT_CLASS = "dose-form";

  const { t } = useTranslation();
  const { medications } = useMedication();

  // Get medication details from medication id.
  const getMedication = useCallback(() => {
    if (medicationId && medications.length > 0) {
      return medications.find((m) => m.id === medicationId);
    } else {
      return undefined;
    }
  }, [medicationId, medications]);

  const addDose = useCallback(() => {
    setDoses((current) => {
      const clone = [...current];

      clone.push({
        quantity: 0,
        time: -1,
      });

      return clone;
    });
  }, [setDoses]);

  // Always keep at least one dose.
  useEffect(() => {
    if (getMedication() && doses.length === 0) {
      addDose();
    }
  }, [addDose, doses, getMedication]);

  return (
    <Grid container>
      <Grid item xs={12}>
        {doses.map((d, i) => {
          const medication = getMedication();
          return (
            <DoseComponent
              dose={d}
              updateTime={(time: number) => {
                setDoses((current) => {
                  let clone = [...current];

                  clone[i] = { ...clone[i], time };

                  return clone;
                });
              }}
              updateDoseValue={(dose: number) => {
                setDoses((current) => {
                  let clone = [...current];

                  clone[i] = { ...clone[i], quantity: dose };

                  return clone;
                });
              }}
              onDelete={() => {
                setDoses((current) => {
                  let clone = [...current];

                  clone = clone.filter(
                    (dose) =>
                      dose.quantity !== d.quantity || dose.time !== d.time
                  );

                  return clone;
                });
              }}
              index={i}
              key={d.time}
              medication={medication}
            />
          );
        })}
      </Grid>
      {doses.length < 3 ? (
        <Grid item xs={12}>
          <Button className={ROOT_CLASS + "__add-dose"} onClick={addDose}>
            <AddIcon /> {t("medication.dose.add.action")}
          </Button>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box mx={1} display="flex" justifyContent="stretch">
          <FormControlLabel
            control={
              <Checkbox
                value={takesOtherMedication}
                onChange={(_, v) => setTakesOtherMedication(v)}
              />
            }
            label={t("medication.dose.otherMedication.label")}
            labelPlacement="start"
            className={ROOT_CLASS + "__other-medication"}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DoseForm;
