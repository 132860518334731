import Grid from "@material-ui/core/Grid";
import React, { SetStateAction } from "react";
import { Button } from "../..";

interface AMPMSelectorProps {
  isAM: boolean;
  setIsAM: React.Dispatch<SetStateAction<boolean>>;
}

const AMPMSelector: React.FC<AMPMSelectorProps> = ({ isAM, setIsAM }) => {
  const ROOT_CLASS = "ampm-selector";

  return (
    <Grid container justifyContent="center" spacing={4} className={ROOT_CLASS}>
      <Grid item xs={5}>
        <Button
          variant="contained"
          color={isAM ? "primary" : "default"}
          onClick={() => setIsAM(true)}
          className={ROOT_CLASS + "__selector"}
        >
          AM
        </Button>
      </Grid>
      <Grid item xs={5}>
        <Button
          variant="contained"
          color={!isAM ? "primary" : "default"}
          onClick={() => setIsAM(false)}
          className={ROOT_CLASS + "__selector"}
        >
          PM
        </Button>
      </Grid>
    </Grid>
  );
};

export default AMPMSelector;
