import { Box, Button, Card, Drawer } from "@material-ui/core";
import clsx from "clsx";
import { FullScreenIcon, InformationIcon } from "commons/components/Icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Datum } from "./data";
import FrequencyGraph from "./FrequencyGraph";
import IntensityGraph from "./IntensityGraph";

interface GraphCardProps {
  title: string;
  medication: string | JSX.Element;
  graphType: "Intensity" | "Frequency";
  data: Datum[];
  rootClass: string;
  className?: string;
}

const GraphCard: React.FC<GraphCardProps> = ({
  rootClass,
  className,
  title,
  graphType,
  data,
  medication,
}) => {
  const ROOT_CLASS = "graph-card";

  const { t } = useTranslation();

  const [fullscreen, setFullscreen] = useState(false);
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    setHasData(data.some((d) => d.valueY));
  }, [data]);

  return (
    <Card
      classes={{
        root: clsx(
          rootClass,
          rootClass + "--graph",
          ROOT_CLASS,
          { [ROOT_CLASS + "--no-data"]: !hasData },
          className
        ),
      }}
    >
      <div className={ROOT_CLASS + "__axis"} />
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          className={ROOT_CLASS + "__header"}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            color="secondary"
            variant="contained"
            classes={{ root: ROOT_CLASS + "__title" }}
            onClick={() => setOpenInfoDrawer(true)}
          >
            <InformationIcon />
            <span>{title}</span>
          </Button>

          <div>
            <Button
              classes={{ root: ROOT_CLASS + "__fullscreen-toggle" }}
              onClick={() => setFullscreen(true)}
            >
              <FullScreenIcon />
            </Button>
          </div>
        </Box>
        <Box flexGrow={1} className={ROOT_CLASS + "__graph-container"}>
          {!hasData ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <p>
                {graphType === "Frequency"
                  ? t("dashboard.graph.nodata.frequency")
                  : t("dashboard.graph.nodata.intensity")}
              </p>
            </Box>
          ) : graphType === "Frequency" ? (
            <FrequencyGraph data={data} />
          ) : (
            <IntensityGraph data={data} />
          )}
        </Box>
      </Box>

      <Drawer
        anchor="bottom"
        classes={{ paper: ROOT_CLASS + "__fullscreen" }}
        open={fullscreen}
        onClose={() => setFullscreen(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={ROOT_CLASS + "__fullscreen-content"}
        >
          <div className={ROOT_CLASS + "__fullscreen-axis"} />
          <Box display="flex" justifyContent="space-between" p={1}>
            <div className={ROOT_CLASS + "__title"}>
              <InformationIcon />
              <span>{title}</span>
            </div>
            <Button
              onClick={() => setFullscreen(false)}
              classes={{ root: ROOT_CLASS + "__fullscreen-toggle" }}
            >
              <FullScreenIcon reverse />
            </Button>
          </Box>
          <Box px={1} py={0.5} className={ROOT_CLASS + "__medication-label"}>
            {medication}
          </Box>
          <Box flexGrow={1}>
            {graphType === "Frequency" ? (
              <FrequencyGraph data={data} />
            ) : (
              <IntensityGraph data={data} />
            )}
          </Box>
        </Box>
      </Drawer>
      <Drawer
        anchor="bottom"
        open={openInfoDrawer}
        onClose={() => setOpenInfoDrawer(false)}
        classes={{
          paper: ROOT_CLASS + "__info-drawer",
        }}
      >
        <Box p={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            my={1}
          >
            <InformationIcon />
            &nbsp;<h5>{title}</h5>
          </Box>

          <span>{t("dashboard.graph.info")}</span>
        </Box>
      </Drawer>
    </Card>
  );
};

export default GraphCard;
