import React from "react";

interface AvatarProps {
  name: string;
}

const Avatar: React.FC<AvatarProps> = ({ name }) => {
  const ROOT_CLASS = "avatar";

  return (
    <div className={ROOT_CLASS}>
      <div className={ROOT_CLASS + "__image"}></div>

      <div className={ROOT_CLASS + "__name"}>{name}</div>
    </div>
  );
};

export default Avatar;
