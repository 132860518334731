import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const DashboardIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="dashboard">
      <path
        id="path832"
        fill="#000000"
        d="m 11.111111,7.7777777 c -0.740741,0 -1.4814814,0 -2.2222222,0 0,0.7407408 0,1.4814814 0,2.2222222 0.7407408,0 1.4814812,0 2.2222222,0 0,-0.7407408 0,-1.4814814 0,-2.2222222 z m 0,4.4444443 c -0.740741,0 -1.4814814,0 -2.2222222,0 0,1.111111 0,2.222222 0,3.333333 0.7407408,0 1.4814812,0 2.2222222,0 0,-1.111111 0,-2.222222 0,-3.333333 z m 4.444444,-7.7777776 c -0.740741,0 -1.481481,0 -2.222222,0 0,3.7037036 0,7.4074076 0,11.1111106 0.740741,0 1.481481,0 2.222222,0 0,-3.703703 0,-7.407407 0,-11.1111106 z M 6.6666666,9.9999999 c -0.7407408,0 -1.4814814,0 -2.2222222,0 0,1.8518521 0,3.7037031 0,5.5555551 0.7407408,0 1.4814814,0 2.2222222,0 0,-1.851852 0,-3.703703 0,-5.5555551 z M 17.777778,0 C 12.517257,0.00764863 7.2557595,-0.01532187 1.9958495,0.01152887 0.71492115,0.10563523 -0.15591939,1.3934384 0,2.618202 0.00764677,7.7467301 -0.01531937,12.876233 0.01152887,18.00415 0.10563523,19.285079 1.3934384,20.155919 2.618202,20 7.7467301,19.992353 12.876233,20.015319 18.00415,19.988471 19.285079,19.894364 20.155919,18.606561 20,17.381798 19.992353,12.25327 20.015319,7.1237663 19.988471,1.9958495 19.892479,0.89070949 18.887946,-0.01852708 17.777778,0 Z m 0,17.777778 c -5.185186,0 -10.3703706,0 -15.5555558,0 0,-5.185186 0,-10.3703706 0,-15.5555558 5.1851852,0 10.3703698,0 15.5555558,0 0,5.1851852 0,10.3703698 0,15.5555558 z"
      />
    </BaseIcon>
  );
};

export default DashboardIcon;
