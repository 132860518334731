import React from "react";
import { IconProps } from ".";
import clsx from "clsx";

interface BaseIconProps {
  classModifier: string;
  stroke?: string;
}

const BaseIcon: React.FC<IconProps & BaseIconProps> = ({
  onClick,
  classModifier,
  stroke,
  ...props
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={clsx("icon", "icon-" + classModifier, {
        "icon--clickable": onClick,
      })}
    >
      {props.children}
    </svg>
  );
};

export default BaseIcon;
