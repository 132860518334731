import { Box, Grid } from "@material-ui/core";
import { ArrowIcon } from "commons/components/Icons";
import { ArrowType } from "commons/components/Icons/ArrowIcon";
import CheckMarkIcon from "commons/components/Icons/CheckMarkIcon";

interface SideEffectCardProps {
  sideEffect: string;
  onClick: () => void;
  questionsCompleted: boolean;
}

const SideEffectsCard = ({
  sideEffect,
  onClick,
  questionsCompleted,
}: SideEffectCardProps) => {
  const completedBadge = () => {
    return (
      <Grid
        className="completed-badge__container"
        container
        alignItems="flex-end"
      >
        <Box mr={3} className="completed-badge">
          <Grid
            className="completed-badge__icon-container"
            container
            justifyContent="center"
            alignItems="center"
          >
            <CheckMarkIcon />
          </Grid>
        </Box>
      </Grid>
    );
  };

  return (
    <Box
      className="side-effect-card__container"
      m={1}
      borderRadius={10}
      onClick={() => {
        if (!questionsCompleted) {
          onClick();
        }
      }}
    >
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Box m={3}> {sideEffect}</Box>
        </Grid>
        <Grid item>
          {questionsCompleted ? (
            completedBadge()
          ) : (
            <Box mt={3} mr={3}>
              <ArrowIcon arrowType={ArrowType.empty} />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SideEffectsCard;
