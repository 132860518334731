import clsx from "clsx";
import { Page } from "commons/components";
import React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

interface CloseablePageProps {
  isOpen: boolean;
  onClose: VoidFunction;
  title: string;
  closeElement?: JSX.Element;
  rightHeaderElement?: JSX.Element;
  customHeader?: JSX.Element;
  hasFooterButtons?: boolean;
}

const portalRoot = document.getElementById("closeable-page-portal");

const CloseablePage: React.FC<
  CloseablePageProps & React.HTMLProps<HTMLDivElement>
> = ({
  isOpen,
  onClose,
  title,
  closeElement,
  rightHeaderElement,
  customHeader,
  hasFooterButtons = false,
  ...props
}) => {
  const { t } = useTranslation();

  const pageHeader = (): JSX.Element => {
    return customHeader ? (
      customHeader
    ) : (
      <>
        <div
          className="page-closeable__close page-closeable__close--left"
          onClick={onClose}
        >
          {closeElement ?? <div>{t("generic.cancel")}</div>}
        </div>
        <h3 className="page-closeable__title">{title}</h3>
        <div className="page-closeable__close page-closeable__close--right">
          {rightHeaderElement ?? null}
        </div>
      </>
    );
  };

  return isOpen && portalRoot
    ? createPortal(
        <Page
          header={pageHeader()}
          {...props}
          className={clsx(props.className, "page-closeable", {
            "page-closeable--footer-buttons": hasFooterButtons,
          })}
        >
          {props.children}
        </Page>,
        portalRoot
      )
    : null;
};

export default CloseablePage;
