import { SideEffect } from "@neurosolutionsgroup/models";
import { useSideEffectsContext } from "./SideEffectsContext";

interface UseSideEffectsResult {
  sideEffects: SideEffect[];
  getSideEffectFromId: (id: string) => SideEffect | undefined;
}

const useSideEffects = (): UseSideEffectsResult => {
  const { sideEffects } = useSideEffectsContext();

  const getSideEffectFromId = (id: string): SideEffect | undefined => {
    return sideEffects.find((se) => se.id === id);
  };

  return {
    sideEffects,
    getSideEffectFromId,
  };
};

export default useSideEffects;
