const resources = {
  en: {
    translation: {
      journal: {
        observation: "{{count}} observation",
        observation_plural: "{{count}} observations",
        emptyState: {
          noSideEffects: {
            1: "No side effect to follow for now.",
            2: "Go to the Follow-up tab to complete the required information.",
          },
          noPrescription: {
            1: "No active prescription for now.",
            2: "Go to the Follow-up tab to complete requiered information.",
          },
          completed: {
            1: "Observations have been completed for this week.",
            2: "You will be able to fill in the observations next Monday from 8:00 am.",
          },
        },
        note: {
          date: {
            label: "Date of the note",
          },
          add: {
            label: "Add a note",
            inputs: {
              sideEffect: {
                placeholder: "Select a side effect",
                searchPlaceholder: "Search a side effect",
              },
              time: {
                label: "Time of the observation",
              },
              title: {
                placeholder: "Enter a title",
              },
              text: {
                placeholder: "Enter a description",
              },
            },
            cancel: "The note will be deleted.",
          },
          edit: {
            label: "Added note",
            delete: {
              title: "Delete note",
              text: "The note will be deleted.",
            },
            cancel: {
              title: "Cancel modifications",
              text: "Modification will not be saved.",
            },
          },
          list: {
            title: "Added notes",
            empty: "No note added yet",
          },
        },
        question: {
          intensity: {
            low: "Low",
            moderate: "Moderate",
            high: "High",
          },
        },
      },
    },
  },
  fr: {
    translation: {
      journal: {
        observation: "{{count}} observation",
        observation_plural: "{{count}} observations",
        emptyState: {
          noSideEffects: {
            1: "Aucun effet secondaire à suivre pour le moment.",
            2: "Rendez-vous dans l'onglet Suivi pour compléter les renseignements requis.",
          },
          noPrescription: {
            1: "Aucune prescription active pour le moment.",
            2: "Rendez-vous dans l'onglet Suivi pour compléter les renseignements requis.",
          },
          completed: {
            1: "Les observations ont été complétées pour cette semaine. ",
            2: "Vous pourrez remplir les observations dès Lundi prochain 8h00.",
          },
        },
        note: {
          date: {
            label: "Date de la note",
          },
          add: {
            label: "Ajouter une note",
            inputs: {
              sideEffect: {
                placeholder: "Sélectionner un effet secondaire",
                searchPlaceholder: "Rechercher un effet secondaire",
              },
              time: {
                label: "Moment de l'observation",
              },
              title: {
                placeholder: "Entrer un titre",
              },
              text: {
                placeholder: "Entrer une description",
              },
            },
            cancel: "La note sera supprimée.",
          },
          edit: {
            label: "Note ajoutée",
            delete: {
              title: "Supprimer la note",
              text: "La note sera supprimé.",
            },
            cancel: {
              title: "Annuler les modifications",
              text: "Les modifications ne seront pas enregistrées.",
            },
          },
          list: {
            title: "Notes ajoutées",
            empty: "Aucune note ajoutée pour le moment",
          },
        },
        question: {
          intensity: {
            low: "Faible",
            moderate: "Modéré",
            high: "Élevé",
          },
        },
      },
    },
  },
};

export default resources;
