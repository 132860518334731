import { useAuth } from "@neurosolutionsgroup/authentication";
import MedicalService from "commons/services/Medical";
import { useChildrenContext } from "./ChildrenContext";
import { v4 } from "uuid";
import { useAppDataContext } from "../AppDataContext";
import { GamerChild, MedicalChild } from "@neurosolutionsgroup/models";

interface UseChildrenResult {
  children: MedicalChild[];
  gamerChildren: GamerChild[];
  selectedChildId: string | undefined;
  updateMedicalChildren: (value: string[]) => Promise<void>;
  setSelectedChildId: (value: string) => void;
  getChildFromId: (id: string) => MedicalChild | undefined;
  createDefaultChild: () => Promise<void>;
  loading: boolean;
}

const useChildren = (): UseChildrenResult => {
  const {
    children,
    setChildren,
    gamerChildren,
    selectedChildId,
    setSelectedChildId,
  } = useChildrenContext();
  const {
    actions: { getIdToken },
  } = useAuth();
  const { loading, setLoading } = useAppDataContext();

  const getChildFromId = (id: string): MedicalChild | undefined => {
    return children.find((c) => c.medicalChildId === id);
  };

  const createDefaultChild = async (): Promise<void> => {
    setLoading(true);

    const idToken = await getIdToken();

    if (idToken) {
      return MedicalService.ChildService.createChild(idToken, {
        medicalChildId: v4(),
        name: "Test Child",
      })
        .then((result) => {
          setChildren([result]);
        })
        .catch((error) => {
          return Promise.reject({
            code: 500,
            message: "Server error",
            context: error,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      return Promise.reject({
        code: 401,
        message: "Not authenticated",
      });
    }
  };

  const updateMedicalChildren = async (
    followedChildrenIds: string[]
  ): Promise<void> => {
    setLoading(true);
    return Promise.all(
      gamerChildren.map((gc) => {
        let isInFollowed = followedChildrenIds.find((id) => id === gc.id);
        let isInMedical = children.find(
          (mc) => mc.gamerChildId === gc.id
        )?.medicalChildId;
        if (isInMedical && !isInFollowed) {
          return deleteMedicalChild(isInMedical);
        } else if (!isInMedical && isInFollowed) {
          return createMedicalChild(isInFollowed);
        }
        return Promise.resolve();
      })
    )
      .then(() => {
        return Promise.resolve();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteMedicalChild = async (medicalChildId: string): Promise<void> => {
    const medicalChild = children.find(
      (mc) => mc.medicalChildId === medicalChildId
    );
    const idToken = await getIdToken();
    if (idToken && medicalChild) {
      return MedicalService.ChildService.deleteChild(idToken, medicalChildId)
        .then(() => {
          setChildren((current) => {
            const clone = [...current];
            const i = clone.indexOf(medicalChild);
            if (i !== -1) {
              clone.splice(i, 1);
            }
            return clone;
          });
        })
        .catch((error) => {
          return Promise.reject({
            code: 500,
            message: "Server error",
            context: error,
          });
        });
    } else {
      return Promise.reject({
        code: 401,
        message: "Not authenticated",
      });
    }
  };

  const createMedicalChild = async (gamerChildId: string): Promise<void> => {
    const gamerChild = gamerChildren.find((gc) => gc.id === gamerChildId);
    const idToken = await getIdToken();
    if (idToken && gamerChild) {
      return MedicalService.ChildService.createChild(idToken, {
        medicalChildId: v4(),
        gamerChildId: gamerChild.id,
        name: gamerChild.name,
      })
        .then((result) => {
          setChildren((prev) => [...prev, result]);
        })
        .catch((error) => {
          return Promise.reject({
            code: 500,
            message: "Server error",
            context: error,
          });
        });
    } else {
      return Promise.reject({
        code: 401,
        message: "Not authenticated",
      });
    }
  };

  return {
    children,
    gamerChildren,
    selectedChildId,
    updateMedicalChildren,
    setSelectedChildId,
    getChildFromId,
    createDefaultChild,
    loading,
  };
};

export default useChildren;
