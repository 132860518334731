import React from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Datum } from "./data";

interface IntensityGraphProps {
  data: Datum[];
}

const IntensityGraph: React.FC<IntensityGraphProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
        margin={{
          top: 15,
          right: 30,
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="2" />
        <XAxis
          dataKey="valueX"
          domain={["dataMin", "dataMax"]}
          axisLine={false}
          tickLine={false}
          tickFormatter={(v, i) => {
            return `${t("dashboard.graph.week")} ${v}`;
          }}
          tick={{ fontSize: 12, fill: "white" }}
          height={25}
        />
        <YAxis
          ticks={["1", "2", "3"]}
          domain={[0.75, 3]}
          axisLine={false}
          tickLine={false}
          tickMargin={0}
          tick={{ fontSize: 12, fill: "black" }}
          width={60}
          tickFormatter={(v) => {
            switch (v) {
              case "1":
                return t("dashboard.summary.categories.low");
              case "2":
                return t("dashboard.summary.categories.moderate");
              case "3":
                return t("dashboard.summary.categories.high");
              default:
                return "N/A";
            }
          }}
        />
        <Line
          type="linear"
          dataKey="valueY"
          stroke="#c4c4c4"
          strokeWidth={2}
          dot={{ stroke: "#000000", fill: "#000000", strokeWidth: 1, r: 3 }}
          animationDuration={500}
          connectNulls
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default IntensityGraph;
