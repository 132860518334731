import React, { useRef } from "react";
import clsx from "clsx";
import { CloseIcon } from "commons/components/Icons";
import MuiTextInput from "@material-ui/core/TextField";

interface TextInputProps {
  icon?: JSX.Element;
  resetable?: boolean;
  resetFunction?: VoidFunction;
}

/**
 * A text input with default styling and custom icons. Input is also resetable.
 */
const TextInput: React.FC<
  TextInputProps & React.ComponentProps<typeof MuiTextInput>
> = ({ icon, resetable, resetFunction, ...props }) => {
  const ROOT_CLASS = "text-input";

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <MuiTextInput
      {...props}
      className={clsx(ROOT_CLASS, props.className)}
      type="text"
      ref={inputRef}
      variant="standard"
      fullWidth={true}
      InputProps={{
        startAdornment: icon ?? null,
        endAdornment:
          resetable &&
          resetFunction &&
          props.value &&
          (props.value as string)?.toString().length > 0 ? (
            <CloseIcon onClick={resetFunction} />
          ) : null,
        disableUnderline: true,
      }}
    />
  );
};

export default TextInput;
