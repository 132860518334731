import { Datum } from "./data";

export const generateIntensityData = (
  weeks: number,
  missedWeeks: number[] = []
): Datum[] => {
  let data: Datum[] = [];

  for (let i = 0; i < weeks; i++) {
    data.push({
      valueX: i + 1,
      valueY: !missedWeeks.includes(i)
        ? Math.floor(Math.random() * 3) + 1
        : undefined,
    });
  }

  return data;
};

export const generateFrequencyData = (
  weeks: number,
  missedWeeks: number[] = []
): Datum[] => {
  let data: Datum[] = [];

  for (let i = 0; i < weeks; i++) {
    data.push({
      valueX: i + 1,
      valueY: !missedWeeks.includes(i + 1)
        ? Math.floor(Math.random() * 4)
        : undefined,
    });
  }

  return data;
};
