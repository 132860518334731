import { Box, Grid } from "@material-ui/core";
import { OptionMenuButton } from "..";
import ChildSelectorButton from "../ChildSelector/ChildSelectorButton";

interface PageHeaderProps {
  leftElement?: JSX.Element;
  centralElement?: JSX.Element;
  rightElement?: JSX.Element;
  onClickLeft?: () => void;
  onClickRight?: () => void;
}

const PageHeader: React.FC<
  PageHeaderProps & React.HTMLProps<HTMLDivElement>
> = ({
  leftElement,
  centralElement,
  rightElement,
  onClickLeft,
  onClickRight,
  ...props
}) => {
  const ROOT_CLASS = "page-header";
  const leftDefaultElement: React.FC = () => {
    return <ChildSelectorButton />;
  };
  const centralDefaultElement: React.FC = () => {
    return <Box></Box>;
  };
  const rightDefaultElement: React.FC = () => {
    return <OptionMenuButton />;
  };

  return (
    <Box className={props.className ? props.className : ROOT_CLASS}>
      <Grid container justifyContent="space-between">
        <Box>{leftElement ? leftElement : leftDefaultElement}</Box>
        <Box>{centralElement ? centralElement : centralDefaultElement}</Box>
        <Box>{rightElement ? rightElement : rightDefaultElement}</Box>
      </Grid>
    </Box>
  );
};
export default PageHeader;
