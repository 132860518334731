import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { MenuIcon } from "commons/components/Icons";
import {
  DatePicker as MUIDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment, { Moment, utc } from "moment";
import "moment/locale/fr";
import "moment/locale/en-ca";
import { Button } from "commons/components/Inputs";
import Grid from "@material-ui/core/Grid";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import MomentUTCUtils from "commons/helpers/MomentUTCUtils";
import DateOptions from "./DateOptions";

interface DatePickerProps {
  date: Date;
  setDate: React.Dispatch<SetStateAction<Date>>;
  pastDays?: number;
  label?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  date,
  setDate,
  pastDays = 4,
  label,
}) => {
  const ROOT_CLASS = "date-picker";

  const { t, i18n } = useTranslation();

  // The "first" (latest) date to be shown in the carousel.
  const [rootDate, setRootDate] = useState(utc().startOf("day"));
  const [showDateDrawer, setShowDateDrawer] = useState(false);
  // The date selected in the date picker calendar.
  const [datePickerDate, setDatePickerDate] = useState(utc().startOf("day"));

  useEffect(() => {
    if (date) {
      setRootDate(moment(date).utc().startOf("day"));
      setDate(moment(date).utc().startOf("day").toDate());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update calendar date when date changed.
  useEffect(() => {
    setDatePickerDate(moment(date));
  }, [date]);

  const setDateFromUTC = useCallback(
    (value: Moment): void => {
      setDate(new Date(value.unix() * 1000));
    },
    [setDate]
  );

  // Custom render for calendar days in order to highlight previous days.
  const renderDay = (
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    const dayDiff = selectedDate?.diff(date, "days");

    const isSameDay = selectedDate?.isSame(date, "day");

    const wrapperClassName = clsx("mui-date-picker__day", {
      "mui-date-picker__day--highlight":
        (dayDiff && [1, 2, 3].includes(dayDiff)) || isSameDay,
      "mui-date-picker__day--highlight-start": dayDiff && dayDiff === 3,
      "mui-date-picker__day--highlight-middle":
        dayDiff && (dayDiff === 2 || dayDiff === 1),
      "mui-date-picker__day--highlight-end": isSameDay,
    });

    return <div className={wrapperClassName}>{dayComponent}</div>;
  };

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUTCUtils}
      locale={i18n.language}
    >
      <div className={ROOT_CLASS}>
        <label className={ROOT_CLASS + "__label"}>
          {label && <span>{label}</span>}
          <MenuIcon
            onClick={() => {
              setShowDateDrawer(true);
            }}
          />
        </label>

        <Drawer
          anchor="bottom"
          open={showDateDrawer}
          onClose={() => setShowDateDrawer(false)}
        >
          <Box p={2}>
            <Grid container justifyContent="center">
              <Grid item sm={12}>
                <MUIDatePicker
                  variant="static"
                  disableToolbar={true}
                  openTo="date"
                  disableFuture={true}
                  value={datePickerDate}
                  onChange={(date) => {
                    if (date) {
                      setDatePickerDate(date);
                    }
                  }}
                  renderDay={renderDay}
                />
              </Grid>
              <Grid item sm={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation={true}
                  onClick={() => {
                    setShowDateDrawer(false);
                    setDateFromUTC(datePickerDate);
                    setRootDate(datePickerDate);
                  }}
                >
                  {t("generic.confirm")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
        <DateOptions
          className={ROOT_CLASS + "__date-container"}
          rootDate={rootDate}
          setDate={setDateFromUTC}
          pastDays={pastDays}
          date={date}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
