import React, { useEffect, useState } from "react";
import { Avatar, Loader, Page } from "commons/components";
import CreateFollowUpForm from "./FollowUp/CreateFollowUpForm";
import useChildren from "commons/hooks/children/useChildren";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import Box from "@material-ui/core/Box";
import { Button } from "commons/components/Inputs";
import { ApiError } from "commons/models/ApiError";
import {
  ErrorDialog,
  YesNoDialog,
} from "commons/components/Layout/Components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { Card, PageHeader } from "commons/components/Layout/Components";
import { Presentation } from "./FollowUp/Components";
import { AddButton } from "commons/components/Inputs/Button";
import { ArchiveIcon, EditIcon } from "commons/components/Icons";
import MenuDrawer from "commons/components/Layout/Components/MenuDrawer/MenuDrawer";
import useAlerts from "commons/hooks/alerts/useAlerts";
import ModifySideEffectsForm from "./FollowUp/ModifySideEffectsForm";
import ModifyPrescriptionForm from "./FollowUp/ModifyPrescriptionForm";
import { Typography } from "@material-ui/core";
import { MedicalChild } from "@neurosolutionsgroup/models";
import { FrontEndFollowUp } from "commons/models/Medical";
import TimeHelpers from "commons/helpers/Time";

const FollowUpPage: React.FC = () => {
  const { createDefaultChild, getChildFromId, selectedChildId } = useChildren();
  const {
    helpers: {
      getFollowUpsByChild,
      getActiveFollowUpForChild,
      getPreviousSideEffectsForChild,
    },
    requests: { terminateFollowUp },
    loading,
  } = useFollowUps();
  const { t } = useTranslation();
  const { addSuccessAlert } = useAlerts();

  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [openPrescriptionDrawer, setOpenPrescriptionDrawer] = useState(false);
  const [openSideEffectDrawer, setOpenSideEffectDrawer] = useState(false);
  const [showTerminateDialog, setShowTerminateDialog] = useState(false);
  const [showFollowUpForm, setShowFollowUpForm] = useState(false);
  const [showModifySideEffectsForm, setShowModifySideEffectsForm] =
    useState(false);
  const [showModifyPrescriptionForm, setShowModifyPrescriptionForm] =
    useState(false);
  const [child, setChild] = useState<MedicalChild | undefined>(undefined);

  const [selectedFollowUp, setSelectedFollowUp] =
    useState<FrontEndFollowUp | null>(null);

  useEffect(() => {
    if (selectedChildId) {
      setChild(getChildFromId(selectedChildId));
    }
  }, [getChildFromId, selectedChildId]);

  useEffect(() => {
    if (child) {
      setSelectedFollowUp(getActiveFollowUpForChild(child.medicalChildId));
    }
  }, [getActiveFollowUpForChild, child]);

  const pageHeader = (): JSX.Element => {
    return <PageHeader />;
  };

  const onAddFollowUp = () => {
    setShowFollowUpForm(true);
  };

  const onAddTestChild = async (): Promise<void> => {
    return createDefaultChild().catch((error: ApiError) => {
      setApiError(error);
    });
  };

  const onTerminatePrescription = async (): Promise<void> => {
    setShowTerminateDialog(false);
    const activeFollowUp = getActiveFollowUpForChild(child?.medicalChildId);
    if (activeFollowUp)
      terminateFollowUp(activeFollowUp.followUpId)
        .then(() => {
          setShowFollowUpForm(true);
          addSuccessAlert(
            t("medication.actions.terminate.success"),
            true,
            true
          );
        })
        .catch((error: ApiError) => {
          setApiError(error);
        });
  };

  return (
    <Page header={pageHeader()} className="follow-up-page">
      <Box p={1}>
        <h3>{t("navigation.pages.followUp.title")}</h3>
      </Box>
      {child ? (
        <>
          {child ? (
            <>
              <Box p={1}>
                <Grid container justifyContent="center">
                  <Grid item>
                    <Avatar name={child.name} />
                  </Grid>
                </Grid>
              </Box>
              <FollowUpDisplay
                followUp={getActiveFollowUpForChild(child?.medicalChildId)}
                firstFollowUp={
                  getFollowUpsByChild(child?.medicalChildId).length === 0
                }
                childId={child?.medicalChildId}
                previousSideEffects={getPreviousSideEffectsForChild(
                  child?.medicalChildId
                )}
                onEditPrescription={() => setOpenPrescriptionDrawer(true)}
                onAddSideEffects={() => setShowModifySideEffectsForm(true)}
                onEditSideEffects={() => setOpenSideEffectDrawer(true)}
                onAddFollowUp={onAddFollowUp}
              />

              <CreateFollowUpForm
                open={showFollowUpForm}
                onClose={() => setShowFollowUpForm(false)}
                medicalChildId={child.medicalChildId}
                previousSideEffects={getPreviousSideEffectsForChild(
                  child?.medicalChildId
                )}
              />

              {selectedFollowUp ? (
                <>
                  <ModifySideEffectsForm
                    followUp={selectedFollowUp}
                    open={showModifySideEffectsForm}
                    onClose={() => setShowModifySideEffectsForm(false)}
                  />
                  <ModifyPrescriptionForm
                    open={showModifyPrescriptionForm}
                    onClose={() => setShowModifyPrescriptionForm(false)}
                    initialFollowUp={selectedFollowUp}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </>
      ) : (
        <>
          <p>No Children!</p>
          <Button
            variant="contained"
            color="secondary"
            onClick={onAddTestChild}
          >
            Create test child
          </Button>
        </>
      )}
      <ErrorDialog
        title={t("generic.errorDialog.title", {
          code: apiError?.code ?? "500",
        })}
        text={t("generic.errorDialog.text")}
        action={t("generic.tryAgain")}
        open={!!apiError}
        onClick={() => setApiError(undefined)}
      />
      <MenuDrawer
        open={openPrescriptionDrawer}
        onClose={() => setOpenPrescriptionDrawer(false)}
        anchor="bottom"
        menuItems={[
          {
            translationKey: "medication.actions.correct.title",
            icon: <EditIcon />,
            onClick: () => {
              setOpenPrescriptionDrawer(false);
              setShowModifyPrescriptionForm(true);
            },
          },
          {
            translationKey: "medication.actions.terminate.title",
            icon: <ArchiveIcon />,
            onClick: () => {
              setOpenPrescriptionDrawer(false);
              setShowTerminateDialog(true);
            },
          },
        ]}
      />
      <MenuDrawer
        open={openSideEffectDrawer}
        onClose={() => setOpenSideEffectDrawer(false)}
        anchor="bottom"
        menuItems={[
          {
            translationKey: "sideEffects.form.modify.title",
            icon: <EditIcon />,
            onClick: () => {
              setOpenSideEffectDrawer(false);
              setShowModifySideEffectsForm(true);
            },
          },
        ]}
      />
      <YesNoDialog
        title={t("medication.actions.terminate.title")}
        body={
          <>
            <Typography align="center">
              <span>
                <b>{t("generic.cancelDialog.text.one")}</b>
              </span>
              <br />
              <span>
                <b>{t("medication.actions.terminate.confirm")}</b>
              </span>
              <br />
              <span>
                <b>{t("generic.cancelDialog.text.three")}</b>
              </span>
            </Typography>
          </>
        }
        open={showTerminateDialog}
        onYes={() => onTerminatePrescription()}
        onNo={() => setShowTerminateDialog(false)}
      />
      <Loader show={loading} />
    </Page>
  );
};

export default FollowUpPage;

interface FollowUpDisplayProps {
  followUp: FrontEndFollowUp | null;
  firstFollowUp: boolean;
  childId: string;
  previousSideEffects: string[];
  onEditPrescription: VoidFunction;
  onAddSideEffects: VoidFunction;
  onEditSideEffects: VoidFunction;
  onAddFollowUp: VoidFunction;
}

const FollowUpDisplay: React.FC<FollowUpDisplayProps> = ({
  followUp,
  firstFollowUp,
  childId,
  previousSideEffects,
  onEditPrescription,
  onAddSideEffects,
  onEditSideEffects,
  onAddFollowUp,
}) => {
  const { t } = useTranslation();

  return followUp ? (
    <Box m={1}>
      <Presentation.PrescriptionCard
        childId={childId}
        prescription={followUp.prescriptions[0]}
        startDate={TimeHelpers.date.getDateFromApiDateString(
          followUp.startDate
        )}
        takesOtherMedication={followUp.takesOtherMedication}
        onEdit={onEditPrescription}
      />
      <hr />
      <Presentation.SideEffectsCard
        onEdit={onEditSideEffects}
        sideEffects={followUp.followedSideEffectIds}
        onAdd={onAddSideEffects}
      />
    </Box>
  ) : firstFollowUp ? (
    <Box p={1} className="empty-follow-up__wrapper">
      <Card className="empty-follow-up">
        <AddButton
          onClick={onAddFollowUp}
          className="empty-follow-up__button"
        />
        <span className="empty-follow-up__text"> {t("followup.start")}</span>
      </Card>
    </Box>
  ) : (
    <Box m={1}>
      <Presentation.PrescriptionCard childId={childId} onAdd={onAddFollowUp} />
      <hr />
      <Presentation.SideEffectsCard
        disabled
        sideEffects={previousSideEffects}
      />
    </Box>
  );
};
