import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { AddIcon } from "commons/components/Icons";
import { IconButton } from "commons/components/Inputs/Button";
import SimpleDatePicker from "commons/components/Inputs/DatePicker/SimpleDatePicker";
import { Card } from "commons/components/Layout/Components";
import TimeHelpers from "commons/helpers/Time";
import useChildren from "commons/hooks/children/useChildren";
import useNotes from "commons/hooks/notes/useNotes";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import AddNoteForm from "./AddNoteForm";
import EditNoteForm from "./EditNoteForm";
import NotesDisplay from "./NotesDisplay";

interface NotesPageProps {}

const NotesPage: React.FC<NotesPageProps> = () => {
  const ROOT_CLASS = "notes-page";
  const MAX_NOTES_PER_DAY = 10;

  const { t } = useTranslation();

  const [date, setDate] = useState<Date>(new Date());
  const [selectedNoteId, setSelectedNoteId] = useState<string | undefined>(
    undefined
  );

  const [openAddNoteForm, setOpenAddNoteForm] = useState(false);
  const [openEditNoteForm, setOpenEditNoteForm] = useState(false);

  const { selectedChildId } = useChildren();
  const { notes, getNoteById, getNotesByDay } = useNotes();

  const addNoteIsDisabled = useCallback((): boolean => {
    return getNotesByDay(date).length >= MAX_NOTES_PER_DAY;
  }, [date, getNotesByDay]);

  const onNoteClicked = (noteId: string) => {
    setSelectedNoteId(noteId);
    setOpenEditNoteForm(true);
  };

  return selectedChildId ? (
    <Box p={1} className={ROOT_CLASS}>
      <Grid
        container
        direction="column"
        spacing={1}
        className={ROOT_CLASS + "__grid"}
      >
        <Grid item xs={12}>
          <SimpleDatePicker
            date={date}
            setDate={setDate}
            pastDays={30}
            label={t("journal.note.date.label")}
            taggedDates={notes.map((n) =>
              TimeHelpers.date.getDateFromApiDateString(n.eventDate)
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Card className={ROOT_CLASS + "__add-button"}>
            <Box m={1}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {t("journal.note.add.label")}
                <IconButton
                  color="secondary"
                  onClick={() => setOpenAddNoteForm(true)}
                  icon={<AddIcon />}
                  disabled={addNoteIsDisabled()}
                />
              </Grid>
            </Box>
          </Card>
        </Grid>
        <Grid item className={ROOT_CLASS + "__display-container"}>
          <NotesDisplay
            notes={getNotesByDay(date)}
            onNoteClick={onNoteClicked}
          />
        </Grid>
      </Grid>

      <AddNoteForm
        open={openAddNoteForm}
        onClose={() => setOpenAddNoteForm(false)}
        date={date}
        medicalChildId={selectedChildId}
      />
      {selectedNoteId ? (
        <EditNoteForm
          open={openEditNoteForm}
          onClose={() => {
            setSelectedNoteId(undefined);
            setOpenEditNoteForm(false);
          }}
          note={getNoteById(selectedNoteId)}
        />
      ) : null}
    </Box>
  ) : null;
};

export default NotesPage;
