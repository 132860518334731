import {
  CreateFollowUpBody,
  CreateMedicalChildBody,
  FirestoreCollection,
  FollowedSideEffect,
  FollowUp,
  GamerChild,
  MedicalChild,
  Prescription,
  UpdatePrescriptionsBody,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import {
  constructBearerTokenHeader,
  MEDICALCHILD_FUNCTION,
  urlGenerator,
} from ".";
import { handleApiError } from "./error";
import JournalService from "./journal";

const getGamerChildren = async (
  userId: string,
  tenantId: string
): Promise<GamerChild[]> => {
  const db = getFirestore();

  const collectionRef = collection(db, FirestoreCollection.GamerChildren);

  const queryRef = query(
    collectionRef,
    where("userId", "==", userId),
    where("tenantId", "==", tenantId)
  );

  return getDocs(queryRef).then((result) => {
    return result.docs.map((d) => ({ ...d.data(), id: d.id } as GamerChild));
  });
};

const getChildren = async (idToken: string): Promise<MedicalChild[]> => {
  const URL = urlGenerator(MEDICALCHILD_FUNCTION);

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .get(URL, {
      headers: headers,
    })
    .then((response) => {
      return response.data as MedicalChild[];
    });
};

const createChild = async (
  idToken: string,
  child: CreateMedicalChildBody
): Promise<MedicalChild> => {
  const URL = urlGenerator(MEDICALCHILD_FUNCTION);

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .post(URL, child, {
      headers: headers,
    })
    .then((response) => {
      return response.data as MedicalChild;
    });
};

const deleteChild = async (
  idToken: string,
  childId: string
): Promise<boolean> => {
  const URL = urlGenerator(`${MEDICALCHILD_FUNCTION}/${childId}`);

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .delete(URL, {
      headers: headers,
    })
    .then(() => {
      return true;
    })
    .catch((err) => {
      return Promise.reject(new Error(err));
    });
};

const getFollowUps = async (
  idToken: string,
  childId: string
): Promise<FollowUp[]> => {
  const URL = urlGenerator(`${MEDICALCHILD_FUNCTION}/${childId}/followUp`);

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .get(URL, {
      headers: headers,
    })
    .then((response) => {
      let followUps = response.data as FollowUp[];

      return followUps.map((fu) => {
        return {
          ...fu,
          takesOtherMedication: fu.prescriptions.some(
            (p) =>
              p.drug.drugId ===
              process.env.REACT_APP_MEDICAL_OTHER_MEDICATION_ID
          ),
        };
      });
    });
};

const createFollowUp = async (
  idToken: string,
  childId: string,
  followUp: CreateFollowUpBody
): Promise<FollowUp> => {
  const URL = urlGenerator(`${MEDICALCHILD_FUNCTION}/${childId}/followUp`);

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .post(URL, followUp, {
      headers: headers,
    })
    .then((response) => {
      return response.data as FollowUp;
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const updateFollowUp = async (
  idToken: string,
  childId: string,
  observationPeriodId: string,
  startDate: string,
  endDate?: string
): Promise<void> => {
  const URL = urlGenerator(
    `${MEDICALCHILD_FUNCTION}/${childId}/followUp/${observationPeriodId}`
  );

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .put(
      URL,
      {
        startDate,
        endDate,
      },
      {
        headers: headers,
      }
    )
    .then(() => {
      return;
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const updateFollowUpSideEffects = async (
  idToken: string,
  childId: string,
  observationPeriodId: string,
  sideEffectIds: string[]
): Promise<FollowedSideEffect[]> => {
  const URL = urlGenerator(
    `${MEDICALCHILD_FUNCTION}/${childId}/followUp/${observationPeriodId}/side-effects`
  );

  const headers = constructBearerTokenHeader(idToken);

  return axios
    .put(
      URL,
      {
        sideEffectIds,
      },
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response.data as FollowedSideEffect[];
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const updateFollowUpPrescription = async (
  idToken: string,
  childId: string,
  observationPeriodId: string,
  startDate: string,
  prescriptions: Prescription[]
): Promise<void> => {
  const URL = urlGenerator(
    `${MEDICALCHILD_FUNCTION}/${childId}/followUp/${observationPeriodId}/prescriptions`
  );

  const headers = constructBearerTokenHeader(idToken);

  const data: UpdatePrescriptionsBody = {
    startDate,
    prescriptions,
  };

  return axios
    .put(URL, data, {
      headers: headers,
    })
    .then(() => {
      return;
    })
    .catch((error) => {
      return Promise.reject(handleApiError(error));
    });
};

const ChildService = {
  getChildren,
  deleteChild,
  getGamerChildren,
  createChild,
  getFollowUps,
  createFollowUp,
  updateFollowUp,
  updateFollowUpSideEffects,
  updateFollowUpPrescription,
  JournalService,
};

export default ChildService;
