import React from "react";
import {
  AuthError,
  ResetPasswordPage,
} from "@neurosolutionsgroup/authentication";

const ResetPassword = () => {
  const onSuccess = () => {
    console.log("ResetPassword success");
  };

  const onFailure = (err: AuthError) => {
    console.log("ResetPassword failure");
  };

  return (
    <ResetPasswordPage
      token={"this is a token"}
      onSuccess={onSuccess}
      onFailure={onFailure}
    />
  );
};

export default ResetPassword;
