import { Box, Button, Grid, Typography } from "@material-ui/core";
import { Question, SideEffect } from "@neurosolutionsgroup/models";
import { Loader } from "commons/components";
import { FooterButtons } from "commons/components/Layout/Components";
import {
  ApiErrorDialog,
  CancelDialog,
} from "commons/components/Layout/Components/Dialog/Dialog";
import CloseablePage from "commons/components/Layout/Page/CloseablePage/CloseablePage";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import { ApiError } from "commons/models/ApiError";
import { AnswerType } from "commons/models/Types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FrequencyForm from "./frequency/FrequencyForm";
import IntensityForm from "./intensity/IntensityForm";

interface QuestionPageProps {
  sideEffect: SideEffect | undefined;
  open: boolean;
  onClose: VoidFunction;
  onFinish: (intensity: number, frequency: number) => Promise<void>;
}
enum QuestionnaireStep {
  frequency = 1,
  intensity = 2,
}

const QuestionPage = ({
  sideEffect,
  open,
  onClose,
  onFinish,
}: QuestionPageProps): JSX.Element => {
  const ROOT_CLASS = "question-page";
  const { t, i18n } = useTranslation();
  const { loading } = useFollowUps();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [questionnaireStep, setQuestionnaireStep] = useState<QuestionnaireStep>(
    QuestionnaireStep.frequency
  );

  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [frequency, setFrequency] = useState<number>(0);
  const [intensity, setIntensity] = useState<number>(0);
  const questionnaireFinish = () => {
    onFinish(intensity, frequency)
      .then(() => {
        resetForm();
        onClose();
      })
      .catch((error: ApiError) => {
        setApiError(error);
      });
  };

  const resetForm = () => {
    setFrequency(0);
    setIntensity(0);
    setQuestionnaireStep(1);
  };

  const nextStep = () => {
    switch (questionnaireStep) {
      case QuestionnaireStep.frequency:
        setQuestionnaireStep(QuestionnaireStep.intensity);
        break;
      case QuestionnaireStep.intensity:
      default:
        break;
    }
  };
  const previousStep = () => {
    setQuestionnaireStep(QuestionnaireStep.frequency);
  };

  const onCancel = () => {
    resetForm();
    setShowCancelDialog(false);
    onClose();
  };

  const questionForm = () => {
    switch (questionnaireStep) {
      case QuestionnaireStep.frequency:
        return (
          <FrequencyForm frequency={frequency} setFrequncy={setFrequency} />
        );
      case QuestionnaireStep.intensity:
        return (
          <IntensityForm intensity={intensity} setIntensity={setIntensity} />
        );
      default:
        break;
    }
  };

  const stepIndicator = () => {
    return (
      <Box
        className={ROOT_CLASS + "__step-indicator__container"}
        borderRadius={25}
      >
        <Grid
          className={ROOT_CLASS + "__step-indicator__grid"}
          container
          justifyContent="center"
          alignContent="center"
        >
          <Typography
            className={ROOT_CLASS + "__step-indicator__text"}
            align="center"
          >
            {questionnaireStep}
          </Typography>
        </Grid>
      </Box>
    );
  };

  const questionText = () => {
    let question: Question | undefined;
    if (sideEffect) {
      switch (questionnaireStep) {
        case QuestionnaireStep.intensity:
          question = sideEffect.questions.find(
            (q) => q.answerType === AnswerType.intensity
          );
          break;
        case QuestionnaireStep.frequency:
          question = sideEffect.questions.find(
            (q) => q.answerType === AnswerType.frequency
          );
          break;
        default:
          break;
      }
      return question
        ? i18n.language === "en"
          ? question.question.en
          : question.question.fr
        : "";
    }
  };

  return (
    <CloseablePage
      isOpen={open}
      closeElement={
        <div className="question-page__close-element">
          {t("generic.cancel")}
        </div>
      }
      onClose={() => setShowCancelDialog(true)}
      title={
        sideEffect
          ? i18n.language === "en"
            ? sideEffect?.name.en
            : sideEffect?.name.fr
          : ""
      }
      className={ROOT_CLASS}
    >
      <Grid className={ROOT_CLASS + "__grid"} container justifyContent="center">
        {stepIndicator()}
        <Box mt={3} className={ROOT_CLASS + "__form-container"}>
          <Grid className={ROOT_CLASS + "__form-container__grid"} container>
            <Box className={ROOT_CLASS + "__question-text-container"} p={2}>
              <Typography
                className={ROOT_CLASS + "__question-text"}
                align="center"
              >
                {questionText()}
              </Typography>
            </Box>
            {questionForm()}
          </Grid>
        </Box>
      </Grid>
      <Box className={ROOT_CLASS + "__footer-container"}>
        <FooterButtons>
          {questionnaireStep === QuestionnaireStep.frequency ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                frequency === 0 ? questionnaireFinish() : nextStep();
              }}
              disabled={false}
            >
              {frequency === 0 ? t("generic.finish") : t("generic.next")}
            </Button>
          ) : (
            <Grid container justifyContent="space-around">
              <Button
                variant="contained"
                color="secondary"
                onClick={previousStep}
                disabled={false}
              >
                {t("generic.previous")}
              </Button>{" "}
              <Button
                variant="contained"
                color="secondary"
                onClick={questionnaireFinish}
                disabled={intensity === 0}
              >
                {t("generic.finish")}
              </Button>
            </Grid>
          )}
        </FooterButtons>
      </Box>
      <CancelDialog
        open={showCancelDialog}
        onCancel={() => setShowCancelDialog(false)}
        onConfirm={onCancel}
        body={
          <Grid container justifyContent="center">
            <Typography align="center">
              <b>{t("generic.cancelDialog.text.one")}</b> <br />
              <b>{t("journal.note.add.cancel")}</b> <br />
              <b>{t("generic.cancelDialog.text.three")}</b> <br />
            </Typography>
          </Grid>
        }
      />
      <ApiErrorDialog error={apiError} setError={setApiError} />
      <Loader show={loading} />
    </CloseablePage>
  );
};

export default QuestionPage;
