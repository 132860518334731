import clsx from "clsx";
import { CheckBoxIcon } from "commons/components/Icons";
import React from "react";
import BaseAlert from "../BaseAlert";

interface SuccessAlertProps {
  display: boolean;
  text: string;
  onClose?: VoidFunction;
  className?: string;
}

const SuccessAlert: React.FC<SuccessAlertProps> = ({
  display,
  text,
  onClose,
  className,
}) => {
  return (
    <BaseAlert
      display={display}
      icon={<CheckBoxIcon isChecked />}
      text={text}
      onClose={onClose}
      className={clsx("alert--success", className)}
    />
  );
};

export default SuccessAlert;
