import React, { useEffect, useState } from "react";
import { FormButton } from "@neurosolutionsgroup/components";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Loader } from "commons/components";

const LandingPageComponent = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const params = queryString.parse(window.location.search.slice(1));

    if (Object.keys(params).includes("web")) {
      setLoading(false);
    }
  }, []);

  return !loading ? (
    <div className="container">
      <h2>Landing</h2>
      <FormButton
        value={"Login"}
        type={"button"}
        onClick={() => {
          navigate("/authentication");
        }}
      />
      <FormButton
        value={"Registration"}
        type={"button"}
        onClick={() => {
          navigate("/authentication/registration");
        }}
      />
    </div>
  ) : (
    <Loader show={loading} />
  );
};

export default LandingPageComponent;
