import React from "react";
import MuiSlider from "@material-ui/core/Slider";

const Slider: React.FC<React.ComponentProps<typeof MuiSlider>> = ({
  ...props
}) => {
  return <MuiSlider className={"slider " + props.className} {...props} />;
};

export default Slider;
