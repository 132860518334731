import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import React, {
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

interface ScrollPickerProps {
  value: number;
  unit: "hour" | "minute";
  onChange: (value: number) => void;
}

const ScrollPicker: React.FC<ScrollPickerProps> = ({
  unit,
  value,
  onChange,
}) => {
  const ROOT_CLASS = "timepicker-scroll";

  const { t, i18n } = useTranslation();

  // Need to store initial value or it will be changed by auto-scroll.
  const [initialValue] = useState(value);
  const [setupComplete, setSetupComplete] = useState(false);

  // Generate possible values according to units and localization.
  const maxValue = unit === "hour" ? (i18n.language === "en" ? 12 : 23) : 59;

  let values: number[] = [];
  for (
    let i = unit === "hour" && i18n.language === "en" ? 1 : 0;
    i <= maxValue;
    i++
  ) {
    values.push(i);
  }

  // Setup array of div refs for use in auto-scroll.
  const valueRefs = useRef<RefObject<HTMLDivElement>[]>(
    Array.from({ length: values.length }, (a) =>
      React.createRef<HTMLDivElement>()
    )
  );

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Auto scroll to value on first render.
  useLayoutEffect(() => {
    valueRefs.current[initialValue].current?.scrollIntoView({
      block: "center",
    });
    setSetupComplete(true);
  }, [initialValue]);

  return (
    <Grid container direction="column">
      <label className={ROOT_CLASS + "__label"}>
        {t(unit === "hour" ? "time.hours.label" : "time.minutes.label")}
      </label>
      <div className={ROOT_CLASS + "__input"}>
        <div
          className={ROOT_CLASS + "__scroll-container"}
          ref={scrollContainerRef}
        >
          {values.map((v) => {
            return (
              <div ref={valueRefs.current[v]}>
                <ScrollValue
                  value={v}
                  root={scrollContainerRef.current}
                  onChange={() => {
                    setupComplete && onChange(v);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className={ROOT_CLASS + "__unit"}>
          {unit === "hour" ? "h" : "min"}
        </div>
      </div>
    </Grid>
  );
};

export default ScrollPicker;

interface ScrollValueProps {
  value: number;
  root: HTMLDivElement | null;
  onChange: VoidFunction;
}

const ScrollValue: React.FC<ScrollValueProps> = ({ value, root, onChange }) => {
  const { ref, inView } = useInView({
    root: root,
    // inView margin small as possible to prevent conflicts with snap.
    rootMargin: "-39.5% 0% -59.5% 0%",
    delay: 100,
  });

  useEffect(() => {
    if (inView) {
      onChange();
    }
  }, [inView, onChange]);

  return (
    <div
      ref={ref}
      className={clsx("timepicker-scroll__value", {
        "timepicker-scroll__value--selected": inView,
      })}
      onContextMenu={() => false}
    >
      {value}
    </div>
  );
};
