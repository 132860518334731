import React, { ReactNode } from "react";
import MuiButton from "@material-ui/core/Button";
import { Button } from "..";
import clsx from "clsx";

interface IconMenuButtonProps {
  text: string;
  icon: ReactNode;
}

const IconMenuButton: React.FC<
  React.ComponentProps<typeof MuiButton> & IconMenuButtonProps
> = ({ text, icon, ...props }) => {
  const ROOT_CLASS = "icon-menu-button";

  return (
    <div className={ROOT_CLASS}>
      <Button
        variant="contained"
        color="primary"
        {...props}
        className={clsx(ROOT_CLASS + "__button", props.className)}
      >
        {icon}
      </Button>
      <span className={ROOT_CLASS + "__text"}>{text}</span>
    </div>
  );
};

export default IconMenuButton;
