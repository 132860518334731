import React from "react";
import MuiButton from "@material-ui/core/Button";

const Button: React.FC<React.ComponentProps<typeof MuiButton>> = ({
  ...props
}) => {
  return <MuiButton {...props} />;
};

export default Button;
