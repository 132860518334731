const resources = {
  en: {
    translation: {
      dashboard: {
        medication: {
          title: "Select a medication",
          today: "Today",
        },
        summary: {
          observations: "Observations completed",
          frequency: "Average frequency",
          intensity: "Average intensity",
          categories: {
            low: "Low",
            moderate: "Moderate",
            high: "High",
            na: "N/A",
          },
        },
        periods: {
          "label": "Analysis over a period of <1>{{name}}</1>",
          "4": "4 weeks",
          "8": "8 weeks",
          "12": "3 months",
          "24": "6 months",
          "36": "9 months",
          "52": "1 year",
        },
        graph: {
          info: "The data presented is calculated based on the observations completed in the Journal section",
          intensity: "Intensity",
          frequency: {
            title: "Frequency",
            bins: {
              "0": "0 days",
              "1": "1 - 2 days",
              "2": "3 - 5 days",
              "3": "6 - 7 days",
            },
          },
          week: "Week",
          nodata: {
            intensity: "No intensity data",
            frequency: "No frequency data",
          },
        },
      },
    },
  },
  fr: {
    translation: {
      dashboard: {
        medication: {
          title: "Sélectionner une médication",
          today: "aujourd'hui",
        },
        summary: {
          observations: "Observations complétées",
          frequency: "Fréquence moyenne",
          intensity: "Intensité moyenne",
          categories: {
            low: "Faible",
            moderate: "Modéré",
            high: "Élevé",
            na: "N/A",
          },
        },
        periods: {
          "label": "Analyse sur une période de <1></1>",
          "4": "4 semaines",
          "8": "8 semaines",
          "12": "3 mois",
          "24": "6 mois",
          "36": "9 mois",
          "52": "1 an",
        },
        graph: {
          info: "Les données présentées sont calculées en fonction des observations complétées dans la section Journal",
          intensity: "Intensité",
          frequency: {
            title: "Fréquence",
            bins: {
              "0": "0 jour",
              "1": "1 - 2 jours",
              "2": "3 - 5 jours",
              "3": "6 - 7 jours",
            },
          },
          week: "Sem.",
          nodata: {
            intensity: "Aucun donnée d'intensité",
            frequency: "Aucun donnée de fréquence",
          },
        },
      },
    },
  },
};

export default resources;
