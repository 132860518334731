import { v4 } from "uuid";
import { AlertDefinition, AlertVariety, useAlertContext } from "./AlertContext";

interface UseAlertsResult {
  addSuccessAlert: (
    text: string,
    dismissable?: boolean,
    timeout?: boolean
  ) => void;
  addErrorAlert: (
    text: string,
    dismissable?: boolean,
    timeout?: boolean
  ) => void;
}

const useAlerts = (): UseAlertsResult => {
  const { setAlerts } = useAlertContext();

  const addSuccessAlert = (
    text: string,
    dismissable?: boolean,
    timeout?: boolean
  ) => {
    addAlert({
      id: v4(),
      variety: AlertVariety.Success,
      text,
      dismissable,
      timeout,
    });
  };

  const addErrorAlert = (
    text: string,
    dismissable?: boolean,
    timeout?: boolean
  ) => {
    addAlert({
      id: v4(),
      variety: AlertVariety.Error,
      text,
      dismissable,
      timeout,
    });
  };

  const addAlert = (alert: AlertDefinition) => {
    setAlerts((current) => {
      let clone = [...current];
      clone.push(alert);
      return clone;
    });
  };

  return {
    addSuccessAlert,
    addErrorAlert,
  };
};

export default useAlerts;
