import { Medication } from "@neurosolutionsgroup/models";
import axios from "axios";
import { urlGenerator } from ".";

const getMedications = (): Promise<Medication[]> => {
  const URL = urlGenerator("/medication");

  return axios.get(URL).then((response) => {
    return response.data as Medication[];
  });
};

const MedicationService = { getMedications };

export default MedicationService;
