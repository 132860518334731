import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

interface BurgerMenuIconProps {
  color?: string;
}

const BurgerMenuIcon: React.FC<IconProps & BurgerMenuIconProps> = ({
  color,
}) => {
  return (
    <BaseIcon classModifier={""}>
      <path
        d="M 0,16.701414 H 20 V 14.4676 H 0 Z M 0,11.116901 H 20 V 8.8830977 H 0 Z M 0,3.2985855 v 2.233802 h 20 v -2.233802 z"
        fill={color ? color : "#ffffff"}
        id="path20"
      />
    </BaseIcon>
  );
};

export default BurgerMenuIcon;
