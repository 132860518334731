import { ApiError } from "commons/models/ApiError";
import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import ErrorDialog from "./ErrorDialog";

interface ApiErrorDialogProps {
  error: ApiError | undefined;
  setError: React.Dispatch<SetStateAction<ApiError | undefined>>;
}

const ApiErrorDialog: React.FC<ApiErrorDialogProps> = ({ error, setError }) => {
  const { t } = useTranslation();

  return (
    <ErrorDialog
      title={t("generic.errorDialog.title", {
        code: error?.code ?? "500",
      })}
      text={t("generic.errorDialog.text")}
      action={t("generic.tryAgain")}
      open={!!error}
      onClick={() => setError(undefined)}
    />
  );
};

export default ApiErrorDialog;
