import { Box } from "@material-ui/core";
import { ArrowIcon } from "commons/components/Icons";
import { Button } from "commons/components/Inputs";
import useChildren from "commons/hooks/children/useChildren";
import React, { useState } from "react";
import ChildSelectorDrawer from "./ChildSelectorDrawer";

interface ChildSelectorButtonProps {}

const ChildSelectorButton: React.FC<ChildSelectorButtonProps> = () => {
  const { children, selectedChildId, getChildFromId } = useChildren();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const textHandler = () => {
    if (selectedChildId) {
      const child = getChildFromId(selectedChildId);
      if (child) {
        const nameLength = child.name.length;
        if (nameLength >= 15) {
          return child.name.substring(0, 15) + "...";
        } else {
          return child.name;
        }
      }
    }
  };

  const numberOfChild = () => {
    return children.length;
  };

  return (
    <>
      <Button
        onClick={() => setDrawerIsOpen(true)}
        endIcon={numberOfChild() >= 2 ? <ArrowIcon /> : ""}
        className="child-selector-button"
        variant="contained"
        color="secondary"
      >
        <Box className="child-selector-button__text" pr={1}>
          {textHandler()}
        </Box>
      </Button>
      {numberOfChild() >= 2 ? (
        <ChildSelectorDrawer
          isOpen={drawerIsOpen}
          setIsOpen={setDrawerIsOpen}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ChildSelectorButton;
