import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./login/Login";
import RecoverAccount from "./passwordrecovery/RecoverAccount";
import ResetPassword from "./passwordrecovery/ResetPassword";
import CompleteRegistration from "./registration/CompleteRegistration";
import Registration from "./registration/Registration";

const Authentication = () => {
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="recoveraccount" element={<RecoverAccount />} />
        <Route path="resetpassword" element={<ResetPassword />} />
        <Route path="completeregistration" element={<CompleteRegistration />} />
        <Route path="registration" element={<Registration />} />
      </Routes>
    </div>
  );
};

export default Authentication;
