import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { SideEffect } from "@neurosolutionsgroup/models";
import clsx from "clsx";
import { ErrorAlert } from "commons/components/Alerts";
import { SearchIcon } from "commons/components/Icons";
import { Button, Checkbox, TextInput } from "commons/components/Inputs";
import { FooterButtons } from "commons/components/Layout/Components";
import { stringArrayCompare } from "commons/helpers/Array";
import useSideEffects from "commons/hooks/sideEffects/useSideEffects";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SideEffectFormProps {
  initialSideEffects: string[];
  onSubmit: (sideEffects: string[]) => void;
  onBack?: VoidFunction;
  minSideEffects?: number;
  submitText?: string;
  isModified?: boolean;
  onModification?: React.Dispatch<SetStateAction<boolean>>;
  disabledIfUnmodified?: boolean;
}

interface Selections {
  [key: string]: boolean;
}

const enum Filter {
  FrequentlyFollowed,
}

const SideEffectForm: React.FC<SideEffectFormProps> = ({
  initialSideEffects,
  onSubmit,
  onBack,
  minSideEffects = 0,
  submitText,
  isModified = true,
  onModification,
  disabledIfUnmodified,
}) => {
  const ROOT_CLASS = "sideeffect-form";
  const MAXIMUM_SIDE_EFFECTS = 8;

  const { t, i18n } = useTranslation();
  const { sideEffects: sideEffectValues } = useSideEffects();

  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedSideEffects, setSelectedSideEffects] = useState<Selections>(
    {}
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [submitSideEffectDisabled, setSubmitSideEffectDisabled] =
    useState<boolean>(false);
  const [showValidationError, setShowValidationError] = useState(false);

  useEffect(() => {
    if (
      onModification &&
      stringArrayCompare(
        Object.keys(selectedSideEffects).filter((k) => selectedSideEffects[k]),
        initialSideEffects
      )
    ) {
      onModification(false);
    } else {
      onModification && onModification(true);
    }
  }, [selectedSideEffects, onModification, initialSideEffects]);

  // Setup selected side effects from initial values and side effect possible values.
  useEffect(() => {
    sideEffectValues.forEach((se) => {
      setSelectedSideEffects((current) => {
        const clone = { ...current };
        clone[se.id] = initialSideEffects.includes(se.id);
        return clone;
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Calculate if over or under limit of side effects.
  // If too few (0) or too many disable submission.
  // If too many show validation error.
  useEffect(() => {
    const activeSideEffects = Object.keys(selectedSideEffects).filter(
      (key) => selectedSideEffects[key]
    ).length;

    setShowValidationError(activeSideEffects > MAXIMUM_SIDE_EFFECTS);
    setSubmitSideEffectDisabled(
      activeSideEffects > MAXIMUM_SIDE_EFFECTS ||
        activeSideEffects < minSideEffects ||
        !isModified
    );
  }, [selectedSideEffects, minSideEffects, disabledIfUnmodified, isModified]);

  // Activate or deactivate a filter.
  const toggleFilter = (filter: Filter) => {
    setFilters((current) => {
      const clone = [...current];

      if (clone.includes(filter)) {
        return clone.filter((f) => f !== filter);
      } else {
        clone.push(filter);
        return clone;
      }
    });
  };

  const clearFilters = () => {
    setFilters([]);
  };

  const getFilteredSideEffects = useCallback((): SideEffect[] => {
    // Filter results by category.
    const filteredSideEffects = sideEffectValues.filter((se) => {
      if (filters.includes(Filter.FrequentlyFollowed)) {
        return se.isFrequentlyFollowed && !selectedSideEffects[se.id];
      } else {
        return !selectedSideEffects[se.id];
      }
    });

    // Then filter results of category filter by search term.
    // Set search term to lower case and trim any leading or trailing white space.
    return filteredSideEffects.filter((se) => {
      return (
        se.name.en
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trimStart().trimEnd()) ||
        se.name.fr
          .toLowerCase()
          .includes(searchTerm.toLowerCase().trimStart().trimEnd())
      );
    });
  }, [filters, sideEffectValues, selectedSideEffects, searchTerm]);

  // Calculate title to display above list of side effects not yet selected.
  const getFilterTitle = useCallback((): string => {
    if (searchTerm.length > 0) {
      return t("sideEffects.search.title");
    } else {
      if (filters.length > 0) {
        let title = "";
        filters.forEach((f) => {
          title += t("sideEffects.filter." + f) + " ";
        });
        return title;
      } else {
        return t("sideEffects.filter.all");
      }
    }
  }, [filters, t, searchTerm]);

  const toggleSideEffect = (sideEffectId: string, checked: boolean) => {
    setSelectedSideEffects((current) => {
      const clone = { ...current };

      clone[sideEffectId] = checked;

      return clone;
    });
  };

  return (
    <>
      <Box pt={1}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Box pl={1} pr={1}>
              <h4>{t("sideEffects.title")}</h4>
            </Box>
          </Grid>
          <Grid item>
            <Box pl={1} pr={1}>
              <TextInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.currentTarget.value)}
                placeholder={t("sideEffects.search.placeholder")}
                className={ROOT_CLASS + "__search"}
                resetable={true}
                resetFunction={() => setSearchTerm("")}
                icon={<SearchIcon />}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box pl={1}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={clsx(ROOT_CLASS + "__filter", {
                      [ROOT_CLASS + "__filter--selected"]: filters.length === 0,
                    })}
                    onClick={() => clearFilters()}
                  >
                    {t("sideEffects.filter.all")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={clsx(ROOT_CLASS + "__filter", {
                      [ROOT_CLASS + "__filter--selected"]: filters.includes(
                        Filter.FrequentlyFollowed
                      ),
                    })}
                    onClick={() => {
                      toggleFilter(Filter.FrequentlyFollowed);
                    }}
                  >
                    {t("sideEffects.filter." + Filter.FrequentlyFollowed)}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box p={1}>
              {
                // Only show if at least one side effect selected.
                Object.keys(selectedSideEffects).some(
                  (key) => selectedSideEffects[key]
                ) ? (
                  <>
                    <div className={ROOT_CLASS + "__title"}>
                      {t("sideEffects.selected")}
                      <div className={ROOT_CLASS + "__title-line"}></div>
                    </div>
                    {Object.keys(selectedSideEffects)
                      .filter((key) => selectedSideEffects[key])
                      .map((key, i) => {
                        return (
                          <div
                            className={clsx(ROOT_CLASS + "__value", {
                              [ROOT_CLASS + "__value--first"]: i === 0,
                            })}
                            key={key}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedSideEffects[key] ?? false}
                                  onChange={(e) => {
                                    toggleSideEffect(key, e.target.checked);
                                  }}
                                />
                              }
                              label={
                                sideEffectValues.find((se) => se.id === key)
                                  ?.name[i18n.language === "en" ? "en" : "fr"]
                              }
                            />
                          </div>
                        );
                      })}
                  </>
                ) : null
              }
              {
                // Only display if at least one side effect matches filter.
                getFilteredSideEffects().length > 0 ? (
                  <>
                    <div className={ROOT_CLASS + "__title"}>
                      {getFilterTitle()}
                      <div className={ROOT_CLASS + "__title-line"}></div>
                    </div>
                    {getFilteredSideEffects().map((se, i) => (
                      <div
                        className={clsx(ROOT_CLASS + "__value", {
                          [ROOT_CLASS + "__value--first"]: i === 0,
                        })}
                        key={se.id}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedSideEffects[se.id] ?? false}
                              onChange={(e) => {
                                toggleSideEffect(se.id, e.target.checked);
                              }}
                            />
                          }
                          label={se.name[i18n.language === "en" ? "en" : "fr"]}
                        />
                      </div>
                    ))}
                  </>
                ) : null
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
      <FooterButtons>
        <ErrorAlert
          className={ROOT_CLASS + "__error"}
          display={showValidationError}
          text={t("sideEffects.error.maximum", {
            maximum: MAXIMUM_SIDE_EFFECTS,
          })}
        />
        <Grid container spacing={1} justifyContent="center">
          {onBack ? (
            <Grid item>
              <Button variant="contained" color="secondary" onClick={onBack}>
                {t("generic.previous")}
              </Button>
            </Grid>
          ) : null}
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={submitSideEffectDisabled}
              onClick={() => {
                onSubmit(
                  Object.keys(selectedSideEffects).filter(
                    (key) => selectedSideEffects[key]
                  )
                );
              }}
            >
              {submitText ?? t("generic.next")}
            </Button>
          </Grid>
        </Grid>
      </FooterButtons>
    </>
  );
};

export default SideEffectForm;
