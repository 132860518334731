import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

interface CheckBoxIconProps {
  isChecked: boolean;
}

const CheckBoxIcon: React.FC<CheckBoxIconProps & IconProps> = ({
  onClick,
  isChecked,
}) => {
  return (
    <BaseIcon onClick={onClick} classModifier="checkbox">
      <circle
        cx="10"
        cy="10"
        r="9.333333"
        stroke="#000000"
        strokeWidth="1.33333"
        id="circle824"
      />
      {isChecked ? (
        <path
          d="M 7.6119402,12.910467 4.4776068,9.77612 3.4328335,10.820893 7.6119402,15 16.567166,6.0447733 15.522367,5 Z"
          fill="#000000"
          id="path916"
        />
      ) : null}
    </BaseIcon>
  );
};

export default CheckBoxIcon;
