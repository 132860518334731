import { Box, Typography } from "@material-ui/core";
import { Prescription } from "@neurosolutionsgroup/models";
import { Loader } from "commons/components";
import {
  CancelDialog,
  ErrorDialog,
  YesNoDialog,
} from "commons/components/Layout/Components/Dialog/Dialog";
import CloseablePage from "commons/components/Layout/Page/CloseablePage/CloseablePage";
import TimeHelpers from "commons/helpers/Time";
import useAlerts from "commons/hooks/alerts/useAlerts";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import { ApiError } from "commons/models/ApiError";
import { FrontEndFollowUp } from "commons/models/Medical";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PrescriptionForm from "./PrescriptionForm/PrescriptionForm";

interface ModifyPrescriptionFormProps {
  open: boolean;
  onClose: VoidFunction;
  initialFollowUp: FrontEndFollowUp;
}

const ModifyPrescriptionForm: React.FC<ModifyPrescriptionFormProps> = ({
  open,
  onClose,
  initialFollowUp,
}) => {
  const { t } = useTranslation();
  const { addSuccessAlert } = useAlerts();

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const [isModified, setIsModified] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  // Form state.
  const [prescription, setPrescription] = useState<Prescription | undefined>(
    undefined
  );
  const [takesOtherMedication, setTakesOtherMedication] = useState<boolean>(
    initialFollowUp.takesOtherMedication
  );
  const [startDate, setStartDate] = useState<Date>(
    TimeHelpers.date.getDateFromApiDateString(initialFollowUp.startDate)
  );

  const {
    loading,
    requests: { correctPrescription },
  } = useFollowUps();

  const resetState = () => {
    setApiError(undefined);
    setShowCancelDialog(false);
  };

  const onCancelConfirmation = () => {
    resetState();
    onClose();
  };

  const onSubmit = (
    newPrescription: Prescription,
    newtakesOtherMedication: boolean,
    newStartDate: Date
  ) => {
    setPrescription(newPrescription);
    setTakesOtherMedication(newtakesOtherMedication);
    setStartDate(newStartDate);
    setShowConfirmationDialog(true);
  };

  const onSubmitConfirm = () => {
    if (prescription) {
      correctPrescription({
        ...initialFollowUp,
        startDate: TimeHelpers.strings.getDateStringFromDate(startDate),
        prescriptions: [prescription],
        takesOtherMedication,
      })
        .then(() => {
          addSuccessAlert(t("medication.actions.correct.success"), true, true);
          onClose();
        })
        .catch((error: ApiError) => {
          setApiError(error);
        });
    }
  };

  return (
    <CloseablePage
      isOpen={open}
      onClose={() =>
        isModified ? setShowCancelDialog(true) : onCancelConfirmation()
      }
      title={t("medication.actions.correct.title")}
      closeElement={<div>{t("generic.cancel")}</div>}
      hasFooterButtons
    >
      <PrescriptionForm
        onSubmit={onSubmit}
        submitText={t("generic.update")}
        initialStartDate={TimeHelpers.date.getDateFromApiDateString(
          initialFollowUp.startDate
        )}
        initialTakesOtherMedication={initialFollowUp.takesOtherMedication}
        prescription={
          initialFollowUp.prescriptions.length > 0
            ? initialFollowUp.prescriptions[0]
            : undefined
        }
        onModification={setIsModified}
        isModified={isModified}
        disabledIfUnmodified
      />
      <YesNoDialog
        open={showConfirmationDialog}
        onNo={() => setShowConfirmationDialog(false)}
        onYes={() => {
          setShowConfirmationDialog(false);
          onSubmitConfirm();
        }}
        title={t("medication.actions.correct.title")}
        body={
          <Typography variant="body2" align="center">
            <Box mb={1}>
              <b>{t("medication.actions.correct.confirm.body.one")}</b>
            </Box>
            <Box mb={1}>
              <b>{t("medication.actions.correct.confirm.body.two")}</b>
            </Box>
            <Box mb={1}>
              <b>{t("medication.actions.correct.confirm.body.three")}</b>
            </Box>
          </Typography>
        }
      />
      <CancelDialog
        open={showCancelDialog}
        onCancel={() => {
          setShowCancelDialog(false);
        }}
        onConfirm={onCancelConfirmation}
      />
      <ErrorDialog
        title={t("generic.errorDialog.title", {
          code: apiError?.code ?? "500",
        })}
        text={t("generic.errorDialog.text")}
        action={t("generic.tryAgain")}
        open={!!apiError}
        onClick={() => setApiError(undefined)}
      />
      <Loader show={loading} />
    </CloseablePage>
  );
};

export default ModifyPrescriptionForm;
