import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Slider } from "commons/components/Inputs";
import { CloseIcon } from "commons/components/Icons";
import { TimePicker } from "commons/components/Inputs";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { Dose, Medication } from "@neurosolutionsgroup/models";

interface DoseProps {
  dose: Dose;
  updateTime: (time: number) => void;
  updateDoseValue: (dose: number) => void;
  index: number;
  medication?: Medication;
  onDelete: VoidFunction;
}

const DoseComponent: React.FC<DoseProps> = ({
  dose,
  updateTime,
  updateDoseValue,
  index,
  medication,
  onDelete,
}) => {
  const ROOT_CLASS = "dose-element";

  const { t } = useTranslation();

  const marks = useCallback(() => {
    const marks = [{ value: 0 }];

    if (medication) {
      marks.push(
        ...medication.dosage.map((d) => {
          return {
            value: d,
          };
        })
      );
    }

    return marks;
  }, [medication]);

  return (
    <Box className={ROOT_CLASS} p={1} mb={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {index === 0 ? (
            <h3>{t("medication.dose.label.0")}</h3>
          ) : (
            <div className={ROOT_CLASS + "__label--deleteable"}>
              <span>
                <h3>{t("medication.dose.label." + index)}</h3>
              </span>
              <CloseIcon onClick={onDelete} />
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <TimePicker
            time={dose.time === -1 ? undefined : dose.time}
            onChange={updateTime}
            label={t("medication.dose.time.label")}
          />
        </Grid>
        <Grid item xs={12}>
          <label className={ROOT_CLASS + "__dose-label"}>
            {dose.time >= 14400 && dose.time <= 43200
              ? t("medication.dose.dose.label.morning")
              : dose.time > 43200 && dose.time <= 64800
              ? t("medication.dose.dose.label.afternoon")
              : t("medication.dose.dose.label.night")}
          </label>
          <Box p={1} pb={0}>
            <Slider
              marks={marks()}
              step={null}
              min={marks()[0].value}
              max={marks()[marks().length - 1].value}
              value={dose.quantity}
              onChange={(_, v) => updateDoseValue(v as number)}
              className="dose-slider"
              color="secondary"
            />
          </Box>
          <Box display="flex" justifyContent="center">
            <span>{dose.quantity} mg</span>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DoseComponent;
