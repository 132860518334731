import TimeHelpers from "commons/helpers/Time";
import { AnswerType } from "commons/models/Types";
import { v4 } from "uuid";
import {
  FollowedSideEffect,
  GamerChild,
  MedicalChild,
  Note,
  SideEffect,
} from "@neurosolutionsgroup/models";
import { FrontEndFollowUp } from "commons/models/Medical";

export const devChildren: MedicalChild[] = [
  {
    medicalChildId: "ae65e65c-38b2-4fc5-aeae-9c9eb4427da4",
    gamerChildId: "ae65e65c-38b2-4fc5-aeae-9c9eb4427da4",
    name: "TestChild",
    userId: "0123456789ABCDEF",
  },
  {
    medicalChildId: "72549be7-8742-42a8-bfc0-9e2154c8444c",
    gamerChildId: "72549be7-8742-42a8-bfc0-9e2154c8444c",
    name: "this name is to long",
    userId: "0123456789ABCDEF",
  },
];

export const devGamerChildren: GamerChild[] = [
  {
    id: "ae65e65c-38b2-4fc5-aeae-9c9eb4427da4",
    name: "TestChild",
    icon: 4,
    creation: 1634059989,
    nip: null,
    history: [],
  },
  {
    id: "72549be7-8742-42a8-bfc0-9e2154c8444c",
    name: "this name is to long",
    icon: 3,
    creation: 1634059989,
    nip: null,
    history: [],
  },
  {
    id: "e3d37626-308d-4135-bb95-6ab2f12c81cf",
    name: "TG",
    icon: 2,
    creation: 1634059989,
    nip: null,
    history: [],
  },
];

export const devMedication = [
  {
    id: "e3d37626-308d-4135-bb95-6ab2f12c81cf",
    name: {
      en: "Adderall XR®",
      fr: "Adderall XR®",
    },
    dosage: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    drugTypeId: v4(),
  },
  {
    id: v4(),
    name: { en: "Concerta®", fr: "Concerta®" },
    dosage: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
    drugTypeId: v4(),
  },
  {
    id: v4(),
    name: { en: "Dexedrine®", fr: "Dexedrine®" },
    dosage: [5, 10, 15, 20],
    drugTypeId: v4(),
  },
];

export const devSideEffects: SideEffect[] = [
  {
    id: "1",
    name: { en: "Headache", fr: "Mal à la tête" },
    isFrequentlyFollowed: true,
    questions: [
      {
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        answerType: AnswerType.frequency,
        answerValue: {
          minValue: 1,
          maxValue: 7,
          step: 1,
        },
        question: {
          en: "Durant la dernière semaine, à quelle fréquence avez-vous observé SIDE_EFFECT?",
          fr: "During the past week, how often have you observed SIDE_EFFECT?",
        },
      },
      {
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        answerType: AnswerType.intensity,
        answerValue: {
          minValue: 1,
          maxValue: 3,
          step: 1,
        },
        question: {
          fr: "Durant la dernière semaine, à quelle intensité qualifiez-vous SIDE_EFFECT?",
          en: "During the past week, at what intensity do you qualify SIDE_EFFECT?",
        },
      },
    ],
  },
  {
    id: "2",
    name: { en: "Vomiting", fr: "Vomissements" },
    isFrequentlyFollowed: false,
    questions: [
      {
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        answerType: AnswerType.frequency,
        answerValue: {
          minValue: 1,
          maxValue: 7,
          step: 1,
        },
        question: {
          en: "Durant la dernière semaine, à quelle fréquence avez-vous observé SIDE_EFFECT?",
          fr: "During the past week, how often have you observed SIDE_EFFECT?",
        },
      },
      {
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        answerType: AnswerType.intensity,
        answerValue: {
          minValue: 1,
          maxValue: 3,
          step: 1,
        },
        question: {
          fr: "Durant la dernière semaine, à quelle intensité qualifiez-vous SIDE_EFFECT?",
          en: "During the past week, at what intensity do you qualify SIDE_EFFECT?",
        },
      },
    ],
  },
  {
    id: "3",
    name: { en: "Weight gain", fr: "Gain de poids" },
    isFrequentlyFollowed: true,
    questions: [
      {
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        answerType: AnswerType.frequency,
        answerValue: {
          minValue: 1,
          maxValue: 7,
          step: 1,
        },
        question: {
          en: "Durant la dernière semaine, à quelle fréquence avez-vous observé SIDE_EFFECT?",
          fr: "During the past week, how often have you observed SIDE_EFFECT?",
        },
      },
      {
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        answerType: AnswerType.intensity,
        answerValue: {
          minValue: 1,
          maxValue: 3,
          step: 1,
        },
        question: {
          fr: "Durant la dernière semaine, à quelle intensité qualifiez-vous SIDE_EFFECT?",
          en: "During the past week, at what intensity do you qualify SIDE_EFFECT?",
        },
      },
    ],
  },
];

export const devFollowUp: FrontEndFollowUp[] = [
  {
    followUpId: v4(),
    startDate: "2020-01-01",
    endDate: "2021-01-01",
    followedSideEffectIds: ["1", "2"],
    medicalChildId: "ae65e65c-38b2-4fc5-aeae-9c9eb4427da4",
    prescriptions: [
      {
        prescriptionId: v4(),
        drug: {
          doses: [
            { quantity: 5, time: 28800 },
            { quantity: 10, time: 32400 },
            { quantity: 50, time: 36000 },
          ],
          drugId: "e3d37626-308d-4135-bb95-6ab2f12c81cf",
        },
      },
    ],
    takesOtherMedication: false,
  },
  {
    followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
    startDate: "2021-01-01",
    followedSideEffectIds: ["1", "2"],
    medicalChildId: "ae65e65c-38b2-4fc5-aeae-9c9eb4427da4",
    prescriptions: [
      {
        prescriptionId: v4(),
        drug: {
          doses: [
            { quantity: 5, time: 28800 },
            { quantity: 10, time: 32400 },
            { quantity: 50, time: 36000 },
          ],
          drugId: "e3d37626-308d-4135-bb95-6ab2f12c81cf",
        },
      },
    ],
    takesOtherMedication: false,
  },
];

export const devNotes: Note[] = [
  {
    noteId: v4(),
    eventDate: TimeHelpers.strings.getDateStringFromDate(
      TimeHelpers.date.getDateUTCMidnight()
    ),
    title: "Title",
    text: "Text",
    medicalChildId: devChildren[0].medicalChildId,
    sideEffectId: devSideEffects[0].id,
  },
];

export const devRecords: FollowedSideEffect[] = [
  {
    followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
    startDate: "2021-01-01",
    isActive: true,
    sideEffectId: "1",
    activeStatusChanges: [],
    // Was deactivated last week:
    // activeStatusChanges: [new Date(2021, 10, 7, 23, 59)],
    recordsLast4Weeks: [
      {
        recordId: v4(),
        weekStartDate: "2020-12-28",
        answer: 2,
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        sideEffectId: "1",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-04",
        answer: 5,
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        sideEffectId: "1",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-18",
        answer: 6,
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        sideEffectId: "1",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2020-12-28",
        answer: 1,
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        sideEffectId: "1",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-04",
        answer: 3,
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        sideEffectId: "1",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-18",
        answer: 2,
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        sideEffectId: "1",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
    ],
  },
  {
    followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
    startDate: "2021-01-01",
    isActive: true,
    sideEffectId: "2",
    activeStatusChanges: [],
    recordsLast4Weeks: [
      {
        recordId: v4(),
        weekStartDate: "2020-12-28",
        answer: 0,
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-04",
        answer: 1,
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-11",
        answer: 2,
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-18",
        answer: 1,
        questionId: "39f391aa-faa7-4c75-abbd-a49bcff2b2e0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2020-12-28",
        answer: 3,
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-04",
        answer: 2,
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-11",
        answer: 3,
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
      {
        recordId: v4(),
        weekStartDate: "2021-01-18",
        answer: 1,
        questionId: "cc493f17-6096-46e5-aacd-cbff5a2be6a0",
        sideEffectId: "2",
        followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
      },
    ],
  },
  {
    followUpId: "b6820d86-e7d0-48a4-aaeb-81b00621f905",
    startDate: "2021-01-01",
    isActive: false,
    sideEffectId: "3",
    activeStatusChanges: ["2021-10-05"],
    recordsLast4Weeks: [],
  },
];
