import React from "react";
import clsx from "clsx";

interface PageProps {
  header?: JSX.Element;
  customClass?: string;
}

const Page: React.FC<PageProps & React.HTMLProps<HTMLDivElement>> = (props) => {
  const ROOT_CLASS = "page";

  return (
    <div {...props} className={clsx(ROOT_CLASS, props.className)}>
      {props.header && (
        <div className={ROOT_CLASS + "__header"}>{props.header}</div>
      )}
      <div className={ROOT_CLASS + "__body"}>{props.children}</div>
    </div>
  );
};

export default Page;
