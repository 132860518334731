export const stringArrayCompare = (
  a: string[],
  b: string[],
  ignoreOrder?: boolean
): boolean => {
  return ignoreOrder
    ? a.length === b.length && a.sort().every((v, i) => v === b.sort()[i])
    : a.length === b.length && a.every((v, i) => v === b[i]);
};

export const getAverage = (arr: number[]) => {
  let reducer = (total: number, currentValue: number) => total + currentValue;
  let sum = arr.reduce(reducer);
  return sum / arr.length;
};

export const getModes = (arr: number[]): number[] => {
  const frequency: { [key: number]: number | undefined } = {};
  let maxFreq = 0;
  const modes: number[] = [];

  for (let i in arr) {
    let newFrequency = (frequency[arr[i]] || 0) + 1;

    frequency[arr[i]] = newFrequency;

    if (newFrequency && newFrequency > maxFreq) {
      maxFreq = newFrequency;
    }
  }

  Object.keys(frequency).forEach((f) => {
    if (frequency[Number(f)] === maxFreq) {
      modes.push(Number(f));
    }
  });

  return modes;
};
