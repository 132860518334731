import { SetStateAction, useState } from "react";
import { createGenericContext } from "../GenericContext";
import AlertsComponent from "./AlertsComponent";

export enum AlertVariety {
  Success,
  Error,
}

export type AlertDefinition = {
  id: string;
  variety: AlertVariety;
  text: string;
  dismissable?: boolean;
  timeout?: boolean;
};

interface AlertContextData {
  alerts: AlertDefinition[];
  setAlerts: React.Dispatch<SetStateAction<AlertDefinition[]>>;
}

const [useAlertContext, AlertContextProvider] =
  createGenericContext<AlertContextData>();

const AlertProvider: React.FC = (props) => {
  const [alerts, setAlerts] = useState<AlertDefinition[]>([]);

  const onHide = (id: string) => {
    setAlerts((current) => {
      let clone = [...current];
      return clone.filter((a) => a.id !== id);
    });
  };

  return (
    <AlertContextProvider value={{ alerts, setAlerts }}>
      <AlertsComponent alerts={alerts} onHide={onHide} />
      {props.children}
    </AlertContextProvider>
  );
};

export { useAlertContext, AlertProvider };
