import { Box, Button, Grid, Typography } from "@material-ui/core";
import { pi0, pi1, pi2, pi3, pi4, pi5 } from "commons/assets/profileIcons";
import { Loader, Page } from "commons/components";
import { ArrowIcon } from "commons/components/Icons";
import { ArrowType } from "commons/components/Icons/ArrowIcon";
import KairosBackButton from "commons/components/Inputs/Button/KairosBackButton/KairosBackButton";
import {
  FooterButtons,
  PageHeader,
} from "commons/components/Layout/Components";
import { ErrorDialog } from "commons/components/Layout/Components/Dialog/Dialog";
import useChildren from "commons/hooks/children/useChildren";
import { ApiError } from "commons/models/ApiError";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const AddChildPage: React.FC = () => {
  const { t } = useTranslation();

  const { children, gamerChildren, updateMedicalChildren, loading } =
    useChildren();
  const [selectedChildIds, setSelectedChildIds] = useState<string[]>([]);
  const [originalSelectedChildren, setOriginalSelectedChildren] = useState<
    string[]
  >([]);
  const [apiError, setApiError] = useState<ApiError | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const selectedChild: string[] = [];
    gamerChildren.forEach((gc) => {
      if (children.find((c) => c.gamerChildId === gc.gamerChildId)) {
        selectedChild.push(gc.id);
      }
    });
    setSelectedChildIds(selectedChild);
    setOriginalSelectedChildren(selectedChild);
  }, [children, gamerChildren]);

  const selectedChildChanged = (): boolean => {
    if (!(selectedChildIds.length === originalSelectedChildren.length)) {
      return true;
    } else {
      let newIds = selectedChildIds.sort();
      let oldIds = originalSelectedChildren.sort();

      if (!(newIds === oldIds)) {
        return true;
      }
    }
    return false;
  };

  const profilIcon = (i: number) => {
    switch (i) {
      case 0:
        return pi0;
      case 1:
        return pi1;
      case 2:
        return pi2;
      case 3:
        return pi3;
      case 4:
        return pi4;
      case 5:
        return pi5;
      default:
        break;
    }
  };

  const onClick = (gamerChildId: string) => {
    let id = selectedChildIds.find((id) => id === gamerChildId);
    if (id) {
      const i = selectedChildIds.indexOf(id);
      let clone = [...selectedChildIds];
      clone.splice(i, 1);
      setSelectedChildIds(clone);
    } else {
      let clone = [...selectedChildIds];
      clone.push(gamerChildId);
      setSelectedChildIds(clone);
    }
  };

  const isSelected = (gamerChildId: string): boolean => {
    return selectedChildIds.find((id) => id === gamerChildId) ? true : false;
  };

  const closeElement = () => {
    if (originalSelectedChildren.length === 0) {
      return <KairosBackButton />;
    } else {
      return (
        <Box
          onClick={() => {
            navigate("/app/follow-up");
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Box className={"add-child-page__back-button__icon"} mr={1}>
              <ArrowIcon arrowType={ArrowType.empty} />
            </Box>
            <Box className={"add-child-page__back-button__text"}>
              {t("navigation.pages.option.title")}
            </Box>
          </Grid>
        </Box>
      );
    }
  };

  const handleSubmit = async () => {
    updateMedicalChildren(selectedChildIds)
      .then(() => navigate("/app/follow-up"))
      .catch((err) => {
        setApiError(err);
      });
  };

  return (
    <Page className={"add-child-page"}>
      <Box mt={1} ml={1}>
        <PageHeader leftElement={closeElement()} rightElement={<> </>} />
      </Box>
      <Box p={2}>
        <Typography>{t("generic.selectAChild")}</Typography>
        {gamerChildren.map((gc) => {
          return (
            <Box
              className={"add-child-selector__container"}
              p={2}
              mt={1}
              borderRadius={10}
              bgcolor={isSelected(gc.id) ? "#C7B3F2" : "#fff"}
              onClick={() => onClick(gc.id)}
            >
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <Box
                    className={"add-child-selector__image-wrapper"}
                    bgcolor={"#fff"}
                  >
                    <img
                      className={"add-child-selector__image"}
                      src={profilIcon(gc.icon)}
                      alt="child_icon"
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    m={2}
                    className={
                      isSelected(gc.id)
                        ? "add-child-selector__text-container__selected"
                        : "add-child-selector__text-container"
                    }
                  >
                    <Typography>{gc.name}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
      <FooterButtons>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleSubmit()}
          disabled={!selectedChildChanged()}
        >
          {t(
            originalSelectedChildren.length === 0
              ? "generic.next"
              : "generic.update"
          )}
        </Button>
      </FooterButtons>
      <ErrorDialog
        title={t("generic.errorDialog.title", {
          code: apiError?.code ?? "500",
        })}
        text={t("generic.errorDialog.text")}
        action={t("generic.tryAgain")}
        open={!!apiError}
        onClick={() => setApiError(undefined)}
      />
      <Loader show={loading} />
    </Page>
  );
};

export default AddChildPage;
