import { Note } from "@neurosolutionsgroup/models";
import { SetStateAction, useState } from "react";
import { createGenericContext } from "../GenericContext";

interface NotesContextData {
  notes: Note[];
  setNotes: React.Dispatch<SetStateAction<Note[]>>;
}

const [useNotesContext, NotesContextProvider] =
  createGenericContext<NotesContextData>();

const NotesProvider: React.FC = (props) => {
  const [notes, setNotes] = useState<Note[]>([]);

  return (
    <NotesContextProvider value={{ notes, setNotes }}>
      {props.children}
    </NotesContextProvider>
  );
};

export { useNotesContext, NotesProvider };
