import { Loader as NSGLoader } from "@neurosolutionsgroup/components";
import React from "react";
import LoaderIcon from "../Icons/LoaderIcon";

interface LoaderProps {
  show: boolean;
}

const Loader: React.FC<
  LoaderProps & React.ComponentProps<typeof NSGLoader>
> = ({ show }) => {
  return show ? <NSGLoader loader={<LoaderIcon />} /> : null;
};

export default Loader;
