import { GamerChild, MedicalChild } from "@neurosolutionsgroup/models";
import { SetStateAction, useEffect, useState } from "react";
import { createGenericContext } from "../GenericContext";

interface ChildrenContextData {
  children: MedicalChild[];
  setChildren: React.Dispatch<SetStateAction<MedicalChild[]>>;
  gamerChildren: GamerChild[];
  setGamerChildren: React.Dispatch<SetStateAction<GamerChild[]>>;
  selectedChildId: string | undefined;
  setSelectedChildId: React.Dispatch<SetStateAction<string | undefined>>;
}

const [useChildrenContext, ChildrenContextProvider] =
  createGenericContext<ChildrenContextData>();

const ChildrenProvider: React.FC = (props) => {
  const [children, setChildren] = useState<MedicalChild[]>([]);
  const [gamerChildren, setGamerChildren] = useState<GamerChild[]>([]);
  const [selectedChildId, setSelectedChildId] = useState<string | undefined>(
    undefined
  );

  // This useEffect is added so app functions without child selector implemented yet.
  // Should be replaced when function to pick child is implemented.
  useEffect(() => {
    if (children.length > 0) {
      setSelectedChildId(children[0].medicalChildId);
    }
  }, [children]);

  return (
    <ChildrenContextProvider
      value={{
        children,
        setChildren,
        gamerChildren,
        setGamerChildren,
        selectedChildId,
        setSelectedChildId,
      }}
    >
      {props.children}
    </ChildrenContextProvider>
  );
};

export { useChildrenContext, ChildrenProvider };
