import { useAuth } from "@neurosolutionsgroup/authentication";
import React, { SetStateAction, useEffect, useState } from "react";
import { createGenericContext } from "./GenericContext";
import {
  MedicationProvider,
  useMedicationContext,
} from "./medication/MedicationContext";
import {
  SideEffectsProvider,
  useSideEffectsContext,
} from "./sideEffects/SideEffectsContext";
import {
  ChildrenProvider,
  useChildrenContext,
} from "./children/ChildrenContext";
import MedicalService from "commons/services/Medical";
import {
  FollowUpsProvider,
  useFollowUpsContext,
} from "./followUp/FollowUpsContext";
import {
  devChildren,
  devFollowUp,
  devGamerChildren,
  devMedication,
  devNotes,
  devRecords,
  devSideEffects,
} from "./ContextDevValues";
import { NotesProvider, useNotesContext } from "./notes/NotesContext";
import { FollowedSideEffect } from "@neurosolutionsgroup/models";
import { getAuth } from "firebase/auth";

export interface AppDataContextData {
  dataFetched: boolean;
  loading: boolean;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  noInternetConnection: boolean;
  setNoInternetConnection: React.Dispatch<SetStateAction<boolean>>;
}

const [useAppDataContext, AppDataContextProvider] =
  createGenericContext<AppDataContextData>();

const AppDataProvider: React.FC = (props) => {
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    selectors: { tenant },
    actions: { isAuthenticated, getIdToken },
  } = useAuth();
  const [noInternetConnection, setNoInternetConnection] = useState(false);

  const { setMedications } = useMedicationContext();
  const { setSideEffects } = useSideEffectsContext();
  const { setChildren, setGamerChildren } = useChildrenContext();
  const { setFollowUps, setFollowUpSideEffects } = useFollowUpsContext();
  const { setNotes } = useNotesContext();

  useEffect(() => {
    if (
      process.env.REACT_APP_USE_DEV_VALUES &&
      (process.env.REACT_APP_SKIP_AUTH || isAuthenticated())
    ) {
      setChildren(devChildren);
      setGamerChildren(devGamerChildren);
      setMedications(devMedication);
      setSideEffects(devSideEffects);
      setFollowUps(devFollowUp);
      setFollowUpSideEffects(devRecords);
      setNotes(devNotes);

      setDataFetched(true);
    }

    getIdToken().then((authToken) => {
      const userId = getAuth().currentUser?.uid;

      if (authToken && userId && tenant) {
        Promise.all([
          MedicalService.MedicationService.getMedications().then((response) => {
            setMedications(response);
          }),
          MedicalService.SideEffectService.getSideEffects().then((response) => {
            setSideEffects(response);
          }),
          MedicalService.ChildService.getChildren(authToken).then(
            (response) => {
              setChildren(response);

              return Promise.all(
                response.map((c) => {
                  return Promise.all([
                    MedicalService.ChildService.getFollowUps(
                      authToken,
                      c.medicalChildId
                    ).then((followUps) => {
                      setFollowUps((current) => {
                        return [
                          ...current,
                          ...followUps.map((fu) => {
                            return {
                              followUpId: fu.followUpId,
                              startDate: fu.startDate,
                              endDate: fu.endDate,
                              prescriptions: fu.prescriptions,
                              followedSideEffectIds: fu.followedSideEffects
                                .filter((fse) => fse.isActive)
                                .map((fse) => fse.sideEffectId),
                              medicalChildId: fu.medicalChildId,
                              takesOtherMedication: fu.takesOtherMedication,
                            };
                          }),
                        ];
                      });

                      setFollowUpSideEffects((current) => {
                        const followUpSideEffects: FollowedSideEffect[] = [
                          ...current,
                        ];

                        followUps.forEach((fu) => {
                          fu.followedSideEffects.forEach((fuse) => {
                            followUpSideEffects.push({
                              ...fuse,
                            });
                          });
                        });
                        return followUpSideEffects;
                      });
                    }),
                    MedicalService.ChildService.JournalService.getNotes(
                      authToken,
                      c.medicalChildId
                    ).then((response) => {
                      setNotes((current) => [...current, ...response]);
                    }),
                  ]);
                })
              ).then(() => {
                return Promise.resolve();
              });
            }
          ),
          MedicalService.ChildService.getGamerChildren(userId, tenant).then(
            (response) => {
              setGamerChildren(response);
            }
          ),
        ]).then(() => {
          setDataFetched(true);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, isAuthenticated]);

  return (
    <AppDataContextProvider
      value={{
        dataFetched,
        loading,
        setLoading,
        noInternetConnection,
        setNoInternetConnection,
      }}
    >
      {props.children}
    </AppDataContextProvider>
  );
};

const AppDataProviders: React.FC = (props) => {
  return (
    <ChildrenProvider>
      <MedicationProvider>
        <SideEffectsProvider>
          <FollowUpsProvider>
            <NotesProvider>
              <AppDataProvider>{props.children}</AppDataProvider>
            </NotesProvider>
          </FollowUpsProvider>
        </SideEffectsProvider>
      </MedicationProvider>
    </ChildrenProvider>
  );
};

export { useAppDataContext, AppDataProviders };
