import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import genericResources from "./generic.i18n";
import journalResources from "./journal.i18n";
import medicationResources from "./medication.i18n";
import navigationResources from "./navigation.i18n";
import dashboardResources from "./dashboard.i18n";
import timeResources from "./time.i18n";
import deepmerge from "deepmerge";
import LanguageDetector from "i18next-browser-languagedetector";

const mergedResources: any = deepmerge.all([
  genericResources,
  journalResources,
  timeResources,
  medicationResources,
  navigationResources,
  dashboardResources,
]);

export function initWithBrowserLocale() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      supportedLngs: ["en", "fr"],
      fallbackLng: "en",
      resources: mergedResources,
      interpolation: {
        escapeValue: false,
      },
      lng: "fr",
      debug: process.env.NODE_ENV === "development",
    });
}
