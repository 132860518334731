import MomentUtils from "@date-io/moment";
import { Moment } from "moment";

class MomentUTCUtils extends MomentUtils {
  format(value: Moment, formatString: string) {
    return this.moment.utc(value).format(formatString);
  }

  parse(value: string, format: string) {
    return this.moment.utc(value, format, true);
  }

  date(value?: any) {
    const moment = this.moment.utc(value);
    if (this.locale) {
      moment.locale(this.locale);
    }

    return moment;
  }
}

export default MomentUTCUtils;
