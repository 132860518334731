import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Note } from "@neurosolutionsgroup/models";
import clsx from "clsx";
import { ArrowIcon } from "commons/components/Icons";
import { ArrowType } from "commons/components/Icons/ArrowIcon";
import { Card } from "commons/components/Layout/Components";
import useSideEffects from "commons/hooks/sideEffects/useSideEffects";
import React from "react";
import { useTranslation } from "react-i18next";

interface NotesDisplayProps {
  notes: Note[];
  onNoteClick: (noteId: string) => void;
}

const NotesDisplay: React.FC<NotesDisplayProps> = ({ notes, onNoteClick }) => {
  const ROOT_CLASS = "notes-display";

  const { t, i18n } = useTranslation();

  const { getSideEffectFromId } = useSideEffects();

  return (
    <Card className={ROOT_CLASS}>
      <Grid container direction="column" spacing={1}>
        <Grid item xs={12}>
          <Box pt={2} px={2} pb={1} className={ROOT_CLASS + "__title"}>
            <span>{t("journal.note.list.title")}</span>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {notes.length > 0 ? (
            notes
              .sort((a, b) => (a.eventTime ?? 0) - (b.eventTime ?? 0))
              .map((n, i) => {
                return (
                  <Box
                    pb={1.5}
                    pt={i === 0 ? 0.5 : 1.5}
                    px={2}
                    className={clsx(ROOT_CLASS + "__item", {
                      [ROOT_CLASS + "__item--first"]: i === 0,
                    })}
                    onClick={() => onNoteClick(n.noteId)}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <span>
                          {n.sideEffectId
                            ? getSideEffectFromId(n.sideEffectId)?.name[
                                i18n.language === "en" ? "en" : "fr"
                              ]
                            : t("generic.other")}
                        </span>
                        <h4>{n.title}</h4>
                      </Grid>
                      <Grid item>
                        <ArrowIcon arrowType={ArrowType.empty} />
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
          ) : (
            <Box py={1} px={2}>
              <Typography align="center">
                <span>{t("journal.note.list.empty")}</span>
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default NotesDisplay;
