import { Medication } from "@neurosolutionsgroup/models";
import { useCallback } from "react";
import { useMedicationContext } from "./MedicationContext";

interface UseMedicationResult {
  medications: Medication[];
  getMedicationFromId: (id: string) => Medication | undefined;
}

const useMedication = (): UseMedicationResult => {
  const { medications } = useMedicationContext();

  const getMedicationFromId = useCallback(
    (id: string): Medication | undefined => {
      return medications.find((m) => m.id === id);
    },
    [medications]
  );

  return {
    medications,
    getMedicationFromId,
  };
};

export default useMedication;
