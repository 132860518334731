import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import ScrollPicker from "./ScrollPicker/ScrollPicker";
import { Button } from "..";
import { useTranslation } from "react-i18next";
import TimeHelper from "commons/helpers/Time";
import AMPMSelector from "./AMPMSelector/AMPMSelector";
import { isIPhone } from "commons/helpers/System";
import clsx from "clsx";

interface TimePickerProps {
  time: number | undefined;
  onChange: (value: number) => void;
  label?: string;
  drawerLabel?: string;
  customInputClasses?: string;
}

export interface CustomInputProps {
  onClick: VoidFunction;
  value: string;
}

const TimePicker: React.FC<TimePickerProps> = ({
  time,
  onChange,
  label,
  drawerLabel,
  customInputClasses,
}) => {
  const { t, i18n } = useTranslation();

  /**
   * Calculate the minutes to display initially,
   * either from the time passed into the component or the current time.
   */
  const getInitialMinutes = useCallback((): number => {
    return time ? Math.floor((time % 3600) / 60) : new Date().getMinutes();
  }, [time]);

  /**
   * Calculate the hours to display initially,
   * either from the time passed into the component or the current time.
   */
  const getInitialHours = useCallback((): number => {
    return time
      ? i18n.language === "en" && Math.floor(time / 3600) >= 12
        ? Math.floor(time / 3600) - 12
        : Math.floor(time / 3600)
      : new Date().getHours();
  }, [i18n.language, time]);

  // Component states.

  const [drawerOpen, setDrawerOpen] = useState(false);
  // Setup inital hours and mins from state.
  const [minutes, setMinutes] = useState<number>(getInitialMinutes());
  const [hours, setHours] = useState<number>(getInitialHours());
  const [timeString, setTimeString] = useState<string>("");
  const [isAM, setIsAM] = useState(true);

  useEffect(() => {
    // If language changes to english or time changes, calculate if new time is AM.
    if (i18n.language === "en" && time) {
      setIsAM(Math.floor(time / 3600) < 13);
    }
  }, [i18n.language, time]);

  /**
   * Set new time on draw close via confirmation.
   */
  const onConfirm = () => {
    onChange(hours * 3600 + minutes * 60 + (isAM ? 0 : 43200));
    setDrawerOpen(false);
  };

  const resetTime = () => {
    setMinutes(getInitialMinutes());
    setHours(getInitialHours());
    if (i18n.language === "en" && time) {
      setIsAM(Math.floor(time / 3600) < 13);
    }
  };

  /**
   * Generate the formatted, localized time to display.
   */
  const getDisplayValue = useCallback((): string => {
    return time
      ? TimeHelper.strings.localizedTimeFromSeconds(
          time,
          i18n.language === "en" ? "en" : "fr"
        )
      : t("time.picker.empty");
  }, [i18n.language, t, time]);

  useEffect(() => {
    if (time) {
      setTimeString(TimeHelper.strings.localizedTimeFromSeconds(time, "fr"));
    }
  }, [time]);

  const setTime = (newTimeString: string) => {
    console.log("Set time " + newTimeString);
    const time = newTimeString.split(":");

    if (time.length === 2) {
      onChange(
        parseInt(time[0]) * 3600 + parseInt(time[1]) * 60 + (isAM ? 0 : 43200)
      );
    }
  };

  return (
    <Box>
      {label ? <label className={"time-picker__label"}>{label}</label> : null}
      {isIPhone() ? (
        <>
          <input
            type="time"
            value={timeString}
            onChange={(ev) => setTime(ev.target.value)}
            className={clsx(
              "time-picker",
              "time-picker__input--ios",
              customInputClasses,
              {
                "time-picker__input--ios--en": i18n.language === "en" && !time,
                "time-picker__input--ios--fr": i18n.language === "fr" && !time,
              }
            )}
            lang={i18n.language}
          />
        </>
      ) : (
        <Box
          mt={1}
          onClick={() => setDrawerOpen(true)}
          className={clsx("time-picker", customInputClasses)}
        >
          {getDisplayValue()}
        </Box>
      )}

      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          resetTime();
          setDrawerOpen(false);
        }}
      >
        <Box p={3}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <h3 className={"time-picker__label--drawer"}>
                {drawerLabel ?? label}
              </h3>
            </Grid>
            {i18n.language === "en" ? (
              <Grid item xs={12}>
                <Box mt={1}>
                  <AMPMSelector isAM={isAM} setIsAM={setIsAM} />
                </Box>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <Box my={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={5} container justifyContent="center">
                    <ScrollPicker
                      value={hours ?? 1}
                      onChange={(v) => {
                        setHours(v);
                      }}
                      unit="hour"
                    />
                  </Grid>
                  <Grid item xs={5} container justifyContent="center">
                    <ScrollPicker
                      value={minutes ?? 0}
                      onChange={(v) => {
                        setMinutes(v);
                      }}
                      unit="minute"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Box m={"auto"}>
              <Button variant="contained" color="secondary" onClick={onConfirm}>
                {t("generic.confirm")}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Drawer>
    </Box>
  );
};

export default TimePicker;
