import React from "react";
import {
  AuthError,
  RegistrationPage,
  useAuth,
} from "@neurosolutionsgroup/authentication";
import { Navigate, useNavigate } from "react-router-dom";
import { FormButton } from "@neurosolutionsgroup/components";
import { UserCredential } from "firebase/auth";

const Registration = () => {
  const navigate = useNavigate();
  const {
    actions: { isAuthenticated },
  } = useAuth();

  const onClick = () => {
    navigate("/authentication");
  };

  const onSuccess = (response: UserCredential) => {
    console.log("registration success, user: " + response.user.uid);
    navigate("/authentication/completeregistration");
  };

  const onFailure = (err: AuthError) => {
    console.log("registration failure: " + err.message);
  };

  return (
    <div>
      {isAuthenticated() ? <Navigate to="/app/follow-up" /> : null}
      <FormButton type={"button"} value={"login"} onClick={onClick} />
      <RegistrationPage onSuccess={onSuccess} onFailure={onFailure} />
    </div>
  );
};

export default Registration;
