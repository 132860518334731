import { SideEffect } from "@neurosolutionsgroup/models";
import { SetStateAction, useState } from "react";
import { createGenericContext } from "../GenericContext";

interface SideEffectsContextData {
  sideEffects: SideEffect[];
  setSideEffects: React.Dispatch<SetStateAction<SideEffect[]>>;
}

const [useSideEffectsContext, SideEffectsContextProvider] =
  createGenericContext<SideEffectsContextData>();

const SideEffectsProvider: React.FC = (props) => {
  const [sideEffects, setSideEffects] = useState<SideEffect[]>([]);

  return (
    <SideEffectsContextProvider value={{ sideEffects, setSideEffects }}>
      {props.children}
    </SideEffectsContextProvider>
  );
};

export { useSideEffectsContext, SideEffectsProvider };
