import React, { useCallback, useState } from "react";
import { Page } from "commons/components";
import { useTranslation } from "react-i18next";
import { PageHeader } from "commons/components/Layout/Components";
import HeadSection from "commons/components/Layout/Components/HeadSection/HeadSection";
import { Box, Button, FormControlLabel, Grid } from "@material-ui/core";
import clsx from "clsx";
import FollowUpSelector from "./Components/FollowUpSelector/FollowUpSelector";
import { ExportIcon } from "commons/components/Icons";
import SideEffectSelector from "./Components/SideEffectSelector/SideEffectSelector";
import useFollowUps from "commons/hooks/followUp/useFollowUps";
import SummaryCard, {
  FollowUpSummary,
} from "./Components/SummaryCard/SummaryCard";
import { getModes } from "commons/helpers/Array";
import PeriodSelector from "./Components/PeriodSelector/PeriodSelector";
import GraphCard from "./Components/Graphs/GraphCard";
import {
  generateFrequencyData,
  generateIntensityData,
} from "./Components/Graphs/devData";
import { Checkbox } from "commons/components/Inputs";
import { Datum, formatGraphData } from "./Components/Graphs/data";
import useChildren from "commons/hooks/children/useChildren";
import TimeHelpers from "commons/helpers/Time";

const Dashboard: React.FC = () => {
  const ROOT_CLASS = "dashboard";

  const { t } = useTranslation();

  const {
    helpers: {
      getFollowUpById,
      getRecordsForFollowUpSideEffectPeriod,
      getPreviousSideEffectsForChild,
    },
  } = useFollowUps();

  const { selectedChildId } = useChildren();

  const [selectedPeriod, setSelectedPeriod] = useState(4);
  const [selectedFollowUpId, setSelectedFollowUpId] = useState<
    string | undefined
  >(undefined);
  const [selectedFollowUpLabel, setSelectedFollowUpLabel] = useState<
    string | JSX.Element
  >("");
  const [selectedSideEffectId, setSelectedSideEffectId] = useState<
    string | undefined
  >(undefined);
  const [useDevData, setUseDevData] = useState(false);
  const [isCurrentFollowUp, setIsCurrentFollowUp] = useState(true);

  const getSummary = useCallback((): FollowUpSummary | undefined => {
    if (selectedFollowUpId && selectedSideEffectId) {
      if (!isCurrentFollowUp) {
        // TODO: Get records data
      }

      const records = getRecordsForFollowUpSideEffectPeriod(
        selectedFollowUpId,
        selectedSideEffectId,
        selectedPeriod
      );

      // Get unique weeks of records.
      const count = new Set(
        records.map((r) =>
          TimeHelpers.date.getDateFromApiDateString(r.weekStartDate).getTime()
        )
      ).size;

      // Get frequency answers.
      const frequencyRecords = records.filter(
        (r) => r.questionId === process.env.REACT_APP_FREQUENCY_QUESTION_ID
      );
      // Get intensity answers.
      const intensityRecords = records.filter(
        (r) => r.questionId === process.env.REACT_APP_INTENSITY_QUESTION_ID
      );

      // Get average of each answers.
      const frequencyAverage =
        frequencyRecords.length > 0
          ? getModes(frequencyRecords.map((fr) => fr.answer))[0]
          : -1;
      const intensityAverage =
        intensityRecords.length > 0
          ? getModes(intensityRecords.map((ir) => ir.answer))[0]
          : -1;

      // Return summary.
      return {
        completedObservations: count,
        averageFrequency: frequencyAverage,
        averageIntensity: intensityAverage,
      };
    } else {
      return undefined;
    }
  }, [
    getRecordsForFollowUpSideEffectPeriod,
    selectedFollowUpId,
    selectedPeriod,
    selectedSideEffectId,
  ]);

  const onExport = () => {
    // TODO: export report in PDF
    console.log("Export.");
  };

  const getRecordsByQuestionId = useCallback(
    (questionId: string) => {
      if (selectedFollowUpId && selectedSideEffectId) {
        const records = getRecordsForFollowUpSideEffectPeriod(
          selectedFollowUpId,
          selectedSideEffectId,
          selectedPeriod
        );

        return records
          .filter((r) => r.questionId === questionId)
          .sort((a, b) =>
            TimeHelpers.date.compareToApiDateStrings(
              a.weekStartDate,
              b.weekStartDate
            )
          );
      }

      return [];
    },
    [
      getRecordsForFollowUpSideEffectPeriod,
      selectedFollowUpId,
      selectedPeriod,
      selectedSideEffectId,
    ]
  );

  const getIntensityData = useCallback((): Datum[] => {
    if (
      selectedFollowUpId &&
      selectedSideEffectId &&
      process.env.REACT_APP_INTENSITY_QUESTION_ID
    ) {
      const followUp = getFollowUpById(selectedFollowUpId);

      if (followUp) {
        return formatGraphData(
          selectedPeriod,
          getRecordsByQuestionId(process.env.REACT_APP_INTENSITY_QUESTION_ID),
          TimeHelpers.date.getDateFromApiDateString(followUp.startDate),
          (answer: number) => answer
        );
      }
    }

    return [];
  }, [
    getFollowUpById,
    getRecordsByQuestionId,
    selectedFollowUpId,
    selectedPeriod,
    selectedSideEffectId,
  ]);

  const getFrequencyData = useCallback((): Datum[] => {
    if (
      selectedFollowUpId &&
      selectedSideEffectId &&
      process.env.REACT_APP_FREQUENCY_QUESTION_ID
    ) {
      const followUp = getFollowUpById(selectedFollowUpId);
      if (followUp) {
        return formatGraphData(
          selectedPeriod,
          getRecordsByQuestionId(process.env.REACT_APP_FREQUENCY_QUESTION_ID),
          TimeHelpers.date.getDateFromApiDateString(followUp.startDate),
          (answer: number) => {
            let value = 0;

            if (answer > 0 && answer <= 2) {
              value = 1;
            } else if (answer >= 3 && answer <= 5) {
              value = 2;
            } else if (answer >= 6) {
              value = 3;
            }

            return value;
          }
        );
      }
    }

    return [];
  }, [
    getFollowUpById,
    getRecordsByQuestionId,
    selectedFollowUpId,
    selectedPeriod,
    selectedSideEffectId,
  ]);

  return (
    <Page
      className={ROOT_CLASS}
      header={<PageHeader title={t("navigation.pages.dashboard.title")} />}
    >
      <HeadSection className={ROOT_CLASS + "__head-section"}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <FollowUpSelector
              selectedFollowUpId={selectedFollowUpId}
              setSelectedFollowUpId={setSelectedFollowUpId}
              setSelectedFollowUpLabel={setSelectedFollowUpLabel}
              setIsCurrentFollowUp={setIsCurrentFollowUp}
            />
          </Grid>
          <Grid item>
            <Button style={{ minWidth: "0" }} onClick={onExport}>
              <ExportIcon />
            </Button>
          </Grid>
        </Grid>
      </HeadSection>
      {process.env.NODE_ENV === "development" ? (
        <Box m={1}>
          <FormControlLabel
            control={
              <Checkbox
                value={useDevData}
                onChange={(_, v) => setUseDevData(v)}
              />
            }
            label="Use Dev Data"
            labelPlacement="start"
          />
        </Box>
      ) : null}
      <Box my={2}>
        <Grid
          container
          direction="column"
          spacing={1}
          className={ROOT_CLASS + "__body"}
        >
          <Grid
            item
            className={clsx(
              ROOT_CLASS + "__body-section",
              ROOT_CLASS + "__body-section--period"
            )}
          >
            <PeriodSelector
              value={selectedPeriod}
              onChange={setSelectedPeriod}
            />
          </Grid>
          <Grid
            item
            className={clsx(
              ROOT_CLASS + "__body-section",
              ROOT_CLASS + "__body-section--side-effect"
            )}
          >
            <SideEffectSelector
              selectedSideEffectId={selectedSideEffectId}
              setSelectedSideEffectId={setSelectedSideEffectId}
              sideEffectIds={
                selectedFollowUpId &&
                getFollowUpById(selectedFollowUpId)?.followedSideEffectIds
                  ? getFollowUpById(selectedFollowUpId)
                      ?.followedSideEffectIds ?? []
                  : selectedChildId
                  ? getPreviousSideEffectsForChild(selectedChildId)
                  : []
              }
            />
          </Grid>
          <Grid
            item
            className={clsx(
              ROOT_CLASS + "__body-section",
              ROOT_CLASS + "__body-section--summary"
            )}
          >
            <Box pt={1}>
              <SummaryCard
                summary={getSummary()}
                rootClass={ROOT_CLASS + "__card"}
              />
            </Box>
          </Grid>
          <Grid
            item
            className={clsx(
              ROOT_CLASS + "__body-section",
              ROOT_CLASS + "__body-section--graph"
            )}
          >
            <GraphCard
              rootClass={ROOT_CLASS + "__card"}
              graphType="Intensity"
              data={
                useDevData
                  ? generateIntensityData(selectedPeriod, [2, 4, 6, 7])
                  : getIntensityData()
              }
              title={t("dashboard.graph.intensity")}
              medication={selectedFollowUpLabel}
            />
          </Grid>
          <Grid
            item
            className={clsx(
              ROOT_CLASS + "__body-section",
              ROOT_CLASS + "__body-section--graph"
            )}
          >
            <GraphCard
              rootClass={ROOT_CLASS + "__card"}
              graphType="Frequency"
              data={
                useDevData
                  ? generateFrequencyData(selectedPeriod)
                  : getFrequencyData()
              }
              title={t("dashboard.graph.frequency.title")}
              medication={selectedFollowUpLabel}
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Dashboard;
