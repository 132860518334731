import {
  Button,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { MenuIcon } from "commons/components/Icons";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

interface PeriodSelectorProps {
  value: number;
  onChange: (value: number) => void;
}

const periods: { [key: number]: string } = {
  4: "4-weeks",
  8: "8-weeks",
  12: "3-months",
  24: "6-months",
  36: "9-months",
  52: "1-year",
};

const PeriodSelector: React.FC<PeriodSelectorProps> = ({ value, onChange }) => {
  const ROOT_CLASS = "period-selector";

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const _onChange = (value: number) => {
    onChange(value);
    setOpen(false);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      classes={{ root: ROOT_CLASS }}
    >
      <b>
        <Trans i18nKey="dashboard.periods.label">
          Analysis over a period of {t(`dashboard.periods.${value}`)}
        </Trans>
      </b>
      <Button
        onClick={() => setOpen(true)}
        classes={{ root: ROOT_CLASS + "__button" }}
      >
        <MenuIcon />
      </Button>
      <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
        <List>
          {Object.keys(periods).map((p) => (
            <ListItem
              key={p}
              button
              onClick={() => _onChange(Number(p))}
              selected={value === Number(p)}
            >
              <ListItemText>
                <Typography align="center">
                  {t("dashboard.periods." + p)}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Grid>
  );
};

export default PeriodSelector;
