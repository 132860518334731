import { Grid } from "@material-ui/core";
import clsx from "clsx";
import {
  FullScreenSelect,
  SelectOption,
  TextInput,
  TimePicker,
} from "commons/components/Inputs";
import useSideEffects from "commons/hooks/sideEffects/useSideEffects";
import React, { SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface NoteFormProps {
  sideEffectId: string | null;
  setSideEffectId: React.Dispatch<SetStateAction<string | null>>;
  time: number | undefined;
  setTime: React.Dispatch<SetStateAction<number | undefined>>;
  title: string;
  setTitle: React.Dispatch<SetStateAction<string>>;
  text: string;
  setText: React.Dispatch<SetStateAction<string>>;
}

const NoteForm: React.FC<NoteFormProps> = ({
  sideEffectId,
  setSideEffectId,
  time,
  setTime,
  title,
  setTitle,
  text,
  setText,
}) => {
  const ROOT_CLASS = "note-form";

  const MAX_TITLE_LENGTH = 30;
  const MAX_TEXT_LENGTH = 1000;

  const { t, i18n } = useTranslation();

  const { sideEffects } = useSideEffects();
  const getSideEffectOptions = useCallback((): SelectOption[] => {
    const options = sideEffects.map((se) => ({
      value: se.id,
      label: i18n.language === "en" ? se.name.en : se.name.fr,
      name: i18n.language === "en" ? se.name.en : se.name.fr,
    }));

    options.push({
      value: "other",
      name: t("generic.other"),
      label: t("generic.other"),
    });

    return options;
  }, [i18n.language, sideEffects, t]);

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        <FullScreenSelect
          options={getSideEffectOptions()}
          value={sideEffectId}
          onChange={(option) => {
            setSideEffectId(option.value);
          }}
          placeholder={t("journal.note.add.inputs.sideEffect.placeholder")}
          isSearchable
          searchPlaceholder={t(
            "journal.note.add.inputs.sideEffect.searchPlaceholder"
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={ROOT_CLASS}
        >
          <Grid item>{t("journal.note.add.inputs.time.label")}</Grid>
          <Grid item>
            <TimePicker
              time={time}
              onChange={setTime}
              drawerLabel={t("journal.note.add.inputs.time.label")}
              customInputClasses={ROOT_CLASS + "__time-picker"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
          className={clsx(
            ROOT_CLASS + "__text-input",
            ROOT_CLASS + "__text-input--title"
          )}
          placeholder={t("journal.note.add.inputs.title.placeholder")}
          inputProps={{
            maxLength: MAX_TITLE_LENGTH,
          }}
        />
        <div
          className={clsx(ROOT_CLASS + "__text-input-count", {
            [ROOT_CLASS + "__text-input-count--error"]:
              title.length === MAX_TITLE_LENGTH,
          })}
        >
          {title.length}/{MAX_TITLE_LENGTH}
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          error
          value={text}
          onChange={(e) => setText(e.currentTarget.value)}
          multiline
          className={clsx(
            ROOT_CLASS + "__text-input",
            ROOT_CLASS + "__text-input--text"
          )}
          placeholder={t("journal.note.add.inputs.text.placeholder")}
          inputProps={{
            maxLength: MAX_TEXT_LENGTH,
          }}
          minRows={6}
          maxRows={15}
        />
        <div
          className={clsx(ROOT_CLASS + "__text-input-count", {
            [ROOT_CLASS + "__text-input-count--error"]:
              text.length === MAX_TEXT_LENGTH,
          })}
        >
          {text.length}/{MAX_TEXT_LENGTH}
        </div>
      </Grid>
    </Grid>
  );
};

export default NoteForm;
