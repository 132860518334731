import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const InformationIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="information">
      <path
        d="m 9,5.0000001 h 2 V 7 H 9 Z M 9,9 h 2 v 6 H 9 Z M 10,0 C 4.4799999,0 0,4.4799999 0,10 0,15.52 4.4799999,20 10,20 15.52,20 20,15.52 20,10 20,4.4799999 15.52,0 10,0 Z m 0,18 C 5.59,18 2,14.41 2,10 2,5.59 5.59,2 10,2 c 4.41,0 8,3.59 8,8 0,4.41 -3.59,8 -8,8 z"
        fill="#000000"
      />
    </BaseIcon>
  );
};

export default InformationIcon;
