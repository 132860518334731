import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const ArchiveIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="archive">
      <path
        d="M 19.488889,2.4777783 17.944444,0.61111125 C 17.644444,0.23333325 17.188889,2.5e-7 16.666666,2.5e-7 H 3.333333 c -0.522222,0 -0.977777,0.233333 -1.288888,0.611111 L 0.511111,2.4777783 C 0.188889,2.8555562 0,3.3555562 0,3.8888892 V 17.777777 C 0,19 1,20 2.222222,20 H 17.777778 C 19,20 20,19 20,17.777777 V 3.8888893 c 0,-0.533333 -0.188889,-1.033333 -0.511111,-1.411111 z M 3.6,2.2222222 h 12.8 l 0.9,1.077778 H 2.711111 Z M 2.222222,17.777778 V 5.5555553 H 17.777778 V 17.777778 Z M 11.611111,7.7777772 H 8.388889 V 11.111111 H 5.555556 L 10,15.555555 14.444444,11.111111 h -2.833333 z"
        fill="#000000"
        id="path824"
      />
    </BaseIcon>
  );
};

export default ArchiveIcon;
