import { ErrorAlert, SuccessAlert } from "commons/components/Alerts";
import React from "react";
import { AlertDefinition, AlertVariety } from "./AlertContext";

interface AlertsComponentProps {
  alerts: AlertDefinition[];
  onHide: (alertId: string) => void;
}

const AlertsComponent: React.FC<AlertsComponentProps> = ({
  alerts,
  onHide,
}) => {
  return (
    <div className="alert-root">
      {alerts.map((a, i) => (
        <AlertWrapper {...a} onHide={onHide} key={i} />
      ))}
    </div>
  );
};

export default AlertsComponent;

interface AlertWrapperProps {
  onHide: (alertId: string) => void;
}

const AlertWrapper: React.FC<AlertDefinition & AlertWrapperProps> = ({
  id,
  variety,
  text,
  dismissable,
  timeout,
  onHide,
}) => {
  if (timeout) {
    setTimeout(() => {
      onHide(id);
    }, 3000);
  }

  switch (variety) {
    case AlertVariety.Success:
      return (
        <SuccessAlert
          display
          text={text}
          onClose={dismissable ? () => onHide(id) : undefined}
        />
      );
    case AlertVariety.Error:
      return (
        <ErrorAlert
          display
          text={text}
          onClose={dismissable ? () => onHide(id) : undefined}
        />
      );
    default:
      return null;
  }
};
