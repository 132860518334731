import { Button, Grid } from "@material-ui/core";
import clsx from "clsx";
import languageHelper from "commons/helpers/Language";
import useSideEffects from "commons/hooks/sideEffects/useSideEffects";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface SideEffectSelectorProps {
  selectedSideEffectId: string | undefined;
  setSelectedSideEffectId: (value: string) => void;
  sideEffectIds: string[];
}

const SideEffectSelector: React.FC<
  SideEffectSelectorProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  selectedSideEffectId,
  setSelectedSideEffectId,
  sideEffectIds,
  ...props
}) => {
  const ROOT_CLASS = "sideeffect-selector";

  const { i18n } = useTranslation();

  const { getSideEffectFromId } = useSideEffects();

  useEffect(() => {
    setSelectedSideEffectId(sideEffectIds[0]);
  }, [setSelectedSideEffectId, sideEffectIds]);

  return (
    <Grid
      container
      spacing={1}
      classes={{ root: clsx(ROOT_CLASS, props.className) }}
      wrap="nowrap"
    >
      {sideEffectIds.map((sid) => (
        <Grid item key={sid}>
          <Button
            variant={selectedSideEffectId === sid ? "contained" : "outlined"}
            color="secondary"
            classes={{
              root: clsx(ROOT_CLASS + "__option", {
                [ROOT_CLASS + "__option--selected"]:
                  selectedSideEffectId === sid,
              }),
            }}
            onClick={() => setSelectedSideEffectId(sid)}
          >
            {
              getSideEffectFromId(sid)?.name[
                languageHelper.getAppLanguageFromI18n(i18n)
              ]
            }
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default SideEffectSelector;
