import { Box, Grid } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

interface ObservationEmptyStateProps {
  type: "noSideEffects" | "noPrescription" | "complete";
}

const ObservationEmptyState: React.FC<ObservationEmptyStateProps> = ({
  type,
}) => {
  const { t } = useTranslation();

  return (
    <Box className="observation-empty-state__container" m={1} p={3}>
      <Grid container direction="column" alignItems="center">
        <Grid className="observation-empty-state__text-container" item>
          <Box textAlign="center">
            {type === "noSideEffects" ? (
              <>
                <p>{t("journal.emptyState.noSideEffects.1")}</p>
                <p>{t("journal.emptyState.noSideEffects.2")}</p>
              </>
            ) : type === "noPrescription" ? (
              <>
                <p>{t("journal.emptyState.noPrescription.1")}</p>
                <p>{t("journal.emptyState.noPrescription.2")}</p>
              </>
            ) : (
              <>
                <p>{t("journal.emptyState.completed.1")}</p>
                <p>{t("journal.emptyState.completed.2")}</p>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ObservationEmptyState;
