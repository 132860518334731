import { Box, Grid } from "@material-ui/core";
import {
  logo_dec,
  logo_firefolk,
  logo_fpt,
  logo_medteq,
  logo_mfc,
  logo_neuro,
  logo_sarbakan,
  logo_uqtr,
  logo_vqb,
} from "commons/assets/credits";
import React from "react";
import { useTranslation } from "react-i18next";

const neuroEmployees = [
  {
    name: "Annie Martineau",
    role: "Présidente et Fondatrice",
  },
  {
    name: "Édouard Garceau-Bolduc",
    role: "Directeur marketing",
  },
  {
    name: "Quentin Cervilla",
    role: "Créateur de contenus",
  },
  {
    name: "Laurence Carignan",
    role: "Gestionnaire de produit",
  },
  {
    name: "Anne-Sophie Fournier",
    role: "Gestionnaire de produit",
  },
  {
    name: "Joanie Paquet",
    role: "Designer UX/UI",
  },
  {
    name: "Olivier Dupont",
    role: "Directeur gamification",
  },
  {
    name: "Hoang Yen Pham",
    role: "Développeuse Full Stack",
  },
  {
    name: "Alexandre Meesen",
    role: "Concepteur de niveaux",
  },
  {
    name: "Andrew Faulkner",
    role: "Développeur Full Stack",
  },
  {
    name: "Antoine Lacroix",
    role: "Développeur Full Stack",
  },
  {
    name: "William Munoz",
    role: "Spécialiste Assurance Qualité",
  },
];

const Credits: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Box m={1} mb={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <img
              className="credits__logo credits__logo--neuro"
              src={logo_neuro}
              alt="Neuro Solutions Group"
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={1}
            xs={12}
            sm={8}
            md={4}
          >
            {neuroEmployees.map((ne) => (
              <Grid item container>
                <Grid item xs={6}>
                  <b>{ne.name}</b>
                </Grid>
                <Grid item xs={6}>
                  {ne.role}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
            <img
              className="credits__logo credits__logo--partner"
              src={logo_uqtr}
              alt="UQTR"
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
            <img
              className="credits__logo credits__logo--partner"
              src={logo_firefolk}
              alt="Firefolk"
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
            <img
              className="credits__logo credits__logo--partner"
              src={logo_sarbakan}
              alt="Sarbakan"
            />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <h3>{t("navigation.pages.settings.credits.partner")}</h3>
        <Grid container direction="row" justifyContent="center" spacing={3}>
          <Grid item xs={7} justifyContent="center" alignItems="center">
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_mfc}
                alt="Media Fund Canada"
              />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_vqb}
                alt="Ville de Québec"
              />
            </Box>
          </Grid>
          <Grid item xs={8} sm={6}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_medteq}
                alt="MEDTEQ"
              />
            </Box>
          </Grid>
          <Grid item xs={8} sm={6}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_fpt}
                alt="Fondation des petits trésors"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_dec}
                alt="Développement économique Canada"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Credits;
