import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React from "react";

const FooterButtons: React.FC = ({ ...props }) => {
  const ROOT_CLASS = "footer-buttons";

  return (
    <Box className={ROOT_CLASS} p={1}>
      <Grid container alignItems="center" justifyContent="center">
        {props.children}
      </Grid>
    </Box>
  );
};

export default FooterButtons;
