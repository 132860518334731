import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { CloseIcon } from "commons/components/Icons";
import { Button } from "commons/components/Inputs";
import { useAppDataContext } from "commons/hooks/AppDataContext";
import React from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../Dialog";

const NoInternetDialog: React.FC = () => {
  const { t } = useTranslation();

  const { noInternetConnection, setNoInternetConnection } = useAppDataContext();

  const onClose = () => {
    setNoInternetConnection(false);
  };

  return (
    <Dialog open={noInternetConnection} onClose={onClose}>
      <Box p={2}>
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item container>
            <Box mr="auto" ml={1}>
              <h4>{t("generic.noInternetDialog.title")}</h4>
            </Box>
            <CloseIcon onClick={onClose} />
          </Grid>
          <Grid item container>
            <Box mr="auto" ml={1}>
              {t("generic.noInternetDialog.text")}
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Button variant="contained" color="secondary" onClick={onClose}>
                {t("generic.tryAgain")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default NoInternetDialog;
