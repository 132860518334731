export interface LocalizedString {
  en: string;
  fr: string;
}

export enum AnswerType {
  frequency = "frequency",
  intensity = "intensity",
}
export interface AnswerValue {
  minValue: number;
  maxValue: number;
  step: number;
}
