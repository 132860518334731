const resources = {
  en: {
    translation: {
      time: {
        picker: {
          empty: "Select a time",
        },
        months: {
          0: {
            full: "January",
            short: "Jan.",
          },
          1: {
            full: "February",
            short: "Feb.",
          },
          2: {
            full: "March",
            short: "Mar.",
          },
          3: {
            full: "April",
            short: "Apr.",
          },
          4: {
            full: "May",
            short: "May",
          },
          5: {
            full: "June",
            short: "Jun.",
          },
          6: {
            full: "July",
            short: "Jul.",
          },
          7: {
            full: "August",
            short: "Aug.",
          },
          8: {
            full: "September",
            short: "Sep.",
          },
          9: {
            full: "October",
            short: "Oct.",
          },
          10: {
            full: "November",
            short: "Nov.",
          },
          11: {
            full: "December",
            short: "Dec.",
          },
        },
        hours: {
          label: "Hours",
        },
        minutes: {
          label: "Minutes",
        },
      },
    },
  },
  fr: {
    translation: {
      time: {
        picker: {
          empty: "Selectionnez une heure",
        },
        months: {
          0: {
            full: "January",
            short: "Jan.",
          },
          1: {
            full: "February",
            short: "Feb.",
          },
          2: {
            full: "March",
            short: "Mar.",
          },
          3: {
            full: "April",
            short: "Apr.",
          },
          4: {
            full: "May",
            short: "May",
          },
          5: {
            full: "June",
            short: "Jun.",
          },
          6: {
            full: "July",
            short: "Jul.",
          },
          7: {
            full: "August",
            short: "Aug.",
          },
          8: {
            full: "September",
            short: "Sep.",
          },
          9: {
            full: "October",
            short: "Oct.",
          },
          10: {
            full: "November",
            short: "Nov.",
          },
          11: {
            full: "December",
            short: "Dec.",
          },
        },
        hours: {
          label: "Heures",
        },
        minutes: {
          label: "Minutes",
        },
      },
    },
  },
};

export default resources;
