import React from "react";
import { IconProps } from ".";
import BaseIcon from "./BaseIcon";

const ExportIcon: React.FC<IconProps> = ({ onClick }) => {
  return (
    <BaseIcon onClick={onClick} classModifier="export">
      <path
        d="M 13.636364,3.6363636 12.345454,4.9272727 10.9,3.4818182 V 13.636364 H 9.0999998 V 3.4818182 L 7.6545452,4.9272727 6.3636361,3.6363636 9.9999998,0 Z m 3.636364,4.5454546 v 9.9999998 c 0,1 -0.818182,1.818182 -1.818182,1.818182 H 4.5454543 C 3.5363634,20 2.7272725,19.181818 2.7272725,18.181818 V 8.1818182 c 0,-1.0090909 0.8090909,-1.8181818 1.8181818,-1.8181818 H 7.272727 V 8.1818182 H 4.5454543 V 18.181818 H 15.454546 V 8.1818182 H 12.727273 V 6.3636364 h 2.727273 c 1,0 1.818182,0.8090909 1.818182,1.8181818 z"
        fill="#000000"
        id="path824"
      />
    </BaseIcon>
  );
};

export default ExportIcon;
