import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Prescription } from "@neurosolutionsgroup/models";
import clsx from "clsx";
import { MedicationIcon, MenuIcon } from "commons/components/Icons";
import { AddButton } from "commons/components/Inputs/Button";
import {
  Card,
  VerticalCardSection,
} from "commons/components/Layout/Components";
import TimeHelper from "commons/helpers/Time";
import useChildren from "commons/hooks/children/useChildren";
import useMedication from "commons/hooks/medication/useMedication";
import React from "react";
import { useTranslation } from "react-i18next";

interface PrescriptionCardProps {
  childId: string;
  prescription?: Prescription;
  startDate?: Date;
  takesOtherMedication?: boolean;
  onEdit?: VoidFunction;
  onAdd?: VoidFunction;
}

const PrescriptionCard: React.FC<PrescriptionCardProps> = ({
  childId,
  prescription,
  startDate,
  takesOtherMedication,
  onEdit,
  onAdd,
}) => {
  const ROOT_CLASS = "prescription-card";

  const { t, i18n } = useTranslation();
  const { getMedicationFromId } = useMedication();
  const { getChildFromId } = useChildren();

  const getTimeOfDay = (time: number): string => {
    return time >= 14400 && time <= 43200
      ? t("followup.confirmation.prescription.doseTime.morning")
      : time > 43200 && time <= 64800
      ? t("followup.confirmation.prescription.doseTime.afternoon")
      : t("followup.confirmation.prescription.doseTime.night");
  };

  return (
    <Card className={ROOT_CLASS + "__card"}>
      <Grid container direction="column">
        <VerticalCardSection>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <h3>{t("followup.confirmation.prescription.title")}</h3>
            </Grid>
            {onEdit ? (
              <Grid
                item
                className={clsx(ROOT_CLASS + "__edit", {
                  [ROOT_CLASS + "__edit--disabled"]: !onEdit,
                })}
              >
                <MenuIcon onClick={onEdit} />
              </Grid>
            ) : null}
          </Grid>
        </VerticalCardSection>
        {prescription && takesOtherMedication !== undefined && startDate ? (
          <>
            <VerticalCardSection>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <MedicationIcon />
                </Grid>
                <Grid item>
                  {
                    getMedicationFromId(prescription.drug.drugId)?.name[
                      i18n.language === "en" ? "en" : "fr"
                    ]
                  }
                </Grid>
              </Grid>
            </VerticalCardSection>
            <VerticalCardSection>
              <Grid container direction="column">
                <Grid
                  container
                  item
                  direction="column"
                  className={ROOT_CLASS + "__wrapper"}
                >
                  <Grid item className={ROOT_CLASS + "__label"}>
                    {t("followup.confirmation.prescription.date")}
                  </Grid>
                  <Grid item className={ROOT_CLASS + "__value"}>
                    {TimeHelper.strings.dateToLocalizedString(startDate, t)}
                  </Grid>
                </Grid>

                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={ROOT_CLASS + "__label"}>
                          {t("followup.confirmation.prescription.moment")}
                        </TableCell>
                        <TableCell className={ROOT_CLASS + "__label"}>
                          {t("followup.confirmation.prescription.dose")}
                        </TableCell>
                        <TableCell className={ROOT_CLASS + "__label"}>
                          {t("followup.confirmation.prescription.time")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prescription.drug.doses.map((d) => (
                        <TableRow key={d.time}>
                          <TableCell className={ROOT_CLASS + "__value"}>
                            {getTimeOfDay(d.time)}
                          </TableCell>
                          <TableCell className={ROOT_CLASS + "__value"}>
                            {d.quantity + "mg"}
                          </TableCell>
                          <TableCell className={ROOT_CLASS + "__value"}>
                            {TimeHelper.strings.localizedTimeFromSeconds(
                              d.time,
                              i18n.language === "en" ? "en" : "fr"
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Grid>
            </VerticalCardSection>
            <VerticalCardSection
              last
              className={ROOT_CLASS + "__other-medication"}
            >
              {t(
                `followup.confirmation.prescription.takesOtherMedication.${takesOtherMedication.toString()}`,
                { name: getChildFromId(childId)?.name }
              )}
            </VerticalCardSection>
          </>
        ) : (
          <div className={ROOT_CLASS + "__add"}>
            <AddButton
              aria-label="add"
              onClick={onAdd}
              className={ROOT_CLASS + "__add-button"}
            />
            <span className={ROOT_CLASS + "__add-text"}>
              {t("followup.confirmation.prescription.add")}
            </span>
          </div>
        )}
      </Grid>
    </Card>
  );
};

export default PrescriptionCard;
