import ChildService from "./children";
import MedicationService from "./medication";
import SideEffectService from "./sideEffects";

const MedicalService = { MedicationService, SideEffectService, ChildService };

export const constructBearerTokenHeader = (
  token: string
): { authorization: string } => {
  return {
    authorization: `Bearer ${token}`,
  };
};

export const MEDICALCHILD_FUNCTION = "/medicalChild";

export const urlGenerator = (path: string): string => {
  if (process.env.REACT_APP_FIREBASE_FUNCTIONS_URL) {
    return process.env.REACT_APP_FIREBASE_FUNCTIONS_URL + path;
  } else {
    throw new Error(
      "Missing environment variable: REACT_APP_FIREBASE_FUNCTIONS_URL"
    );
  }
};

export default MedicalService;
