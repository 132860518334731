import { FollowedSideEffect } from "@neurosolutionsgroup/models";
import { FrontEndFollowUp } from "commons/models/Medical";
import { SetStateAction, useState } from "react";
import { createGenericContext } from "../GenericContext";

interface FollowUpsContextDate {
  followUps: FrontEndFollowUp[];
  setFollowUps: React.Dispatch<SetStateAction<FrontEndFollowUp[]>>;
  followUpSideEffects: FollowedSideEffect[];
  setFollowUpSideEffects: React.Dispatch<SetStateAction<FollowedSideEffect[]>>;
}

const [useFollowUpsContext, FollowUpContextProvider] =
  createGenericContext<FollowUpsContextDate>();

const FollowUpsProvider: React.FC = (props) => {
  const [followUps, setFollowUps] = useState<FrontEndFollowUp[]>([]);
  const [followUpSideEffects, setFollowUpSideEffects] = useState<
    FollowedSideEffect[]
  >([]);

  return (
    <FollowUpContextProvider
      value={{
        followUps,
        setFollowUps,
        followUpSideEffects,
        setFollowUpSideEffects,
      }}
    >
      {props.children}
    </FollowUpContextProvider>
  );
};

export { useFollowUpsContext, FollowUpsProvider };
