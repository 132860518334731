import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {
  SelectOption,
  FullScreenSelect,
  DatePicker,
} from "commons/components/Inputs";
import useMedication from "commons/hooks/medication/useMedication";
import React, { SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface MedicationFormProps {
  medication: string | null;
  setMedication: React.Dispatch<SetStateAction<string | null>>;
  startDate: Date;
  setStartDate: React.Dispatch<SetStateAction<Date>>;
}

const MedicationForm: React.FC<MedicationFormProps> = ({
  medication,
  setMedication,
  startDate,
  setStartDate,
}) => {
  const { medications } = useMedication();
  const { i18n, t } = useTranslation();

  const getMedicationOptions = useCallback((): SelectOption[] => {
    return (
      medications
        // Filter out the "placeholder" drug that represents any "other" drug.
        .filter(
          (m) => m.id !== process.env.REACT_APP_MEDICAL_OTHER_MEDICATION_ID
        )
        .map((m) => ({
          label: (
            <span>
              <b>{i18n.language === "en" ? m.name.en : m.name.fr}</b>
            </span>
          ),
          value: m.id,
          name: i18n.language === "en" ? m.name.en : m.name.fr,
        }))
    );
  }, [medications, i18n]);

  return (
    <Box p={1}>
      <Grid container direction={"column"}>
        <Grid item xs={12} container direction={"column"}>
          <label htmlFor="medication-input">
            <h3>{t("medication.prescriptionForm.medication.title")}</h3>
          </label>
          <FullScreenSelect
            id="medication-input"
            aria-label={t("medication.prescriptionForm.medication.placeholder")}
            placeholder={t(
              "medication.prescriptionForm.medication.placeholder"
            )}
            isSearchable
            searchPlaceholder={t(
              "medication.prescriptionForm.medication.search.placeholder"
            )}
            options={getMedicationOptions()}
            onChange={(o) => setMedication(o.value)}
            value={medication}
          />
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            date={startDate}
            setDate={setStartDate}
            pastDays={14}
            label={t("medication.prescriptionForm.datePicker.label")}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default MedicationForm;
