const resources = {
  en: {
    translation: {
      followup: {
        start: "Start a follow-up",
        form: {
          title: "Add a follow-up",
        },
        confirmation: {
          title: "Confirm the follow-up",
          prescription: {
            title: "Prescription",
            date: "Start date",
            moment: "Moment",
            dose: "Dosage",
            time: "Time",
            takesOtherMedication: {
              true: "{{name}} takes other medication",
              false: "{{name}} doesn't take other medication",
            },
            doseTime: {
              morning: "Morning",
              afternoon: "Afternoon",
              night: "Night",
            },
            add: "Add a prescription",
          },
          sideEffects: {
            title: "Side Effects",
            add: "Add side effects",
          },
        },
      },
      medication: {
        prescriptionForm: {
          medication: {
            title: "Medication",
            placeholder: "Select a medication",
            search: {
              placeholder: "Search a medication",
            },
          },
          datePicker: {
            label: "Medication start date",
          },
        },
        dose: {
          label: {
            "0": "First dose",
            "1": "Second dose",
            "2": "Third dose",
          },
          time: {
            label: "Time of medication",
          },
          dose: {
            label: {
              morning: "Morning Dosage",
              afternoon: "Afternoon Dosage",
              night: "Night Dosage",
            },
          },
          otherMedication: {
            label: "This child takes another medication",
          },
          add: {
            action: "Add a dosage",
          },
        },
        cancel: {
          title: "Cancel the follow-up",
        },
        actions: {
          terminate: {
            title: "End the prescription",
            confirm: "The prescription will be archived.",
            success:
              "To continue side effects follow-up, add a new prescription.",
          },
          correct: {
            title: "Correct the prescription",
            confirm: {
              body: {
                one: "Are you sure you want to apply the correction?",
                two: "Data entered at the prescription creation will be updated.",
                three:
                  "If you want to add a new prescription, use the option <1>End a prescription.</1>",
              },
            },
            success: "The prescription has been corrected successfully",
          },
        },
      },
      sideEffects: {
        title: "Side effects",
        search: {
          placeholder: "Search for a side effect",
          title: "Searched side effects",
        },
        error: {
          maximum:
            "You can follow a maximum of {{maximum}} side effects at a time.",
        },
        selected: "Selected side effects",
        filter: {
          "all": "All",
          "0": "Frequently followed",
        },
        form: {
          modify: {
            title: "Edit side effects",
            success: "Side effects have been successfully updated",
          },
        },
      },
    },
  },
  fr: {
    translation: {
      followup: {
        start: "Commencer un suivi",
        form: {
          title: "Ajouter un suivi",
        },
        confirmation: {
          title: "Confirmation du suivi",
          prescription: {
            title: "Prescription",
            date: "Date dé début",
            moment: "Moment",
            dose: "Dosage",
            time: "Heure",
            takesOtherMedication: {
              true: "{{name}} prends d'autres médicaments",
              false: "{{name}} ne prends pas d'autres médicaments",
            },
            doseTime: {
              morning: "Matin",
              afternoon: "Après-midi",
              night: "Soirée",
            },
            add: "Ajouter une prescription",
          },
          sideEffects: {
            title: "Effets secondaires",
            add: "Ajouter des effets secondaires",
          },
        },
      },
      medication: {
        prescriptionForm: {
          medication: {
            title: "Médication",
            placeholder: "Sélectionnez une médication",
            search: {
              placeholder: "Rechercher un médicament",
            },
          },
          datePicker: {
            label: "Date de début de la prise",
          },
        },
        dose: {
          label: {
            "0": "Première dose",
            "1": "Deuxième dose",
            "2": "Troisième dose",
          },
          time: {
            label: "Heure de prise de médication",
          },
          dose: {
            label: {
              morning: "Dosage du matin",
              afternoon: "Dosage de l'après-midi",
              night: "Dosage du soir",
            },
          },
          otherMedication: {
            label: "Votre enfant prend une autre médication",
          },
          add: {
            action: "Ajouter une posologie",
          },
        },
        cancel: {
          title: "Annuler l'ajout de suivi",
        },
        actions: {
          terminate: {
            title: "Terminer la prescription",
            confirm: "Le prescription sera archivée.",
            success:
              "Pour continuer le suivi des effets secondaires, ajouter une nouvelle prescription.",
          },
          correct: {
            title: "Corriger la prescription",
            confirm: {
              body: {
                one: "Êtes-vous certain de vouloir appliquer la correction?",
                two: "Les données saisies à la création de cette prescription seront mises à jour.",
                three:
                  "Si vous souhaitez ajouter une nouvelle prescription, utiliser l’option Terminé la prescription.",
              },
            },
            success: "La prescription a été corrigé avec succès",
          },
        },
      },
      sideEffects: {
        title: "Effets secondaires",
        search: {
          placeholder: "Rechercher un effet secondaire",
          title: "Effets secondaires recherchés",
        },
        error: {
          maximum: "Vous pouvez suivre un maximum de huit effets secondaires.",
        },
        selected: "Effets secondaires sélectionnés",
        filter: {
          "all": "Tous",
          "0": "Fréquemment suivis",
        },
        form: {
          modify: {
            title: "Modifier les effets secondaires",
            success: "Les effets secondaires ont été modifiés avec succès",
          },
        },
      },
    },
  },
};

export default resources;
