import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {
  DashboardIcon,
  JournalIcon,
  MedicationIcon,
} from "commons/components/Icons";
import AddChildPage from "pages/addChild/AddChildPage";
import Settings from "pages/settings/Settings";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Link, useLocation, Routes, Navigate } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
import FollowUpPage from "../followUp/FollowUpPage";
import Journal from "../journal/JournalPage";

const AppNavigator: React.FC = ({ ...props }) => {
  const [value, setValue] = useState(window.location.pathname);
  const location = useLocation();
  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  const { t } = useTranslation();

  const BOTTOM_NAV_ROUTES = [
    "/app/dashboard",
    "/app/follow-up",
    "/app/journal",
  ];

  return (
    <div className="app-navigator">
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/follow-up" element={<FollowUpPage />} />
        <Route path="/journal" element={<Journal />} />
        <Route path="add-child" element={<AddChildPage />} />
        <Route path="/settings" element={<Settings />} />
        <Route path={"/*"} element={<Navigate to="follow-up" />} />
      </Routes>
      {BOTTOM_NAV_ROUTES.includes(value) ? (
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        >
          <BottomNavigationAction
            component={Link}
            to="follow-up"
            label={t("navigation.pages.followUp.title")}
            value="/app/follow-up"
            icon={<MedicationIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="journal"
            label={t("navigation.pages.journal.title")}
            value="/app/journal"
            icon={<JournalIcon />}
          />
          <BottomNavigationAction
            component={Link}
            to="dashboard"
            label={t("navigation.pages.dashboard.title")}
            value="/app/dashboard"
            icon={<DashboardIcon />}
          />
        </BottomNavigation>
      ) : (
        ""
      )}
    </div>
  );
};

export default AppNavigator;
